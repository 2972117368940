document.addEventListener('project:create', (event) => {
    if( window.Application )
    {

        if( window.Application.state.context == 'visitor' || window.Application.state.context.trim() == '' )
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'warn', 'you_must_be_logged_in_to_create_a_project' );
            }

            if(window.AuthenticationPanel )
            {
                window.AuthenticationPanel.setState( { visible: true } );
            }
            return;
        }
    } } );
