import { useState } from "react";
import GenericUI from "../generic.ui";
import "./styles/messenger.css";
import {StringUtils} from "../../utils/string.utils";
import { Translator } from "../../translator";

const _messagesNeedsAuthentication = [
    'invalid_token',
    'session_does_not_exist',
    'visitor_session_over',
    'session_timeout',
    'not_allowed_with_this_licence_level'
];

const _messagesNeedsrestart = [
    'invalid_token',
    'session_does_not_exist',
    'visitor_session_over',
    'session_timeout'
];

export default class Messenger extends GenericUI{
    constructor( props ) {
        super( props );
        this.state = {
            message: { type: 'none', text: 'Message'}
        };
    }
    
    componentDidMount()
    {
        window.Messenger = this;
    }

    showMessage( type, message, extra ) {
        // FVL 02/08/24 fixes "message.trim() is not a function" random error
        if (typeof message === 'string') message = message.trim();
        if( !message || typeof message !== 'string' ) //   === null || message === undefined || message.trim() === '' )
        {
            console.log("INTERNAL_ERROR_MSG_INVALID", message);
            message = 'internal_error_msg_invalid';
        }
        var message = Translator.get(message);
        if( extra )
        {
            for( var r = 0; r < extra.length; r++ )
            message = StringUtils.strReplace( message, '%' + (r+1), extra[r] );
        }
        this.setState( { message: { type: type, text: message } } );
        setTimeout( this.closeMessenger, 5000 );

        if( _messagesNeedsAuthentication.indexOf( message ) !== -1 )
        {
            if( window.AuthenticationPanel )
            {
                if( localStorage ) localStorage.clear();
                if( sessionStorage ) sessionStorage.clear();
                window.AuthenticationPanel.setState( {visible: true, restart:  _messagesNeedsrestart.indexOf( message ) !== -1 } );
            }
        }
    }

    closeMessenger() {
        if( window.Messenger )
        {
            if( window.Messenger.state.message.type !== 'none' )
            {
                window.Messenger.setState( { message: { type: 'none', text: 'Message'} } );
            }
        }
    }

    render() {
        super.render();
        if( this.state.message.type === 'none')
        {
            return null;
        }
        else
        {
            var icon = ( this.state.message.type === 'error' ) ? 'exclamation-triangle' : (this.state.message.type === 'warn' ? 'exclamation-circle' : 'info-circle' );
            return (
                <div id="messenger" className="messenger" onClick={ (e) => { this.closeMessenger(); }}>
                    <div id="messenger-popup" className={`popup messenger-popup messenger-${this.state.message.type}`}>
                        <div id="messenger-icon" className="messenger-icon">
                            <i className={"fas fa-" + icon}></i>
                        </div>
                        <span id="messenger-text">{this.state.message.text}</span>
                    </div>
                </div>
            );
        }
    }
}
