import {Editor} from '@monaco-editor/react';
import {initSuggestions} from "../syntax/suggestions.js";
import {initDarkTheme} from "../syntax/theme-dark.js";
import {initLightTheme} from "../syntax/theme-light.js";
import {initTokenizer} from "../syntax/tokenizer.js";
import {NetUtils} from '../../../utils/net.utils.js';
import { showMessage } from '../../messenger/func/index.js';
import {FileUtils} from '../../../utils/file.utils.js';
import {ProjectAPI} from '../../../../api/project/index.js';

const eventBus = document;
export function searchTab( tabs, path )
{
    var tab = tabs.find( (item) => item.path === path );
    return tab;
}

export function loadFile( item )
{
    var path = item.key;
    if( window.LoaderAnim )
    {
        window.LoaderAnim.show( 'spinner' );
    }

    ProjectAPI.loadFile( path )
        .then( (data) => {
            if( window.LoaderAnim )
            {
                window.LoaderAnim.hide();
            }
        
            var ext = FileUtils.getExtension( path );
            // CODE EDITOR
                var lang = getSyntax( ext );
                var theme = 'aoz-dark';
                var editor = 'monaco';
                // options: https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IStandaloneEditorConstructionOptions.html
                var options = { 
                    autoIndent: 'full',
                    automaticLayout: true,
                    contextmenu: true,
                    cursorStyle: 'line',
                    detectIndentation: false,               // detect indentation-style (tab/space) based on file contents
                    fontFamily: 'monospace',
                    fontSize: 16,
                    glyphMargin: true,
                    hideCursorInOverviewRuler: true,
                    insertSpaces: false,                    // use tabs for indentation
                    lineHeight: 24,
                    lineNumbersMinChars: 3,
                    matchBrackets: 'always',
                    minimap: {
                        enabled: false,
                    },
                    readOnly: false,
                    roundedSelection: false,
                    scrollbar: {
                        horizontalSliderSize: 6,
                        verticalSliderSize: 6,
                    },
                    selectOnLineNumbers: true,
                    tabSize: 4,                             // one tab-indentation equals N spaces
                };
                var event = new CustomEvent( 'codeeditor:file-loaded', { detail: { editor: editor, key: item.key, path: path, label: item.label, language: lang, code: data, icon: item.icon, options: options, syntax: item.language, theme: theme } } );
                eventBus.dispatchEvent( event );
        })
        .catch( (error) => {
            if( window.LoaderAnim )
            {
                window.LoaderAnim.hide();
            }
            showMessage( 'error', error );
        } );
}

function getSyntax( ext )
{
    switch( ext )
    {
        case "amos":
        case "aoz":
        case "aozinc":
            return "aoz";
        case "json":
            return "json";
        case "js":
            return "javascript";
        case "css":
            return "css";
        case "html":
            return "html";
        case "md":
            return "markdown";
        case "xml":
            return "xml";
        case "txt":
        case "log":
            return "text";
        default:
            return "unknown";
    }
}

export function getIcon( path )
{
    var ext = FileUtils.getExtension( path );
    return 'icon_' + ext;
}