export function initDarkTheme(monaco)
{
	monaco.editor.defineTheme( 'aoz-dark', {
		base: 'vs',
		inherit: true,
		rules: [
			{ token: 'keyword', foreground: '#54b4d6' /*'#83b2c3'*/, /*fontStyle: "bold"*/ },
			{ token: 'block.aoz', foreground: '#774499', /*fontStyle:"bold" */},
			{ token: 'delimiter', foreground: '#CCCCCC', /*fontStyle:"bold" */},
			{ token: 'operator', foreground: '#dddddd', /*'#FFAB00'*/ },
			{ token: 'string', foreground: '#e7bb6d' },
			{ token: 'comment', foreground: '#009900' },
			{ token: 'number', foreground: '#98c379'},
			{ token: 'constant', foreground: '#e4e916'},
			//{ token: 'comment.js', foreground: '#009900' },
			//{ token: 'comment.css', foreground: '#009900' },
			{ background: '#1C1C1C' }
		],
		colors: {
			// List of valid keys for theme
			// https://code.visualstudio.com/api/references/theme-color
			// https://github.com/microsoft/monaco-editor/issues/1631

			'editor.background': '#1C1C1C',
			'editor.focusBorder': 'none',
			'editor.foreground': '#A1A1A1',
			'editor.hoverHighlightBackground': '#303030',
			'editor.inactiveSelectionBackground': '#0b3255',
			'editor.lineHighlightBackground':'#303030',
			'editor.lineHighlightBorder': 'none',
			'editor.selectionBackground': '#2E475D',
			
			'editorCursor.foreground': '#355470',
			'editorGutter.background': '#1a1a1a',
			'editorHoverWidget.background': '#2e475d',
			'editorHoverWidget.border': '#505050',
			'editorHoverWidget.foreground': '#CCCCCC',
			'editorIndentGuide.activeBackground1': '#808080',
			'editorIndentGuide.background1' : '#404040',
			'editorLineNumber.activeForeground': '#FFFFFF',
			'editorLineNumber.foreground': '#888888',
			'editorSuggestWidget.background': '#303030',
			'editorSuggestWidget.border': '#505050',
			'editorSuggestWidget.foreground': '#CCCCCC',
			'editorSuggestWidget.selectedBackground': '#2E475D',
			'editorSuggestWidget.highlightForeground': '#FFAA00',
			'editorWhitespace.foreground': "#606060",

			'list.hoverBackground': '#2E475D',

			'scrollbarSlider.background' : '#e7a522',
			'scrollbarSlider.hoverBackground' : '#e7a522',
			'scrollbarSlider.activeBackground' : '#e7a522',
		}
	});
}
