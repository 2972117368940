export function initLightTheme(monaco)
{
	monaco.editor.defineTheme( 'aoz-light', {
		base: 'vs',
		inherit: true,
		rules: [
			{ token: 'keyword', foreground: '#08aeed', fontStyle: "bold" },
			{ token: 'block.aoz', foreground: '#774499', fontStyle:"bold" },
			{ token: 'delimiter', foreground: '#AA6666', fontStyle:"bold" },
			{ token: 'operator', foreground: '#FFAB00' },
			{ token: 'string', foreground: '#df930c' },
			{ token: 'comment', foreground: '#009900' },
			{ token: 'comment.js', foreground: '#009900' },
			{ token: 'comment.css', foreground: '#009900' },
			{ background: '#FFFFFF' }
		],
		colors: {
			'editor.foreground': '#222222',
			'editor.background': '#FFFFFF',
			'editorCursor.foreground': '#355470',
			'editor.selectionBackground': '#d8dbf1',
			'editor.lineHighlightBackground':'#EEEEFF',
			'editor.lineHighlightBorder': 'none',
			'editorLineNumber.foreground': '#999999',
			"editorLineNumber.activeForeground": "#000000",
			'editor.focusBorder': 'none',
			'editorSuggestWidget.background': '#FFFFFF',
			'editorSuggestWidget.border': '#505050',
			'editorSuggestWidget.foreground': '#202020',
			'editorSuggestWidget.selectedBackground': '#2E475D',
			'editorSuggestWidget.highlightForeground': '#202020',
			'list.hoverBackground': '#2E475D',
			'editor.hoverHighlightBackground': '#FFFFFF',
			'editorHoverWidget.background': '#FFFFFF',
			'editorHoverWidget.border': '#505050'
		}
	});
}
