import GenericUI from '../generic.ui';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {Translator} from '../../translator';

// CSS UI
import './styles/desktop.css';
import './styles/smartphone.css';
import './styles/tablet.css';

export default class ConfirmDialogBox extends GenericUI {
    constructor(props)
    {
        super(props);
        this.state = {
            visible: false
        }
    }

    componentDidMount()
    {
        window.ConfirmDialogBox = this;
    }

    show( title, message, onAccept, onReject )
    {
        this.setState( { visible: true } );
        setTimeout( () => {
            confirmDialog({
                message: Translator.get(message),
                header: Translator.get(title),
                icon: 'fas fa-question-circle',
                position: "center",
                modal: true,
                acceptLabel: Translator.get("yes"),
                rejectLabel: Translator.get("no"),
                defaultFocus: 'reject',
                accept: () => { this.close( onAccept) },
                reject: () => { this.close( onReject) }
            });
        }, 500 );
    }

    close( callback )
    {
        this.setState( { visible: false } );
        if( callback )
        {
            callback();
        }
    }

    render()
    {
        if( this.state.visible )
        {
            return (
                <>
                    <ConfirmDialog className="aoz-confirm" />
                </>
            );
        }
        return null;
    }
}
