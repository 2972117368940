import {Translator} from "../../../translator";

var startMsg = Date.now();
var intervalMsg = null;
const eventBus = document;

/**
 * @description                                         Show a message into the bar messenger
 * @param {String} type                                 Type of message (error, warning, info, default) 
 * @param {String} message                              Message to show
 */
export function showMessage( type, message )
{
    var elm = document.querySelector( '.messenger' );
    if(elm)
    {
        elm.style.zIndex = "5000";
    }

    var event = new CustomEvent( 'messenger:onMessage', { detail: { type: type, text: Translator.get( message ) } } );
    eventBus.dispatchEvent( event );
    startMsg = Date.now();
    if( intervalMsg )
    {
        clearInterval( intervalMsg );
    }

    intervalMsg = setInterval( () => {
        if( Date.now() - startMsg > 5000 )
        {
            closeMessenger();
            clearInterval( intervalMsg );
        }
    }, 1000 );
}

/**
 * @description                                         Handle the resize event
 * @param {Event} event                                 The resize event 
 */
export function handleResize( event )
{

    var elm = document.getElementById( "messenger-popup" );
    if( elm )
    {
        var width = elm.offsetWidth;
    }
}

/**
 * @description                                         Close the messenger
 */
export function closeMessenger()
{
    var event = new CustomEvent( 'messenger:onMessage', { detail: { type: 'none', text: '' } } );
    eventBus.dispatchEvent( event );
}