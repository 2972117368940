import GenericUI from '../generic.ui.js';
import { Translator } from '../../translator/index.js';
import Devices from '../devices';

// CSS UI
import './styles/desktop.css';
//import './styles/smartphone.css';
//import './styles/tablet.css';

export default class MessagesPanel extends GenericUI {
    
    constructor( props ) {      
        super( props );
        this.state = {
            visible: false,
            messages: [],
            nbErrors: 0,
            nbWarnings: 0,
            displayWarnings: false
        };
    }
    
    componentDidMount()
    {
        window.MessagesPanel = this;
    }

    // line: mandatory
    // type: 'error' or 'warning' or falsey to ignore
    removeMessagesForLine(line, type) {
        let msgs = [...this.state.messages];        // shallow copy needed for setState
        let update = false;

        for (let i=0; i<msgs.length; i++) {
            if (msgs[i].line == line && (!type || (type && msgs[i].type == type))) {
                msgs.splice(i, 1);      // remove error
                i--;
                update = true;
            }
        }

        if (update) this.setState( { messages:  msgs } );
    }

    render()
    {
        super.render();
        /*if( this.state.messages.length == 0 || this.state.visible == false)
        {
            return null;
        }*/
        return (
            <div className="messages-panel" style={!this.state.visible ? {display:'none'} : {}}>
                <div className="messages-panel-title">
                    {Translator.get('transpiler_messages') + (!this.state.nbErrors && !this.state.nbWarnings ? Translator.get('transpiler_messages_no_errors') : Translator.get('transpiler_messages_errors_count', [this.state.nbErrors, this.state.nbWarnings]))}
                    <span className="fa fa-times" onClick={ (e) => this.setState( { visible: false } ) }></span>
                </div>
                <div className="messages-panel-content">
                    {this.state.messages.map( ( message, index ) => {
                        return(
                            <Message key={message.key} message={message.messageText} type={message.type} path={message.path} line={message.line} column={message.column} />
                        );
                    })}
                </div>
            </div>
        );
    }
}

function Message( props )
{
    if (props.type=="warning" && !window.MessagesPanel.state.displayWarnings) return null;
    
    var opt = { path: props.path, type: props.type, line: props.line, column: props.column, message: props.message };
    return(
        <div className={`message ${props.type}`} onClick={(e) => { showCodeFrom(opt); }}>
            {props.message}
        </div>
    );
}

function showCodeFrom( data )
{
    if( window.CodeEditor )
    {
        window.CodeEditor.showTabContent( data.path, data );
    }
}