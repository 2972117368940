document.addEventListener( 'treeview:update', ( event ) => {
    if( window.ProjectsTree )
    {
        window.ProjectsTree.setState( { projects: event.detail.projects, tree: event.detail.tree } );
        window.TreeView.setState( { items: event.detail.tree } );

        var projects = event.detail.projects;
        if( projects && projects.length > 0 )
        {
            var item = {
                key: '/' + projects[0].label + '/' + projects[0].main,
                label: projects[0].main,
                icon: 'icon_aoz_file',
                language: 'aoz'
            }
            var event = new CustomEvent( 'codeeditor:open-file', { detail: { item: item } } );
            document.dispatchEvent( event );
        }
    }
} );

document.addEventListener('treeview:itemsChanged', (event) => {
    if( window.TreeView )
    {
        window.TreeView.setState( { items: event.detail.items } );

        if( window.TreeView.props.onItemsChanged )
        {
            window.TreeView.props.onItemsChanged(event.detail.items);
        }
    }
});

document.addEventListener('treeview:itemSelected', (event) => {
    if( window.TreeView )
    {
        window.TreeView.setState( { selectedItem: event.detail.item } );

        if( window.TreeView.props.onSelectItem )
        {
            window.TreeView.props.onSelectItem(event.detail.item);
        }
    }
});

document.addEventListener('treeview:itemExpanded', (event) => {
    if( window.TreeView )
    {
        window.TreeView.setState( { selectedItem: event.detail.item } );

        if( window.TreeView.props.onExpendItem )
        {
            window.TreeView.props.onExpendItem(event.detail.item);;
        }
    }    
});    

document.addEventListener('treeview:itemCollapsed', (event) => {
    if( window.TreeView )
    {
        window.TreeView.setState( { selectedItem: event.detail.item } );

        if( window.TreeView.props.onCollapseItem )
        {
            window.TreeView.props.onCollapseItem(event.detail.item);;
        }
    }    
});
