
import React, {useState} from 'react';
import GenericUI from "../generic.ui";
import { Translator } from "../../translator";
import {FileUtils} from "../../utils/file.utils";
//import "./styles/tablet.css";
//import "./styles/smartphone.css";
import "./styles/desktop.css";

function clickTab( path )
{
    if( window.TabBar )
    {
        window.TabBar.selectTab( path );
    }
}

function closeTab( path )
{
    var event = new CustomEvent( 'tabbar:close-tab', { detail: { path: path } } );
    document.dispatchEvent( event );
}

export default class TabBar extends GenericUI {
    constructor( props ) {
        super( props );
        this.state = {
            items: props.items
        };
    }
    
    componentDidMount() {
        window.TabBar = this;
    }

    TabItem( props )
    {
        var key = this.props.selectedTab;
        //var selected = ( key === props.key ) ? 'selected' : ''
        var selected = ( props.selected ) ? 'selected' : '';
        // without extension
        var tabTitle = FileUtils.getBasename(props.label).substring(0, FileUtils.getBasename(props.label).indexOf('.'));
        return (
            <td key={props.key} path={props.path} className={`tabitem ${ selected }`} alt={props.path} title={props.path} onClick={ (e) => { clickTab(props.path) } }>
                <div className={`tabicon ${props.icon}`}></div>
                <div className="tabtitle">{ ( (props.modified) ? '* ' : '' ) + tabTitle }</div>
                <div className="tabclose" alt={Translator.get('close_tab')} title={Translator.get('close_tab')} onClick={ (e) => { closeTab(props.path) }}><i className="fa-solid fa-times"></i></div>
            </td>
        );
    }

    updateItem( item, lastKey )
    {
        var newItems = this.state.items;

        if( newItems.length > 0 )
        {
            for( var i = 0; i < newItems.length; i++ )
            {
                newItems[i].selected = false;
                if( newItems[i].key === lastKey )
                {
                    if( window.contents)
                    {
                        window.contents[ item.key] = window.contents[ lastKey ];
                        delete window.contents[ lastKey ];
                    }
                    newItems[i].key = item.key;
                    newItems[i].path = item.key;
                    newItems[i].label = item.label;
                    newItems[i].selected = true;
                }
            }
            this.selectTab( item.key );
        }
    }

    selectTab( key )
    {
        var n = -1;
        var newItems = this.state.items;
        var loaded = false;
        var elm = document.querySelector( 'td[path="' + key + '"]' );
        console.log( elm );
        if( newItems.length > 0 )
        {
            for( var i = 0; i < newItems.length; i++ )
            {
                newItems[i].selected = false;
                if( newItems[i].key === key )
                {
                    newItems[i].selected = true;
                    n = i;
                }
            }

            if( n > -1 )
            {
                if( window.CodeEditor)
                {
                    window.CodeEditor.showTabContent( key );
                    this.setState( { items: newItems } );
                    return true;
                }
                return false;
            }
            return false;
        }
        return false;
    }

    modifiedTab( key, value )
    {
        if( value == undefined)
        {
            value = true;
        }
        var n = 0;
        var newItems = this.state.items;
        if( newItems.length > 0 )
        {
            for( var i = 0; i < newItems.length; i++ )
            {
                if( newItems[i].key === key )
                {
                    newItems[i].modified = value;
                    n = 1;
                }
            }
            
            if( n == 1 )
            {
                this.setState( { items: newItems } );
            }
        }
    }

    isModified( key )
    {
        var newItems = this.state.items;
        if( newItems.length > 0 )
        {
            for( var i = 0; i < newItems.length; i++ )
            {
                if( newItems[i].key === key )
                {
                    return newItems[i].modified;
                }
            }
        }
        return false;
    }

    render()
    {
        super.render();
        return (
            <div id={this.props.id} className="tabbar">
                <table className="content-tabbar">
                    <tbody>
                        <tr>
                            { this.state.items.map( (tab) => { return this.TabItem( tab ) } ) }
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
