import GenericUI from '../generic.ui.js';
import { Translator } from '../../translator/index.js';
import "./styles/desktop.css"
//import "./styles/smartphone.css"
//import "./styles/tablet.css"

export default class LoaderAnim extends GenericUI {

    constructor( props )
    {
        super(props);
        this.state = {
            type: (props.type) ? props.type : 'cube',
            visible: (props.visible) ? props.visible : false,
            label: props.label,
            extra: undefined
        }
    }

    componentDidMount()
    {
        window.LoaderAnim = this;    
    }

    show( type, label, extra )
    {
        this.setState( { type: type, visible: true, label: label, extra: extra } );
    }

    hide()
    {
        this.setState( { visible: false, extra: undefined } );
    }

    launchQRCodeURL()
    {
        if( window.LoaderAnim)
        {
            if( window.LoaderAnim.state.extra !== undefined )
            {
                window.open( window.LoaderAnim.state.extra.url, '_blank' );
            }
            window.LoaderAnim.hide();
        }
    }

    render()
    {
        if( !this.state.visible )
        {
            return null;
        }

        if( this.state.type.toLowerCase() === 'spinner')
        {
            return (
                <div className="mask loader-animation">
                    <div className="spinner"></div>
                    <span className="label">{this.state.label}</span>
                </div>
            );
        }

        if( this.state.type === 'cube')
        {
            return (
                <div className="mask loader-animation">
                    <div className="cube">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    { (this.state.label !== '' ) ? <span className="label">{this.state.label}</span> : null }
                </div>
            );
        }

        if( this.state.type === 'build')
        {
            return (
                <div className="mask loader-animation">
                    <div className="build">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                            <circle fill="#FFC60A" stroke="#FFC60A" strokeWidth="6" r="15" cx="40" cy="65">
                                <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
                            </circle>
                            <circle fill="#FFC60A" stroke="#FFC60A" strokeWidth="6" r="15" cx="100" cy="65">
                                <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
                            </circle>
                            <circle fill="#FFC60A" stroke="#FFC60A" strokeWidth="6" r="15" cx="160" cy="65">
                                <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
                            </circle>
                        </svg>
                    </div>
                    <span className="label">{this.state.label}</span>
                    <button className="cancel-button" onClick={ (e) => { window.buildCancelled = true } }>{Translator.get( 'cancel' )}</button>
                </div>
            );
        }

        if( this.state.type == 'potion')
        {
            return (
                <div className="mask loader-animation">
                    <div className="potion"></div>
                    <span className="label">{this.state.label}</span>
                    <button className="cancel-button" onClick={ (e) => { window.buildCancelled = true } }>{Translator.get( 'cancel' )}</button>
                </div>
            );            
        }

        if( this.state.type == 'qrcode' && this.state.extra !== undefined)
        {
            return (
                <div className="mask loader-animation" onClick={(e) => { this.hide() }}>
                    <span className="label qrcode-info">{Translator.get('qrcode_info')}</span>
                    <div className="qrcode" style={{'backgroundImage': 'url(' + this.state.extra.imgUrl + ')'}} onClick={(e) => { this.launchQRCodeURL(e) }}></div>
                    <span className="label qrcode-url" onClick={(e) => { this.launchQRCodeURL(e) }}>{this.state.label}</span>
                </div>
            );            
        }

        if( this.state.type === 'flip')
        {
            return (
                <div className="mask loader-animation">
                    <div className="flipping"></div>
                    { (this.state.label !== '' ) ? <span className="label">{this.state.label}</span> : null }
                </div>
            );
        }
    }
}