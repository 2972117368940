import React, {useState, useRef, useEffect} from 'react';
import Editor from "@monaco-editor/react";
import GenericUI from "../generic.ui";

import {initSuggestions} from "./syntax/suggestions.js";
import {initDarkTheme} from "./syntax/theme-dark.js";
import {initLightTheme} from "./syntax/theme-light.js";
import {initTokenizer} from "./syntax/tokenizer.js";
import {Translator} from "../../translator";
import {ProjectAPI} from "../../../api/project/index.js";
import TabBar from "../tabbar/index.js";
//import "./styles/tablet.css";
//import "./styles/smartphone.css";
import "./styles/desktop.css";
import { searchTab, loadFile, getIcon } from './func/index.js';

window.contents = {};
if( window.monaco )
{
    window.monaco.languages.register( { id: "aoz" } );
    window.monaco.editor.setTheme( 'aoz-dark' );
}
/*
window._intervalHandle = setInterval( function()
{        
    if( window.CodeEditor && window.CodeEditor.state.tabs )
    {
        if( window.CodeEditor.state.tabs.length > 0  && window.CodeEditor.monacoRef && window.CodeEditor.monacoRef.current )
        {
            var elm = document.querySelector( '.monaco-editor' );
            if( elm )
            {
                if(elm.parentNode )
                {
                    elm.parentNode.style.height = 'calc(100% - 42px)';
                }
            }
        }
        window.CodeEditor.showPosition();
    }
}, 100 ); 
*/

document.addEventListener( 'codeeditor:open-file', ( event ) => {
    loadFile( event.detail.item );
} );

document.addEventListener( 'codeeditor:file-loaded', ( event ) => {
    if( window.CodeEditor )
    {
        var newTabs = window.CodeEditor.state.tabs;
        var item = event.detail;
        var loaded = false;
        for( var i = 0; i < newTabs.length; i++ )
        {
            if( newTabs[i].path === item.key )
            {
                loaded = true;
                break;
            }
        }

        if( !loaded )
        {
            contents[item.key] = item.code;
            newTabs.push( { key: item.key, path: item.key, label: item.label, icon: item.icon, selected: true, modified: false, code: window.contents[item.path], editor: item.editor, syntax: item.language, options: item.options, theme: item.theme } );
            window.CodeEditor.setState( { 
                selectedTab: item.key,
                tabs: newTabs,
                path: item.key,
                code: window.contents[ item.key ],
                syntax: item.language,
                options: item.options,
                theme: item.theme
            } );

            setTimeout( function() {
                window.CodeEditor.showPosition();
            },500 );
        }
        if( window.TabBar )
        {
            window.TabBar.selectTab( item.key );
        }
        
        if( window.ToolBar )
        {
            var elm = document.getElementById( 'cmd_run' );
            if( elm )
            {
                elm.removeAttribute( 'disabled' );
            }

            elm = document.getElementById( 'cmd_publish' );
            if( elm )
            {
                elm.removeAttribute( 'disabled' );
            }
        }        
    }   
} )

document.addEventListener( 'tabbar:close-tab', ( event ) => {
    if( window.CodeEditor )
    {
        if( window.CodeEditor.state.tabs.length > 0 && window.CodeEditor.state.selectedTab && window.CodeEditor.state.selectedTab.trim() != '' )
        {
            if( window.TabBar )
            {
                if( window.TabBar.isModified( window.CodeEditor.state.selectedTab ) )
                {
                    if( window.CodeEditor.state.path && window.contents[window.CodeEditor.state.path] )
                    {

                        ProjectAPI.saveFile( window.CodeEditor.state.path, window.contents[window.CodeEditor.state.selectedTab])
                            .then( (response) => {
                                if( window.TabBar )
                                {
                                    window.TabBar.modifiedTab( window.CodeEditor.state.selectedTab, false );
                                }
                            })
                            .catch( (error) => {
                                if(window.Messenger)
                                {
                                    window.Messenger.showMessage( 'error', error );
                                    return;
                                }
                            });
                    }
                }
            }
        }        

        var newTabs = window.CodeEditor.state.tabs;
        var tab = searchTab( newTabs, event.detail.path );
        if( tab )
        {
            var index = newTabs.indexOf( tab );
            newTabs.splice( index, 1 );
            if( contents[ event.detail.path ] )
            {
                delete contents[ event.detail.path ];
            }

            if( newTabs.length > 0 )
            {
                newTabs[0].selected = true;
                window.CodeEditor.showTabContent( newTabs[0].key );
            }
            else
            {
                var leftElm = document.querySelector( '.status-left');
                var rightElm = document.querySelector( '.status-right');
    
                if( leftElm )
                {
                    leftElm.innerHTML = '';
                }
    
                if( rightElm )
                {
                    rightElm.innerHTML = '';
                }
            }

            if( newTabs.length == 0 )
            {
                if( window.ToolBar )
                {
                    var elm = document.getElementById( 'cmd_run' );
                    if( elm )
                    {
                        elm.setAttribute( 'disabled', 'yes' );
                    }

                    elm = document.getElementById( 'cmd_publish' );
                    if( elm )
                    {
                        elm.setAttribute( 'disabled', 'yes' );
                    }
                }
            }
            else
            {
                if( window.ToolBar )
                {
                    var elm = document.getElementById( 'cmd_run' );
                    if( elm )
                    {
                        elm.removeAttribute( 'disabled' );
                    }

                    elm = document.getElementById( 'cmd_publish' );
                    if( elm )
                    {
                        elm.removeAttribute( 'disabled' );
                    }
                }
            }

            window.CodeEditor.setState( { tabs: newTabs } );
        }
    }
} );

setTimeout( function()
{
    var css = document.createElement("style");
    css.innerHTML = '.monaco-editor .view-overlays .current-line { border: none; }';
    document.body.appendChild( css );
}, 500 );

export default class CodeEditor extends GenericUI {
    
    constructor( props )
    {
        super(props);
        this.monacoRef = React.createRef();
        this.editorRef = React.createRef();
        this.ai_decorations = null;
        //this.markersToRemove = [];

        this.state = {
            selectedTab: '',
            tabs: [],
            theme: 'aoz-dark',
            code: '',
            path: '',
            syntax: 'aoz',
            options: {
                autoIndent: 'full',
                automaticLayout: true,
                contextmenu: true,
                cursorStyle: 'line',
                fontFamily: 'monospace',
                fontSize: 16,
                glyphMargin: true,                
                hideCursorInOverviewRuler: true,
                lineHeight: 24,
                lineNumbersMinChars: 3,
                matchBrackets: 'always',
                minimap: {
                  enabled: false,
                },
                readOnly: false,
                roundedSelection: false,
                scrollbar: {
                  horizontalSliderSize: 6,
                  verticalSliderSize: 6,
                },
                selectOnLineNumbers: true,
            },
        };
        this.handleEditorDidMount = this.handleEditorDidMount.bind(this);
    }

    componentDidMount() {
        window.CodeEditor = this;
    }
    
    handleEditorDidMount(editor, monaco) {
        let self = this;
        this.monacoRef.current = monaco;
        this.editorRef.current = editor;
        
        editor.onMouseDown(function (e) {
            //console.log("clicked");
            //console.log(e.target);

            const lineNumber = e.target.position.lineNumber; //editor.getPosition().lineNumber;
            const text = editor.getModel().getLineContent(lineNumber);
            
            if (text) {
                if (e.target.type == monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN) {
                    let command = self.lineHasError(lineNumber) ? "fix_selection" : "explain_selection";

                    window.AOZMagic.sendMessage({
                        "from": "IDE-v2",
                        "command": command,
                        "selection": text,
                        "line": lineNumber,
                        "code": editor.getValue()
                    });

                    window.MessagesPanel?.setState( { visible: false } );
                }
            }

        });

        /* FVL TOFIX: this deletes the currently edited marker BUT the other markers' positions are NOT updated correctly.
            Probably the editor does not like we delete a marker in onDidChangeModelContent
            Tried using onDidChangeModelDecorations (no diff) and onDidChangeMarkers (not fired anyway)
            getModelMarkers() always return the same range position even though the decorations of the markers HAVE moved
            and calling setModelMarkers seem to re-apply the values in getModelMarkers() ...
        */
        editor.onDidChangeModelContent((e) => {
            //console.log("onDidChangeModelContent", e);
            //let markers = monaco.editor.getModelMarkers();
            let msgs = [...window.MessagesPanel?.state.messages || []];  // shallow copy
            let pos = editor.getPosition();
            // very basic way to remove markers: 
            // if cursor position is on a marker, we consider the change impacted the marker
            // and therefore we remove it
            for (let i=0; i< msgs.length; i++) {
                let m = msgs[i];
                if (m.marker && pos.lineNumber == m.startLineNumber && pos.column >= m.startColumn && pos.column <= m.endColumn) {
                    
                    monaco.editor.setModelMarkers(editor.getModel(), m.key, []);
                    //this.markersToRemove.push(m.key);
                    msgs[i].marker = false;
                    window.MessagesPanel.setState({messages: msgs});
                    break;
                }
            }
        })

        /*editor.onDidChangeModelDecorations((e) => {
            console.log("onDidChangeModelDecorations", e);
            if (this.markersToRemove.length) {
                for (let owner of this.markersToRemove) {
                    monaco.editor.setModelMarkers(editor.getModel(), owner, []);
                }
                this.markersToRemove = [];
            }
        })

        monaco.editor.onDidChangeMarkers((e) => {
            console.log("onDidChangeMarkers", e);
        })*/

        editor.onDidChangeCursorPosition((e) => {
            // FVL TOFIX: this is not enough: it is possible to change content without moving cursor (DEL key)
            // TODO/TOFIX: hook this with onDidChangeModelContent as well

            /*console.log("change cursor pos")
            console.log(JSON.stringify(e));*/

            this.showPosition();
            let ln = e.position.lineNumber;
            //console.log(ln);

            

            const text = editor.getModel().getLineContent(editor.getPosition().lineNumber);

            if (text) {
                let tooltip = ( this.lineHasError(ln) ? 'aozm_fix_line' : 'aozm_explain_line' ) ;
                this.setAOZMarginIcon(tooltip, ln);
            }

                
            
        });
        
        editor.onDidChangeCursorSelection((e) => {
            //console.log("change cursor sel")
            //console.log(JSON.stringify(e));

            if (!window.AOZMagic?.listening) return;

            let lineNumber = editor.getPosition().lineNumber;
            let columnNumber = e.selection.positionColumn;
            let selection = editor.getModel().getValueInRange(editor.getSelection())
            let line = editor.getModel().getLineContent(lineNumber);
            let lastSelectedLineLength = editor.getModel().getLineContent(e.selection.endLineNumber).length;
            let code = editor.getValue();

            let data = {
                code: code,
                position: {
                    code: line,
                    line: lineNumber,
                    col: columnNumber
                },
                selection: {
                    code: selection,
                    extended: editor.getModel().getValueInRange({
                        startColumn: 1,
                        startLineNumber: e.selection.startLineNumber,
                        endColumn: lastSelectedLineLength + 1,
                        endLineNumber: e.selection.endLineNumber
                    }),
                    lines: (e.selection.endLineNumber - e.selection.startLineNumber + 1),
                    start: {
                        line: e.selection.startLineNumber,
                        col: e.selection.startColumn
                    },
                    end: {
                        line: e.selection.endLineNumber,
                        col: e.selection.endColumn
                    }
                }
            }

            window.AOZMagic.sendMessage({
                "from": "IDE-v2",
                "event": "cursor_position",
                "code": data.code,
                "position": data.position,
                "selection": data.selection
            });

        });

        editor.addAction( {
            id: 'AI_EXPLAIN_SELECTION',
            label: 'AI: explain selected code',
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.F12],
	        contextMenuGroupId: 'AI',
            run: (ed) => {
                const text = editor.getModel().getValueInRange(editor.getSelection())
                if (text) {
                    window.AOZMagic.sendMessage({
                        "from": "IDE-v2",
                        "command": "explain_selection",
                        "selection": text,
                        "code": editor.getValue()
                    });
                }
            }
        });
    
        editor.addAction( {
                id: 'TOGGLE_MINIMAP',
                label: 'Toggle Minimap',
                run: (ed) => {
                    // options is a sub-object of state.
                    // its reference MUST change for React to re-render the new state
                    // (React only checks the FIRST level of props in state)
                    // Also it's best to not use the state directly when using setState
                    // hence we first create a shallow copy
                    let newState = Object.assign({}, self.state);
                    // newState.options still refers to self.state.options
                    // so we also create a shallow copy of it
                    let newOptions = Object.assign({}, newState.options);
                    newOptions.minimap.enabled = !newOptions.minimap.enabled;
                    // assign the new options to the new state
                    newState.options = newOptions;
                    // assign the new state
                    self.setState(newState);
                }
            }
        );


        initSuggestions(monaco);
        initLightTheme(monaco);
        initDarkTheme(monaco);
        initTokenizer(monaco);

        if( window.CodeEditor )
        {
            monaco.languages.register( { id: (window.CodeEditor.state.syntax) ? window.CodeEditor.state.syntax : "aoz" } );
            monaco.editor.setTheme( ( window.CodeEditor.state.theme ) ? window.CodeEditor.state.theme : 'aoz-dark' );
            if( window.CodeEditor.monacoRef )
            {
                window.CodeEditor.monacoRef.current = monaco;
            }
            if( window.CodeEditor.editorRef )
            {
                window.CodeEditor.editorRef.current = editor;
            }
        }
    }
    
    handleEditorOnChange(value, event) {
        if( window.CodeEditor )
        {
            if( contents[ window.CodeEditor.state.path ] !== value )
            {
                contents[ window.CodeEditor.state.path ] = value;
                if( window.TabBar )
                {
                    window.TabBar.modifiedTab( window.CodeEditor.state.path );
                }
            }
            window.CodeEditor.showPosition();
        }
    }

    handleEditorValidation(markers) {
        // triggered when markers are ready
    }

    showPosition()
    {
        if ( window.CodeEditor?.editorRef.current )
        {
            var position = window.CodeEditor.editorRef.current.getPosition();

            var leftElm = document.querySelector( '.status-left');
            var rightElm = document.querySelector( '.position');

            if( leftElm )
            {
                leftElm.innerHTML = window.CodeEditor.state.path;
            }

            if( rightElm )
            {
                if( window.StatusBar )
                {
                    rightElm.innerHTML = Translator.get( 'position_row' ) + ' ' + position.lineNumber + ', ' + Translator.get( 'position_column' ) + ' ' + position.column;
                }
            }

        }        
    }

    showTabContent( key, withMsg ) 
    {
        if( this.state.tabs.length > 0 && this.state.selectedTab && this.state.selectedTab.trim() != '' )
        {
            var selTab = this.state.selectedTab;
            var selPath = this.state.path;
            if( window.TabBar )
            {
                if( window.TabBar.isModified( selPath ) )
                {
                    if( this.state.path && window.contents[selPath] )
                    {
                        ProjectAPI.saveFile( this.state.path, window.contents[selTab])
                            .then( (response) => {
                                if( window.TabBar )
                                {
                                    window.TabBar.modifiedTab( selPath, false );
                                }
                            })
                            .catch( (error) => {
                                if(window.Messenger)
                                {
                                    window.Messenger.showMessage( 'error', error );
                                    return;
                                }
                            });
                    }
                }
            }
        }

        var tab = searchTab( this.state.tabs, key );
        if( tab )
        {
            this.setState( { 
                selectedTab: tab.key,
                path: tab.path,
                code: window.contents[ tab.path ],
                syntax: tab.syntax,
                options: tab.options,
                theme: tab.theme,
                /*error: (withMsg && withMsg.type) ? true : false,
                line: (withMsg && withMsg.line) ? withMsg.line : 0,
                message: (withMsg && withMsg.message) ? withMsg.message : ''*/
            } );
        }

        if( withMsg )
        {
            let self = this;
            setTimeout( function() {
                // TOFIX: this code should not be triggered by a timeout
                // but by a real event
                // TOFIX: when changing tab, 
                // - tab does not appear selected if multiple tabs are present

                self.highlightWordAtPosition(withMsg.line, withMsg.column, withMsg.type=='error');
            }, 100 );
        }

    }

    highlightWordAtPosition(lineNumber, column, fix, focus=true) {
        if( window.CodeEditor )
        {
            let ed = this.editorRef.current
            let pos = { lineNumber: lineNumber, column: column };
            ed.setPosition( pos );
            let word = ed.getModel().getWordAtPosition(pos);
            ed.revealLine( lineNumber );
            if (word) ed.setSelection( new monaco.Range(pos.lineNumber, word.startColumn, pos.lineNumber, word.endColumn) )
            
            this.setAOZMarginIcon(fix ? 'aozm_fix_line' : '', lineNumber)
            if (focus) ed.focus();
        }
    }

    clearAOZMargins() {
        this.ai_decorations?.clear();
    }

    setAOZMarginIcon(tooltip, line) {
        this.clearAOZMargins();

        this.ai_decorations = this.editorRef.current.createDecorationsCollection([
            {
                range: new monaco.Range(line, 1, line, 1),
                options: {
                    isWholeLine: true,
                    glyphMarginClassName: "aoz-icon fa-beat-fade call-aoz" + ( tooltip == 'aozm_fix_line' ? '-error' : '') ,
                    glyphMarginHoverMessage: { value: Translator.get(tooltip) }
                },
            },
        ]);
    }

    lineHasError(line) {
        if ( window.MessagesPanel?.state.messages ) {
            for (let msg of window.MessagesPanel.state.messages) {
                if (msg.line == line && msg.type == 'error') {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        super.render();
        if( this.state.tabs.length > 0 )
        {
            return (
                <div id="code-editor-container" className="code-editor-container">
                    <TabBar id="tab-editor" items={ this.state.tabs } selectedTab={ this.state.selectedTab } onTabClick={ (e) => { this.tabClick(e) } }/>
                    <Editor id="code-editor"
                        glyphMargin={true}
                        language={this.state.syntax}
                        theme={this.state.theme}
                        path={this.state.path}
                        value={window.contents[this.state.path]}
                        options={this.state.options}
                        onMount={this.handleEditorDidMount}
                        onChange={ this.handleEditorOnChange }
                        onValidate={ this.handleEditorValidation }
                    />
                </div>
            )
        }
        else{

            return null;
        }
    }

}