/**
* @description                                          String utility.
* @version                                              1.0.2
* @copyright                                            (c)2020-2024 AOZ Studio. All Rights Reserved.
*/
export const StringUtils = {

    /**
     * @description                                     Replace a string in another string
     * @param {string} str                              Source
     * @param {string} search                           String to replace
     * @param {string} replace                          Replacement string
     * @returns {string}                                New string with the replacement
     * @example
     * ```javascript	
     * const StringUtils = require( '../utils/string.utils.js' );
     *
     * // Replace a string in another string
     * var str = 'Hello World';
     * var newStr = StringUtils.strReplace( str, 'World', 'Universe' );
     * console.log( newStr );
     * ```
     */
    strReplace: function(str, search, replace) {
        return str.split(search).join(replace);
    },

    /**
     * @description                                     Generate a random ID
     * @param {Number} size                             Size of the ID (default: 20)
     * @returns {string}                                Random ID
     * @example
     * ```javascript
     * const StringUtils = require( '../utils/string.utils.js' );
     *  
     * // Generate a random ID of 10 characters
     * var id = StringUtils.generateID( 10 );
     * console.log( id );
     * ```
     */
    generateID: function( size )
    {
        if( size == undefined || size == null || size == 0 || isNaN( size ) )
        {
            size = 20;
        }
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < size; i++ )
        {
            result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
        }
        return result;
    }
}

