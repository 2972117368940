import { AuthenticationAPI } from '../authentication/index.js';
import {NetUtils} from '../../classes/utils/net.utils.js';
import {FileUtils} from '../../classes/utils/file.utils.js';
import {baseUrl} from '../config.js';

export const ProjectAPI = {

    /**
     * 
     */
    getDefaultManifest()
    {
        return {
            version: "9",
            infos:
            {
                type: "pc",
                applicationName: "My Project",
                author: "Me",
                version: "1.0.0",
                date: "2020-12-01",
                copyright: "My company",
                start: "main.aoz",
                template: "templates/template_cross",
            },

            compilation:
            {
                platform: "aoz",				
                keymap: "aoz",					
                machine: "modern",				
                speed: "fast",					
                syntax: "enhanced",				
                endian: "little",				

                noWarning: [],					
                displayEndAlert: true,			
                displayErrorAlert: true,		
                useLocalTabs: true,				
                useAssetsResources: true       
            },

            display:
            {
                tvStandard: "pal",
                refreshRate: 60,
                resolution: "1920x1080",
                width: 1920,
                height: 1080,
                background: "color",
                backgroundColor: "#000000",
                bodyBackgroundColor: "#FFFFFF",
                bodyBackgroundImage: "./runtime/resources/star_night.jpeg",
                scaleX: 1,
                scaleY: 1,
                screenScale: 1,
                fps: false,
                fpsFont: "12px Verdana",
                fpsColor: "#FFFF00",
                fpsX: 10,
                fpsY: 16,
                fullPage: true,
                fullScreen: false,
                keepProportions: true,
                fullScreenIcon: false,
                fullScreenIconX: -34,
                fullScreenIconY: 2,
                fullScreenIconImage: "./runtime/resources/full_screen.png",
                smallScreenIconImage: "./runtime/resources/small_screen.png",
                smoothing: true,
                orientation_detection: "landscape",
            },
            bootScreen:
            {
                active: true,
                waitSounds: false,
                clickSounds: false,
            },
           collisions:
            {
                method: "fine",
                precision: 75,
                alphaThreshold: 1
            },
            rainbows:
            {
                mode: "slow",
            },
            fonts:
            {
                listFonts: "PC",
                amiga:
                [
                ],
                google:
                [
                ]
            },
            sounds:
            {
                mode: "PC",
                volume: 1.0,
                preload: true,
                numberOfSoundsToPreload: 32,
                soundPoolSize: 32
            },
            gamepad:
            {
                mapping:
                {
                    "up": "ArrowUp",
                    "down": "ArrowDown",
                    "left": "ArrowLeft",
                    "right": "ArrowRight",
                    "fire": "Space"
                }
            },
            fileSystem:
            {
                caseSensitive: false
            },
            default:
            {
                screen:
                {
                    x: 0,
                    y: 0,
                    width: 1920,
                    height: 1080,
                    numberOfColors: 64,
                    pixelMode: "lowres",
                    palette:
                    [
                        "#000000", "#FFFFFF", "#D1D1D1", "#A2A2A2", "#737373", "#444444", "#FF0000", "#D10000",
                        "#A20000", "#730000", "#440000", "#00FF00", "#00D100", "#00A200", "#007300", "#004400",
                        "#FFFF00", "#D1D100", "#A2A200", "#737300", "#444400", "#FF7F00", "#E27100", "#C46200",
                        "#A65300", "#884400", "#0000FF", "#0000D1", "#0000A2", "#000073", "#000044", "#00FFFF",
                        "#00D1D1", "#00A2A2", "#007373", "#004444", "#FF00FF", "#D100D1", "#A200A2", "#730073",
                        "#440044", "#FFA100", "#FFB312", "#FFC625", "#FFD837", "#FFEB4A", "#FFFE5D", "#002965",
                        "#123975", "#244985", "#365995", "#4869A5", "#5A79B5", "#BF717F", "#B26773", "#A45D66",
                        "#975359", "#89494C", "#7B3F3F", "#8252B4", "#623E87", "#41295A", "#21152D", "#000000"
                    ],
                    window:
                    {
                        x: 0,
                        y: 0,
                        width: 80,
                        height: 25,
                        border: 0,
                        paper: 0,
                        pen: 1,
                        background: "opaque",
                        font:
                        {
                            name: "IBM Plex Mono",
                            type: "google",
                            height: 39.475
                        },
                        cursorOn: false,
                        cursorImage: "./runtime/resources/cursor_pc.png",
                        cursorColors:
                        [
                            { r: 68, g:68, b:0, a: 128 },
                            { r: 136, g:136, b:0, a: 128 },
                            { r: 187, g:187, b:0, a: 128 },
                            { r: 221, g:221, b:0, a: 128 },
                            { r: 238, g:238, b:0, a: 128 },
                            { r: 255, g:255, b:34, a: 128 },
                            { r: 255, g:255, b:136, a: 128 },
                            { r: 255, g:255, b:204, a: 128 },
                            { r: 255, g:255, b:255, a: 128 },
                            { r: 170, g:170, b:255, a: 128 },
                            { r: 136, g:136, b:204, a: 128 },
                            { r: 102, g:102, b:170, a: 128 },
                            { r: 34, g:34, b:102, a: 128 },
                            { r: 0, g:0, b:68, a: 128 },
                            { r: 0, g:0, b:17, a: 128 },
                            { r: 0, g:0, b:0, a: 128 }
                        ]
                    }
                }
            },
            publish: {
                protect: false,
            }
        }
    },

    /**
     * @description                                     Get the storage informations of the current user
     * @returns {Promise}
     */
    getUserStorageInfos: function()
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", baseUrl + "/projects/storage", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.storage )
                        {
                            resolve( json.data.storage );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    /**
     * @description                                     Get the tree of files of a project
     * @returns {Promise}
     */
    getTreeFiles: function()
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", baseUrl + "/files", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.tree )
                        {
                            resolve( json.data.tree );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    getProjectsList: function()
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", baseUrl + "/projects", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.projects )
                        {
                            resolve( json.data.projects );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    loadProperties: function( path )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", baseUrl + "/project/properties", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'name', path );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );

                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data )
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        } );
        return p;

    },

    updateProject( path, manifest )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/update", true );
                xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded');
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'name', path );

                var params = 'manifest=' + encodeURIComponent( JSON.stringify( manifest ) );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }
                        else
                        {
                            resolve( true );
                        }
                    }
                }
                xhr.send( params );
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    deleteProject: function( path )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("DELETE", baseUrl + "/project/delete", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'name', path );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        console.log( xhr.responseText );
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    createProject: function( manifest )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/new", true );
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'name', manifest.infos.applicationName );

                var params = 'manifest=' + encodeURIComponent( JSON.stringify( manifest ) );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send( params );
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;

    },

    loadFile: function( path )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", baseUrl + "/project/file/load", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.content)
                        {
                            resolve( json.data.content );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    saveFile: function( path, content )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/file/save", true );
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );

                var params = 'data=' + encodeURIComponent( content );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send( params );
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    renameFolder: function( path, newName )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/folder/rename", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
                xhr.setRequestHeader( 'name', newName );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.newPath)
                        {
                            resolve( json.data.newPath );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    renameFile: function( path, newName )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/file/rename", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
                xhr.setRequestHeader( 'name', newName );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data.newPath)
                        {
                            resolve( json.data.newPath );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },
    
    importProject: function( file )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var formData = new FormData();
                formData.append( 'file', file );

                var xhr = new XMLHttpRequest();
                //xhr.file = file;
                xhr.open("POST", baseUrl + "/project/import", true );
//              xhr.setRequestHeader("Content-Type", "multipart/form-data");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', file.name );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send( formData );
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    importResources: function( projectName, files )
    {
        const filesRefused = [
            "aoz","aozinc","aozip",
            "exe","dll","lib","bin","com",
            "sh","bat","cmd","vbs",
            "php","asp","aspx",
            "jsp","java","class",
            "inf","zip","cab","rar","7z","tar","gz","bz2","tgz","tbz2",
            "rom","iso"
        ];

        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var formData = new FormData();
                for( var f = 0; f < files.length; f++ )
                {
                    var file = files[f];
                    var ext = FileUtils.getExtension( file.name );
                    if( filesRefused.indexOf(ext) > -1 )
                    {
                        reject( { file: file.name, msgError: "file_refused" } );
                        break;
                    }

                    formData.append( 'files[]', file );
                }

                var xhr = new XMLHttpRequest();
                //xhr.file = file;
                xhr.open("POST", baseUrl + "/resources/upload", true );
//              xhr.setRequestHeader("Content-Type", "multipart/form-data");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', projectName );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( { file: '', msgError: json.msgError } );
                        }
                        else
                        {
                            resolve( true );
                        }
                    }
                }
                xhr.send( formData );
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( { file: '', msgError: error } ); } );
        });
        return p;
    },

    exportProject: function( path )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/export", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },
    
    createItem: function( path, type, name, format )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/project/" + type + "/new", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
                xhr.setRequestHeader( 'type', type );
                xhr.setRequestHeader( 'name', name );
                xhr.setRequestHeader( 'format', format );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data && json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;
    },

    deleteItem: function( path, type )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("DELETE", baseUrl + "/project/" + type + "/delete", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'path', path );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }

                        if( json.data)
                        {
                            resolve( json.data );
                        }
                    }
                }
                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        });
        return p;

    }
}