import GenericUI from '../generic.ui';
// CSS UI
import './styles/desktop.css';
//import './styles/smartphone.css';
//import './styles/tablet.css';

import {Translator} from '../../translator';
import ToolBar from '../toolbar';
import ProjectTree from '../projectstree';
import CodeEditor from '../codeeditor';
import AIDocPanel from '../aidocpanel';
import StatusBar from '../statusbar';
import MessagesPanel from '../messagespanel';
import LoaderAnim from '../loaderanim';
import AuthenticationPanel from '../authenticationpanel';
import AccountPanel from '../accountpanel';
import Messenger from '../messenger';
import ProjectPanel from '../projectpanel';
import ConfirmDialogBox from '../confirmdialogbox';
import { AuthenticationAPI } from '../../../api/authentication';
import NewItemPanel from '../newitempanel';

window.tryToconnectUser = function()
{
    if( window.Application )
    {
        var state = window.Application.state;
        if( state.context === '' )
        {
            if( state.user )
            {
                AuthenticationAPI.autoConnect( state.user )
                    .then( ( response ) => {
                        if( response.error )
                        {
                            setTimeout( function()
                            {
                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', response.error );
                                }
                                var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                document.dispatchEvent( event );
                            }, 500 );

                            AuthenticationAPI.authVisitor()
                                .then( ( response ) => {
                                    if( response.error )
                                    {
                                        setTimeout( function()
                                        {
                                            if( window.Messenger )
                                            {
                                                window.Messenger.showMessage( 'error', response.error );
                                            }
                                            var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                            document.dispatchEvent( event );
                                        }, 500 );
                                    }
                                    else
                                    {
                                        window.Application.setState( { context: 'visitor', token: response.token } );
                                        setTimeout( function() {
                                            if(window.ProjectsTree)
                                            {
                                                window.ProjectsTree.loadProjects();
                                            }
                                            if( window.StatusBar)
                                            {
                                                window.StatusBar.setState( { showVisitor: true });
                                            
                                            }
                                        }, 1000);
                                    }
                                } )
                                .catch( ( error ) => {
                                    setTimeout( function()
                                    {
                                        if( window.Messenger )
                                        {
                                            window.Messenger.showMessage( 'error', error );
                                        }

                                        var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                        document.dispatchEvent( event );
                                    }, 500 );
                                } );
                        }
                        else
                        {
                            // FVL 18/9/24: var response as returned by autoConnect()
                            // is NOT an object if the promise resolve but is equal to true
                            // thus it throws an error while trying to read response.licence and the rest is not
                            // executed. If the code is put in remarks then there's no user profile loaded and
                            // the user profile panel does not allow to logout
                            // this needs fixing asap ...

                            window.Application.setState(  { context: response.licence.toLowerCase(), token: response.token } );
                            setTimeout( function() {
                                if(window.ProjectsTree)
                                {
                                    window.ProjectsTree.loadProjects();
                                }
                                if( window.StatusBar)
                                {
                                    window.StatusBar.setState( { showVisitor: ( window.Application.state.context.toLowerCase() === 'visitor' || window.Application.state.context.trim() === '' ) });
                                }                                
                            }, 1000);
                        }
                    } )
                    .catch( ( error ) => {
                        setTimeout( function()
                        {
                            if( window.Messenger )
                            {
                                window.Messenger.showMessage( 'error', error );
                                var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                                document.dispatchEvent( event );
                            }
                        },500);
                    } );
            } 
            else
            {
                if( state.token )
                {
                    AuthenticationAPI.authToken( state.token )
                        .then( ( response ) => {
                            if( response.error )
                            {
                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', response.error );
                                }
                                var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                document.dispatchEvent( event );

                                /**
                                AuthenticationAPI.authVisitor()
                                    .then( ( response ) => {
                                        if( response.error )
                                        {
                                            setTimeout( function()
                                            {
                                                if( window.Messenger )
                                                {
                                                    window.Messenger.showMessage( 'error', response.error );
                                                }
                                                var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                                document.dispatchEvent( event );
                                            },500 );
                                        }
                                        else
                                        {
                                            window.Application.setState( { context: 'visitor', token: response.token } );
                                            setTimeout( function() {
                                                if(window.ProjectsTree)
                                                {
                                                    window.ProjectsTree.loadProjects();
                                                }
                                                if( window.StatusBar)
                                                {
                                                    window.StatusBar.setState( { showVisitor: true });
                                                }
                                            }, 1000);                                 
                                        }
                                    } )
                                    .catch( ( error ) => {
                                        setTimeout( function() {
                                            if( window.Messenger )
                                            {
                                                window.Messenger.showMessage( 'error', error );
                                            }
                                            var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                                            document.dispatchEvent( event );
                                        }, 500);
                                    } );
                                */
                            }
                            else
                            {
                                window.Application.setState( { context: ( response.isTry ) ? 'visitor' : 'connected', token: response.token } );

                                setTimeout( function() {
                                    if(window.ProjectsTree)
                                    {
                                        window.ProjectsTree.loadProjects();
                                    }
                                    if( window.StatusBar)
                                    {
                                        window.StatusBar.setState( { showVisitor: ( window.Application.state.context.toLowerCase() === 'visitor' || window.Application.state.context.trim() === '' ) });
                                    }
                                }, 1000);
                            }
                        } )
                        .catch( ( error ) => {
                            setTimeout( function() {
                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', error );
                                }
                                var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                                document.dispatchEvent( event );
                            }, 500 );
                        } );
                }
                else
                {
                    var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                    document.dispatchEvent( event );
                    /**
                    AuthenticationAPI.authVisitor()
                        .then( ( response ) => {
                            if( response.error )
                            {
                                setTimeout( function() {
                                    if( window.Messenger )
                                    {
                                        window.Messenger.showMessage( 'error', response.error );
                                    }
                                    var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                                    document.dispatchEvent( event );
                                }, 500 );
                            }
                            else
                            {
                                window.Application.setState( { context: 'visitor', token: response.token } );
                                setTimeout( function() {
                                    if(window.ProjectsTree)
                                    {
                                        window.ProjectsTree.loadProjects();
                                    }
                                    if( window.StatusBar)
                                    {
                                        window.StatusBar.setState( { showVisitor: true });
                                    }
                                }, 1000);                                
                            }
                        } )
                        .catch( ( error ) => {
                            setTimeout( function() {
                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', error );
                                }
                                var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                                document.dispatchEvent( event );
                            }, 500);
                        } );
                    */
                }
            }
        }
        else
        {
            var event = new CustomEvent( 'account:show', { detail: { restart: true } });
            document.dispatchEvent( event );
            /**
            AuthenticationAPI.authVisitor()
                .then( ( response ) => {
                    if( response.error )
                    {
                        setTimeout( function()
                        {
                            if( window.Messenger )
                            {
                                window.Messenger.showMessage( 'error', response.error );
                            }
                            var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                            document.dispatchEvent( event );
                        },500);
                    }
                    else
                    {
                        window.Application.setState( { context: 'visitor', token: response.token } );
                        setTimeout( function() {
                            if(window.ProjectsTree)
                            {
                                window.ProjectsTree.loadProjects();
                            }
                            if( window.StatusBar)
                            {
                                window.StatusBar.setState( { showVisitor: true });
                            }
                        }, 1000);
                    }
                } )
                .catch( ( error ) => {
                    setTimeout( function() {
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                        }
                        var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                        document.dispatchEvent( event );
                    }, 500 );
                } );
            */
        }
    }
}

export default class Interface extends GenericUI {

    constructor( props ) {      
        super( props );
        this.state = {
            fullEditor: false,
            fullScreen: false
        };
    }

    componentDidMount()
    {
        window.Interface = this;
    }

    render() {
        super.render();
        return(
            <div className="interface">
                <ToolBar />
                <CodeEditor />
                { ( !this.state.fullEditor ) ? <ProjectTree /> : null }
                { ( !this.state.fullEditor ) ? <AIDocPanel /> : null }
                <StatusBar />
                <MessagesPanel />
                <AuthenticationPanel context={ this.props.context } user={ this.props.user } token={ this.props.token } />
                <AccountPanel />
                <ProjectPanel />
                <LoaderAnim type="spinner" visible={false} />
                <NewItemPanel visible={false} type="folder" />
                <ConfirmDialogBox />
                <Messenger />
            </div>
        );
    }
}