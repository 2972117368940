export var enUS = 
{
    // Cube && Spinner
    "loading_projects": "Loading projects",
    "importing_project": "Importing project",
    "exporting_project": "Exporting project",
    "deleting_project": "Deleting project",
    "open_project_properties": "Open project properties",
    "update_project_properties": "Update project properties",
    "uploading_resources": "Uploading resources",

    // Authentication translations
    "authentication": "Log in",
    "email": "Email",
    "enter_your_email": "Enter your email",
    "password": "Mot de passe",
    "enter_your_password": "Enter your password",
    "remember_me": "Remember me",
    "create_an_account": "Create an account",
    "login": "Log in",

    // Topbar translations
    "website": "Visit AOZ Studio",
    
    // Treeview Projects translations
    "projects_search": "Search in projects",
    "no_results": "No results",

    // Status bar translations
    "show_messages": "Display messages",
    "position_row": "Row",
    "position_column": "Col",

    // Messages panel translations
    "transpiler_messages": "AOZ Transpiler messages",
    "transpiler_messages_errors_count": "{0} error(s), {1} warning(s).",
    "transpiler_messages_no_errors": "no errors.",
    "undefined_message": "Undefined message",
    "syntax_error": "Syntax error",
    "type_mismatch": "Data or variable type mismatch",
    "parameter_not_found": "Parameter not found",
    "in": "in",
    "error": "Error",
    "warning": "Warning",
    "at": "at line",
    "column": "column",

    // Visitor message
    "visitor_session": "Welcome, click here to enjoy all the features of AOZ Studio.",
    "click_to_see_plans": "Discover the different versions",

    // Account Preferences panel
    "account": "Account",
    "account_preferences": "Account preferences",
    "cancel": "Cancel",
    "save": "Save",
    "auto-save": "Auto-save",
    "AI Secret Key": "AI Secret Key",
    "enter_your_ai_secret_key": "Enter your AI key to benefit from AOZ Magic features.",
    "delete_account": "Delete account",
    "logout": "Logout",
    'AOZF':  'Free (for personal use)',
    'AOZ1':  'Starter (1 Month)',
    'AOZ12': 'Gold (1 year)',
    'AOZ24': 'Silver (2 years)',
    'AOZI':  'VIP Edition (no limit)',
    "current_licence": "Current license",

    // Project Panel
    "new_project": "New AOZ project",
    "project_properties": "Project properties",
    "project_infos": "Informations",
    "project_display": "Display",
    "project_publish": "Publish",
    "project_import": "Import a project",
    "project_name": "Project name",
    "project_author": "Author",
    "project_copyright": "Copyright",
    "project_version": "Version",
    "project_format": "Format",
    "project_resolution": "Resolution",
    "project_custom": "Customize",
    "project_size": "Screen size",
    "project_fullscreen": "Full screen",
    "project_keep_proportions": "Keep proportions",
    "project_smoothing": "Graphics smoothing",
    "project_orientation": "Orientation",
    "project_autodetect": "Auto",
    "project_landscape": "Landscape",
    "project_portrait": "Portrait",
    "project_code_protect": "Protect code",

    // Folder Content menu
    'refresh': "Refresh",
    'add': "Add",
    'aoz_program': "AOZ program",
    'aoz_include': "Included program",
    'html_file': "HTML page",
    'css_file': "CSS stylesheet",
    'javascript_file': "Javascript code",
    'json_file': "JSON Metadata",
    'xml_file': "XML Metadata",
    'txt_file': "Text file",
    'from_computer': "From computer",
    'from_clipboard': "From clipboard",
    'from_link': "From a link",

    // File Content menu
    "new_folder": "New folder",
    "new_file": "New file",
    "open_in_editor": "Show code",
    "collapse_all": "Close all",
    "expend_all": "Expand all",
    "delete": "Delete",
    "search": "Search",
    "rename": "Rename",
    "duplicate": "Duplicate",
    "project_export": "Export project",
    "project_import": "Import project",
    "assets_manager": "Assets manager",
	"add_resource": "Add resource",

    // Run and Publish
    "build_and_run": "Build and run your project",
    "run_your_project": "Build and run your project",
    "publish_your_project": "Publish your project",
    "preparing_publishing": "Preparing publication",
    "publishing": "Publication",
    "qrcode_info": "Congratulations the publication is complete. Scan the QR code to open your App on your phone, or click the link below.",
    
    // Dialog messages
    "yes": "Yes",
    "no": "No",
    "confirm_delete": "Delete?",
    "delete_project": "Do you really want to delete this project? This operation cannot be cancelled.",
    "delete_folder": "Do you really want to delete this folder? This operation cannot be cancelled.",
    "delete_file": "Do you really want to delete this file? This operation cannot be cancelled.",
    "confirm_logout": "Logout?",
    "logout_confirm_message": "Do you really want to log out?",

        // New Item Panel
    "type-file": "Type",
    "name": "Name",
    "enter_folder_name": "Enter the new folder's name",
    "enter_file_name": "Enter the new file's name",
    "create": "Create",
    "select_file_format": "Select file type",
	
	// AOZ Magic - Documentation
    "user_guide": "User manual",

    // Error messages
    "please_enter_your_email_and_password_to_login": "Please enter your email and password to log in",
    "invalid_email_format": "This is an invalid email format",
    "auto_connect_error": "Sorry, we have an auto-login error",
    "authentication_failed": "Sorry, the authentication failed",
    "authentication_service_not_available": "Identification service not available",
    "certificate_not_found": "Sorry, I cannot find the certificate",
    "certificate_service_not_available": "Certificate service not available",
    "ip_address_not_found": "IP address not found",
    "email_address_missed": "The email address is missing",
    "password_missed": "The password is missing",
    "login_error": "We have a Login error",
    "no_session_storage": "No session storage",
    "no_token_backed_by_the_server": "Problem... No token returned by the server",
    "invalid_token": "Problem... Invalid login token",
    "internal_error": "Internal error (I don't know any more)",
    "you_must_be_logged_in_to_create_a_project": "You must be logged in to create a project",
    "code_error": "Hmm, it seems there are errors in your code...",
    "visitor_session_over": "Your VISITOR session expired",
    "session_timeout": "Session timeout",
    "not_allowed_with_this_licence_level": "Feature not allowed with this license level",
    "session_does_not_exist": "Session does not exist",
    "there_must_be_at_least_one_project_in_your_list": "There must be at least one project in your list",
    "invalid_file_name": "File name can only contain letters, numbers, spaces and hyphens.",
    "invalid_folder_name": "Folder name can only contain letters, numbers, spaces and hyphens.",
    "file_already_exists": "The file already exists.",
    "folder_already_exists": "The folder already exists.",
    "cannot_edit_name": "You cannot edit the name of this item.",
    "properties_loading_error": "Error loading project properties",
    "building_timeout": "App building time out.",
    "file_refused": "File %1 is not a valid resource.",	
    "file_does_not_exist": "File or folder does not exist.",
    "project_does_not_exist": "Project does not exist.",
    "parent_folder_does_not_exist": "Parent folder does not exist.",
    "manifest_file_does_not_exist": "Manifest file does not exist.",
    "aozip_file_does_not_exist": "AOZIP file does not exist.",
    "folder_does_not_exist": "Folder does not exist.",
    "error_reading_files": "Error while reading files.",
    "error_reading_projects": "Error while reading projects.",
    "project_already_exists": "Project already exists.",
    "error_creating_project": "Error while creating project.",
    "error_deleting_project": "Error while deleting project.",
    "error_updating_manifest": "Error while updating manifest.",
    "error_creating_destination_path": "Error while creating destination path.",
    "error_reading_aozip_file": "Error while reading AOZIP file.",
    "project_not_found": "Project not found.",
    "error_exporting_project": "Error while exporting project.",
    "error_creating_folder": "Error while creating folder.",
    "error_deleting_folder": "Error while deleting folder.",
    "error_renaming_folder": "Error while renaming folder.",
    "error_copying_folder": "Error while copying folder.",
    "error_reading_folder": "Error while reading folder.",
    "error_reading_file": "Error while reading file.",
    "error_writing_file": "Error while writing file.",
    "error_deleting_file": "Error while deleting file.",
    "error_copying_file": "Error while copying file.",
    "internal_error_msg_invalid": "Internal error: message format invalid",
    "no_project_opened": "No project opened",
    "error_creating_user_folder": "Error while creating user's folder",
    "severe_error_no_projects": "Severe error no projects folder on server !",
    "error_could_not_save_session": "Error while saving session",

    // Toolbar tooltips
    "panelsPresets": "Reorganize panels",
    "toggle_fullscreen": "Toggle full screen mode",
    "toggle_full_editor": "Toggle full editor width",

        // Tabbar
    "close_tab": "Close tab",

    // AOZ MAGIC
    "aozm_explain_line" : "Ask AOZ Magic to explain this line",
    "aozm_fix_line" : "Ask AOZ Magic to fix this line",
}