/**
 * @description                                         The network utility functions
 */
export const NetUtils = {
    /**
     * @description                                     generate the request URL parameters with the given options
     * @param {JSON} options                            The options to generate the request URL parameters 
     * @returns {String}                                The request URL parameters
     */
    generateRequestParams: function( options )
    {
        var params = "";
        for( var key in options )
        {
            if( params.length > 0 )
            {
                params += "&";
            }
            params += key + "=" + options[key];
        }
        return params;
    },

    /**
     * @description                                     get the IP address of the current user. This function uses by the website https://api.ipify.org
     * @returns {Promise}                               The promise object of the IP address
     */
    getIPAddress: function()
    {
        return new Promise( ( resolve, reject ) => 
        {
            /**
            if( API === undefined || API.url === undefined )
            {
                reject( 'API is not defined' );
                return;
            }
            */
            var xhr = new XMLHttpRequest();
            //xhr.open( 'GET', 'https://api.ipify.org?format=json', true );
            //xhr.setRequestHeader( 'Accept', 'application/json' );
            xhr.open( 'GET', 'https://doc.aoz.studio/getIP.php', true );
            xhr.setRequestHeader( 'Accept', 'text/plain' );
            xhr.timeout = 5000;

            xhr.onreadystatechange = function() 
            {
                if( xhr.readyState !== 4 ) return;
                if( xhr.status === 200 ) 
                {
                    //var data = JSON.parse( xhr.responseText );
                    //resolve( data.ip );
                    resolve( xhr.responseText );
                } 
                else 
                {
                    reject( xhr.statusText );
                }
            };

            xhr.send();
        } );        
    }
}
