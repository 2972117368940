import React from 'react';
export default class GenericUI extends React.Component {
    constructor( props ) {
        super( props );
    }

    update()
    {
        var event = new CustomEvent( 'ui:update', { detail: { source: this } } );
        document.dispatchEvent( event );
    }

    render()
    {
        var event = new CustomEvent( 'ui:render', { detail: { source: this } } );
        document.dispatchEvent( event );
        return null;
    }
}