import React, { useState } from "react";
import {Dialog} from 'primereact/dialog';
import GenericUI from "../generic.ui";
import {Translator} from "../../translator";

//import "./styles/smartphone.css";
//import "./styles/tablet.css";
import "./styles/desktop.css";

export default class AccountPanel extends GenericUI
{
    constructor( props ) {
        super( props );
        this.state = {
            visible: props.visible,
            licence: "AOZF"
        };
    }

    componentDidMount()
    {
        window.AccountPanel = this;
    }

    logout(e)
    {
        if( window.ConfirmDialogBox )
        {
            window.ConfirmDialogBox.show( 'confirm_logout', 'logout_confirm_message', () => {
                if( window.localStorage) localStorage.clear();
                if( window.sessionStorage) sessionStorage.clear();
                window.open('index.html', '_self');
            } );
        }
    }

    render()
    {
        super.render();
/**
 *                                     <tr>
                                        <td className="td-left label">{Translator.get("AI Secret Key")}</td>
                                        <td className="td-right"><input type="text" defaultValue="" placeHolder={Translator.get("enter_your_ai_secret_key")} /></td>
                                    </tr>

 */
        if( this.state.visible )
        {
            return(
                <div className="mask">
                    <Dialog
                        modal
                        visible={this.state.visible}
                        onHide={() => this.setState( { visible: false } ) }
                        header={Translator.get("account_preferences")}
                        className="account-panel"
                    >
                        <div className="account-form">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="td-left label">{Translator.get("current_licence")}</td>
                                        <td className="td-right">{Translator.get(this.state.licence)}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-left label">{Translator.get("auto-save")}</td>
                                        <td className="td-right"><input disabled style={{cursor: 'not-allowed', filter: 'brightness(0.5)'}} type="checkbox" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>&nbsp;</div>
                        <div className="account-buttons">
                            <button className="logout" onClick={(e)=>{this.logout(e)}}>{Translator.get("logout")}</button>
                            <a href="#">{Translator.get("delete_account")}</a>

                            <button className="cancel" onClick={() => this.setState( { visible: false } )}>{Translator.get("cancel")}</button>
                            <button className="save" style={{cursor: 'not-allowed', filter: 'brightness(0.5)'}}>{Translator.get("save")}</button>
                            
                            {/*<button style={{cursor: 'not-allowed', filter: 'brightness(0.5)'}} className="unregister">{Translator.get("delete_account")}</button>*/}
                        </div>
                    </Dialog>
                </div>
            );
        }
        else
        {
            return null;
        
        }
    }
}