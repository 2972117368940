import GenericUI from "../generic.ui";
import {Translator} from "../../translator";
import {ProgressBar} from "primereact/progressbar";

import "./styles/desktop.css";
//import "./styles/smartphone.css";
//import "./styles/tablet.css";

export default class StorageBar extends GenericUI
{
    constructor( props ) {
        super( props );
        this.state = {
            data:{ total: 1000000000, free: 50000000, used: 50000000}
        }
    }

    componentDidMount() {
        window.StorageBar = this;
    }

    render()
    {
        super.render();
        return (
            <div className="storagebar">
                <ProgressBar value={this.state.data.used} displayValueTemplate={ () => { return (this.state.data.used + '/' + this.state.data.total) } }></ProgressBar>
            </div>
        );
    }
}


