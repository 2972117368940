/**
 * @description                                         AOZ Magic comm through iframe
 */

export default class AOZMagic {
    constructor(iframe) {
        this.iframe = iframe;
        this.listening = false;
        this.start();
    }

    static url() {
        //let url = "https://app.aoz.studio/etxzmik6/?forced=" + Date.now();
        let url = "https://app.aoz.studio/13amr771/?forced=" + Date.now();
        //let url = "http://localhost:6502/index.html?forced=" + Date.now();

        return url;
        
    }

    start() {
        window.addEventListener("message", this.receiveMessage);
        this.listening = true;
    }

    stop() {
        window.removeEventListener("message", this.receiveMessage);
        this.listening = false;
    }

    sendMessage(obj) {
        // FVL TODO: handle Origin
        this.iframe.contentWindow.postMessage(obj, "*");
    }

    receiveMessage(event) {
        // SELECT ALL TEXT
        // https://github.com/microsoft/monaco-editor/issues/2010
        // INSERT TEXT
        // https://stackoverflow.com/a/65797991/991782
    
        // FVL TODO: handle origin ..
        //if (event.origin !== "http://example.com") return;


        if (event.data?.from == "aoz_magic") {
            //console.log("Received message from AOZ MAGIC", event.data);

            event.data.command = event.data.command?.toLowerCase() || "";

            let editor = window.CodeEditor?.editorRef.current;
            switch (event.data.command) {
                case 'insert':
                case 'insert_and_run':
                case 'replace':
                case 'replace_and_run':
                    
                    if (!editor) break;
                    if (event.data.command.startsWith("replace")) editor.setSelection(editor.getModel().getFullModelRange());
                    let code = event.data.code;

                    // monaco keeps indents after pressing Enter 
                    // and setting options.autoIndent to 'none' does not work yet
                    // see https://github.com/microsoft/monaco-editor/issues/3293
                    let lines = code.split('\n');
                    let indent = 0;
                    for (let line of lines) {
                        let l = line.length;
                        let spc=0;
                        // trim spaces ONLY (string.trim trims WHITESPACES ! including TABS !)
                        while (spc<l && line[spc]==' ') ++spc;
                        line = line.substring(spc, l);

                        if (line) {
                            let tabs=0;
                            l = line.length;
                            
                            // remove leading tabs
                            while (tabs<l && line[tabs]=='\t') ++tabs;
                            line = line.substring(tabs, l);
                                                    
                            if ( tabs < indent ) {
                                let diff = indent-tabs;
                                
                                while (diff) {
                                    editor.trigger(monaco.KeyCode.Backspace, 'deleteLeft');
                                    diff--;
                                }
                                indent = tabs;
                            } else if ( tabs > indent ) {
                                let diff = tabs-indent;
                                line = '\t'.repeat(diff) + line;    // more tabs
                                indent = tabs;
                            }
                        }

                        // type the line
                        editor.trigger('keyboard', 'type', {text: line + '\n'});
                    }
                    //code = lines.join('\n');

                    // type the code
                    //editor.trigger('keyboard', 'type', {text: code});
                    if (event.data.command.endsWith("_and_run")) {
                        // run the code
                        window.ToolBar?.build( 'run' );
                    }
                    
                    break;
                case 'resize_panel':
                    if (typeof event.data.size !== 'undefined') {
                        let pct = !!event.data.percentage;
                        switch (event.data.target?.toLowerCase()) {
                            case 'ai':
                            case 'doc':
                            case 'right':
                                window.AIDocPanel?.resize(event.data.size, pct);
                                break;
                            case 'tree':
                            case 'left':
                                window.ProjectsTree?.resize(event.data.size, pct);
                                break;
                            default:
                                console.log("Unknown target panel to resize:", event.data)
                        }
                    } else {
                        console.log ("No size specified:", event.data);
                    }
                    break;
                case 'hello_there':
                    console.log("AOZ MAGIC says \"hello\" :-)");
                    break;
                case 'fix_line':
                    if (!editor) break;

                    const text = editor.getModel().getLineContent(event.data.line);

                    editor.setSelection({
                                        startLineNumber: event.data.line, 
                                        startColumn: 1, 
                                        endLineNumber: event.data.line,
                                        endColumn: text.length + 1,
                                    });

                    editor.trigger(monaco.KeyCode.Backspace, 'deleteLeft');
                    editor.trigger('keyboard', 'type', {text: event.data.code.trim()});

                    window.CodeEditor.clearAOZMargins();

                    window.MessagesPanel.removeMessagesForLine(event.data.line);

                    break;
                default:
                    console.log("Unknown command received from AOZ MAGIC:", event.data);
            }
        } else {
            // ignore other msgs
        }
    }
    
   
}