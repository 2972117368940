document.addEventListener( 'click', ( event ) => {
    //event.preventDefault();
    // Menus contextuels
    //if( document.getElementById( 'projectsMenu' )) document.getElementById( 'projectsMenu' ).style.display = 'none';
    if( document.getElementById( 'projectsCtxFiles' )) document.getElementById( 'projectsCtxFiles' ).style.display = 'none';
    if( document.getElementById( 'projectsCtxMenu' )) document.getElementById( 'projectsCtxMenu' ).style.display = 'none';
    if( document.getElementById( 'projectsCtxResources' )) document.getElementById( 'projectsCtxResources' ).style.display = 'none';    
} );

document.addEventListener( 'contextmenu', ( event ) => {
    event.preventDefault();
    return false;
} );

document.addEventListener( 'ui:render', ( event ) => {
    //console.log( event );
} );

document.addEventListener( 'ui:update', ( event ) => {
    if( window.Application )
    {
        window.Application.update();
    }
} )