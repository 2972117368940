export var frFR = 
{
    // Cube && Spinner
    "loading_projects": "Chargement des projets",
    "importing_project": "Importation du projet",
    "exporting_project": "Exportation du projet",
    "deleting_project": "Suppression du projet",
    "open_project_properties": "Ouverture des propriétés du projet",
    "update_project_properties": "Mise à jour des propriétés du projet",
    "uploading_resources": "Transfert des ressources",

    // Authentication translations
    "authentication": "Connectez-vous",
    "email": "Email",
    "enter_your_email": "Saisissez votre email",
    "password": "Mot de passe",
    "enter_your_password": "Saisissez votre mot de passe",
    "remember_me": "Se souvenir de moi",
    "create_an_account": "Créer un compte",
    "login": "Connexion",

    // Topbar translations
    "website": "Visiter AOZ Studio",
    
    // Treeview Projects translations
    "projects_search": "Rechercher dans les projets",
    "no_results": "Aucun résultat",

    // Status bar translations
    "show_messages": "Afficher les messages",
    "position_row": "Ln",
    "position_column": "Col",

    // Messages panel translations
    "transpiler_messages": "Messages du transpileur: ",
    "transpiler_messages_errors_count": "{0} erreur(s), {1} warning(s).",
    "transpiler_messages_no_errors": "pas d'erreurs.",
    "undefined_message": "Message indéfini",
    "syntax_error": "Erreur de syntaxe",
    "type_mismatch": "Discordance de type de données ou de variable",
    "parameter_not_found": "Paramètre non-trouvé",
    "in": "dans",
    "error": "Erreur",
    "warning": "Avertissement",
    "at": "à la ligne",
    "column": "colonne",

    // Visitor message
    "visitor_session": "Bienvenue Visiteur, cliquez ici pour bénéficier de toutes les fonctionnalités.",
    "click_to_see_plans": "Cliquez pour voir les offres",

    // Account Preferences panel
    "account": "Compte",
    "account_preferences": "Préférences du compte",
    "cancel": "Annuler",
    "save": "Sauvegarder",
    "auto-save": "Sauvegarde automatique",
    "AI Secret Key": "Clé AI",
    "enter_your_ai_secret_key": "Entrez votre clé AI pour bénéficier des fonctionnalités de AOZ Magic.",
    "logout": "Déconnexion",
    "delete_account": "Supprimer le compte",
    'AOZF':  'Gratuite (pour utilisation personnelle)',
    'AOZ1':  'Starter (1 Mois)',
    'AOZ12': 'Gold (1 an)',
    'AOZ24': 'Silver (2 ans)',
    'AOZI':  'VIP Edition (sans limitation)',
    "current_licence": "Licence actuelle",

    // Project Panel
    "new_project": "Nouveau projet AOZ",
    "project_properties": "Propriétés du projet",
    "project_infos": "Informations",
    "project_display": "Affichage",
    "project_publish": "Publication",
    "project_import": "Importer un projet",
    "project_name": "Nom du projet",
    "project_author": "Auteur",
    "project_copyright": "Copyright",
    "project_version": "Version",
    "project_format": "Format",
    "project_resolution": "Résolution",
    "project_custom": "Personnaliser",
    "project_size": "Taille",
    "project_fullscreen": "Plein écran",
    "project_keep_proportions": "Conserver les proportions",
    "project_smoothing": "Lissage du graphisme",
    "project_orientation": "Orientation",
    "project_autodetect": "Auto",
    "project_landscape": "Paysage",
    "project_portrait": "Portrait",
    "project_code_protect": "Protéger le code",

    // Folder Content menu
    'refresh': "Actualiser",
    'add': "Ajouter",
    'aoz_program': "Programme AOZ",
    'aoz_include': "Inclusion AOZ",
    'html_file': "Page HTML",
    'css_file': "Feuille de styles CSS",
    'javascript_file': "Code Javascript",
    'json_file': "Metadonnées JSON",
    'xml_file': "Metadonnées XML",
    'txt_file': "Fichier texte",
    'from_computer': "Depuis l'ordinateur",
    'from_clipboard': "Depuis le presse-papiers",
    'from_link': "Depuis un lien",

    // Context menus
    "new_folder": "Nouveau dossier",
    "new_file": "Nouveau fichier",
    "open_in_editor": "Voir le code",
    "collapse_all": "Tout réduire",
    "expend_all": "Tout déplier",
    "delete": "Supprimer",
    "search": "Rechercher",
    "rename": "Renommer",
    "duplicate": "Dupliquer",
    "project_export": "Exporter le projet",
    "project_import": "Importer un projet",
    "assets_manager": "Gérer les ressources",
    "add_resource": "Ajoût de ressource",

    // Run and Publish
    "build_and_run": "Fabrique et exécute",
    "run_your_project": "Fabriquer et exécuter votre projet",
    "publish_your_project": "Publier votre projet",
    "preparing_publishing": "Préparation de la publication",
    "publishing": "Publication",
    "qrcode_info": "Publication terminée. Scannez le QR code pour ouvrir votre projet sur votre téléphone ou cliquez sur le lien ci-dessous.",

    // Dialog messages
    "yes": "Oui",
    "no": "Non",
    "confirm_delete": "Supprimer ?",
    "delete_project": "Voulez-vous vraiment supprimer ce projet ? Cette opération ne pourra pas être annulée.",
    "delete_folder": "Voulez-vous vraiment supprimer ce dossier et tous ses éléments ? Cette opération ne pourra pas être annulée.",
    "delete_file": "Voulez-vous vraiment supprimer ce fichier ? Cette opération ne pourra pas être annulée.",
    "confirm_logout": "Déconnexion",
    "logout_confirm_message": "Voulez-vous vraiment vous déconnecter ?",

    // New Item Panel
    "type-file": "Format",
    "name": "Nom",
    "enter_folder_name": "Saisissez le nom du nouveau dossier",
    "enter_file_name": "Saisissez le nom du nouveau fichier",
    "create": "Créer",
    "select_file_format": "Sélectionnez le format du fichier",

    // AOZ Magic - Documentation
    "user_guide": "Manuel de l'utilisateur",

    // Error messages
    "please_enter_your_email_and_password_to_login": "Veuillez saisir votre email et votre mot de passe pour vous connecter",
    "invalid_email_format": "Format d'email invalide",
    "auto_connect_error": "Erreur de connexion automatique",
    "authentication_failed": "Authentification échouée",
    "authentication_service_not_available": "Service d'identification non disponible",
    "certificate_not_found": "Certificat introuvable",
    "certificate_service_not_available": "Service de certificat non disponible",
    "ip_address_not_found": "Adresse IP introuvable",
    "email_address_missed": "Adresse email manquante",
    "password_missed": "Mot de passe manquant",
    "login_error": "Erreur de connexion",
    "no_session_storage": "Stockage de session non disponible",
    "no_token_backed_by_the_server": "Aucun jeton retourné par le serveur",
    "invalid_token": "Token de connexion invalide",
    "internal_error": "Erreur interne",
    "you_must_be_logged_in_to_create_a_project": "Vous devez être connecté pour créer un projet",
    "code_error": "Il y a des erreurs dans votre code.",
    "visitor_session_over": "Votre session VISITEUR est terminée.",
    "session_timeout": "Délai de session dépassé.",
    "not_allowed_with_this_licence_level": "Fonctionnalité indisponible avec ce niveau de licence.",
    "session_does_not_exist": "La session n'existe pas.",
    "there_must_be_at_least_one_project_in_your_list": "Il doit y avoir au moins un projet dans votre liste.",
    "invalid_file_name": "Le nom de fichier ne peut contenir que des lettres, des chiffres, des espaces et des tirets.",
    "invalid_folder_name": "Le nom de dossier ne peut contenir que des lettres, des chiffres, des espaces et des tirets.",
    "file_already_exists": "Le fichier existe déjà.",
    "folder_already_exists": "Le dossier existe déjà.",
    "cannot_edit_name": "Vous ne pouvez pas modifier le nom de cet élément.",
    "properties_loading_error": "Erreur lors du chargement des propriétés du projet.",
    "building_timeout": "Délai de construction dépassé.",
    "file_refused": "Fichier %1 n'est pas reconnu comme resource valide.",
    "file_does_not_exist": "Le fichier ou dossier n'existe pas.",
    "project_does_not_exist": "Le projet n'existe pas.",
    "parent_folder_does_not_exist": "Le répertoire parent n'existe pas.",
    "manifest_file_does_not_exist": "Le fichier Manifest n'existe pas.",
    "aozip_file_does_not_exist": "Le fichier AOZIP n'existe pas.",
    "folder_does_not_exist": "Le répertoire n'existe pas.",
    "error_reading_files": "Erreur lors de la lecture de fichiers.",
    "error_reading_projects": "Erreur lors de la lecture de projets.",
    "project_already_exists": "Le projet existe déjà.",
    "error_creating_project": "Erreur lors de la création du projet.",
    "error_deleting_project": "Erreur lors de la suppression du projet.",
    "error_updating_manifest": "Erreur lors de la mise à jour du fichier manifest.",
    "error_creating_destination_path": "Erreur lors de la création du chemin de destination.",
    "error_reading_aozip_file": "Erreur lors de la lecture du fichier AOZIP.",
    "project_not_found": "Le projet n'a pas été trouvé.",
    "error_exporting_project": "Erreur lors de l'exportation du projet",
    "error_creating_folder": "Erreur lors de la création du répertoire",
    "error_deleting_folder": "Erreur lors de la suppression du répertoire.",
    "error_renaming_folder": "Erreur lors du renommage du répertoire.",
    "error_copying_folder": "Erreur lors de la copie du répertoire.",
    "error_reading_folder": "Erreur lors de la lecture du répertoire.",
    "error_reading_file": "Erreur lors de la lecture du fichier.",
    "error_writing_file": "Erreur lors de l'écriture du fichier.",
    "error_deleting_file": "Erreur lors de la suppression du fichier.",
    "error_copying_file": "Erreur lors de la copie du fichier.",
    "internal_error_msg_invalid": "Erreur interne: format de message invalide",
    "no_project_opened": "Pas de projet ouvert",
    "error_creating_user_folder": "Erreur lors de la création du répertoire utilisateur",
    "severe_error_no_projects": "Erreur critique: pas de répertoire de projets sur le serveur !",
    "error_could_not_save_session": "Erreur lors de la sauvegarde de la session",

    // Toolbar tooltips
    "panelsPresets": "Réorganiser les panneaux",
    "toggle_fullscreen": "Passer en mode plein écran",
    "toggle_full_editor": "Elargir l'éditeur au maximum",

    // Tabbar
    "close_tab": "Fermer l'onglet",

    // AOZ MAGIC
    "aozm_explain_line" : "Demander à AOZ Magic d'expliquer cette ligne de code",
    "aozm_fix_line" : "Demander à AOZ Magic de corriger cette ligne de code",
}