import { Lang } from './lang';

/**
 * @description                                         Translator
 */
export var Translator =
{
    currentLang: (navigator.language) ? navigator.language : 'en-US',

    /**
     * @description                                     Get a translation from a key
     * @param {String} key                              The key to get the translation
     * @param {Array} params                            The parameters to replace in the translation. May be undefined
     * @returns 
     */
    get: function( key, params )
    {
        if ( !Lang[this.currentLang] ) {
            if( this.currentLang.startsWith( 'fr' ) ) this.currentLang = 'fr-FR';
            else this.currentLang = 'en-US'
        }
        if( Lang[ this.currentLang ][ key ] )
        {
            var term =  Lang[ this.currentLang ][ key ];
            if( params )
            {
                for( var i = 0; i < params.length; i++ )
                {
                    term = term.replace( '{' + i + '}', params[i] );
                }
            }
            return term;
        } else {
            return '* ' + key;
        }
    },

    /**
     * @description                                     Change the language
     * @param {String} lang                             The language to change
     */
    changeLanguage: function( lang )
    {
        if( Lang[ lang ] )
        {
            this.currentLang = lang;
            var event = new CustomEvent( 'translator:change', { detail: { source: this, lang: lang } } );
            eventBus.dispatchEvent( event );
        }
    }
}