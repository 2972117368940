import {NetUtils} from '../../classes/utils/net.utils.js';
import {baseUrl} from '../config.js';

export var AuthenticationAPI = {
    /**
     * @description                                         Check if the user is in the cache
     * @returns {Boolean|JSON}                              The user object if it is in the cache, otherwise false
     */
    getUserInCache: function()
    {
        if( localStorage )
        {
            try
            {
                var email = localStorage.getItem( 'email' );
                var password = localStorage.getItem( 'password' );
                var token = localStorage.getItem( 'token' );
                var user = { email: email, password: password, token: token };
                if( user && user.email && user.password && user.token )
                {
                    return user;
                }
                return false;
            }
            catch( e )
            {
                return false;
            }
        }
        return false;
    },
    
    /**
     * @description                                         Check if the user has a token
     * @returns {Boolean|String}                            Returns Token if a token is stored, otherwise false
     */
    getLocaleToken: function()
    {
        var token = false;
        if( localStorage)
        {
            token = localStorage.getItem( 'token' );
        }
    
        if( token === undefined || token === null || token === '' )
        {
            return false;
        }
        return token;
    },
    
    /**
     * @description                                         Connect the user automatically to the AOZ Server
     * @param {JSON} user                                   User authentication datas stored in local storage 
     */
    autoConnect: function( user )
    {
        var p = new Promise( ( resolve, reject ) => {
            AuthenticationAPI._login( user.email, user.password )
                .then( ( response ) => 
                {
                    var result = AuthenticationAPI._managerResponse( response, user.email, user.password, true );
                    if( result.err )
                    {
                        reject( 'auto_connect_error' );
                    }
                    else
                    {
                        resolve( true );
                    }
                } )
                .catch( ( error ) => 
                {
                    reject( 'auto_connect_error' );
                } );
        } );
        return p;
    },
    
    /**
     * @description                                         Connect user to the AOZ Server
     * @returns {void}
     */
    authLogin: function()
    {
        var p = new Promise( ( resolve, reject ) => {
            if( localStorage) localStorage.clear();
            if( document.getElementById( 'fld_email' ) && document.getElementById( 'fld_password' ) ) 
            {
                var email = document.getElementById( 'fld_email' ).value;
                var password = document.getElementById( 'fld_password' ).value;
                var remember = document.getElementById( 'remember' ).checked;
                if( email.trim() === '' || password.trim() === '')
                {
                    AuthenticationAPI._resetUser();
                    reject( 'please_enter_your_email_and_password_to_login' );
                }
            
                AuthenticationAPI._login( email, password )
                    .then( ( response ) => 
                    {
                        var result = AuthenticationAPI._managerResponse( response, email, password, remember );
                        if( result.err )
                        {
                            reject( 'authentication_failed' );
                        }
                        else
                        {
                            resolve( true );
                        }
                    } )
                    .catch( ( error ) => {
                        AuthenticationAPI._resetUser();
                        reject( error );
                    } );
            }
            else
            {
                AuthenticationAPI._resetUser();
                reject( 'please_enter_your_email_and_password_to_login' );
            }
        } );
        return p;
    },
    
    /**
     * @description                                         Connect the user to the AOZ Server with the given token
     * @param {String} token                                The token of the user 
     */
    authToken: function( token )
    {
        var p = new Promise( ( resolve, reject ) => {
            AuthenticationAPI._loginFromToken( token )
                .then( ( response ) => 
                {
                    var result = AuthenticationAPI._managerResponse( response, '', '', false );
                    if( result.err )
                    {
                        reject( 'invalid_token' );
                    }
                    else
                    {
                        resolve( result );
                    }
                } )
                .catch( ( error ) => 
                {
                    AuthenticationAPI._resetUser();
                    reject( 'invalid_token' );
                } );
        } );
        return p;
    },
    
    authRegister: function()
    {
        window.open( 'https://shop.aoz.studio/checkout', '_blank' );
        return true;
    },
    
    /**
     * @description                                         Connect the user in "Visitor" mode
     */
    authVisitor: function()
    {

        var self = this;
        var p = new Promise( ( resolve, reject ) => {
            if( localStorage) localStorage.clear();
            AuthenticationAPI._createVisitor()
                .then( ( response ) => 
                {
                    var result = AuthenticationAPI._managerResponse( response, '', '', false );
                    if( result.err )
                    {
                        AuthenticationAPI._resetUser();
                        reject( result.error );
                    }
                    else
                    {
                        resolve( result );
                    }
                } )
                .catch( ( error ) => 
                {
                    AuthenticationAPI._resetUser();
                    reject( error );
                } );    
        } );
        return p;
    },
    
    /**
     * @description                                         login to the system with the given email and password
     * @param {String} email                                The email of the user
     * @param {String} password                             The password of the user
     * @returns {Promise}                                   The promise object of the login
     */
    _login: function( email, password ) {
        return new Promise( ( resolve, reject ) => 
        {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {
                var xhr = new XMLHttpRequest();
                xhr.open( 'GET', baseUrl + '/auth/login', true );
                xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'email', email );
                xhr.setRequestHeader( 'password', password );
                xhr.timeout = 5000;
    
                xhr.onload = function() 
                {
                    if( xhr.status === 200 ) 
                    {
                        // resolve the promise with the response (token)
                        resolve( JSON.parse( xhr.responseText ) );
                    } 
                    else 
                    {
                        // reject the promise with the status text
                        reject( xhr.statusText );
                    }
                };
                // send the request with the email and password
                xhr.send();
            } )
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        } );
    },
    
    /**
     * @description                                         login to the system with the given token
     * @param {String} token                                The token of the user 
     * @returns {Promise}
     */
    _loginFromToken: function( token )
    {
        return new Promise( ( resolve, reject ) => 
        {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {
                var xhr = new XMLHttpRequest();
                xhr.open( 'GET', baseUrl + '/auth/token', true );
                xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.timeout = 5000;
    
                xhr.onload = function() 
                {
                    if( xhr.status === 200 ) 
                    {
                        // resolve the promise with the response (token)
                        resolve( JSON.parse( xhr.responseText ) );
                    } 
                    else 
                    {
                        // reject the promise with the status text
                        reject( xhr.statusText );
                    }
                };
                // send the request with the email and password
                xhr.send();
            } )
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        } );
    
    },
    
    /**
     * @description                                         Asks to the server to create a session in "Visitor" mode
     * @returns {Promise}                                   The promise object of the creation
     */
    _createVisitor: function()
    {
        return new Promise( ( resolve, reject ) => 
        {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {
                var xhr = new XMLHttpRequest();
                xhr.open( 'GET', baseUrl + '/auth/visitor', true );
                xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.timeout = 5000;
    
                xhr.onload = function() 
                {
                    if( xhr.status === 200 ) 
                    {
                        // resolve the promise with the response (token)
                        resolve( JSON.parse( xhr.responseText ) );
                    } 
                    else 
                    {
                        // reject the promise with the status text
                        reject( xhr.statusText );
                    }
                };
                // send the request with the email and password
                xhr.send();
            } )
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        } );
    },
    
    /**
     * @description                                         Manage the server response after a login request
     * @param {JSON} response                               The response from the server
     * @param {String} email                                Email user to login
     * @param {String} password                             Password user to login 
     * @param {Boolean} remember                            Remember user to login
     * @returns 
     */
    _managerResponse: function( response, email, password, remember )
    {
        if( response )
        {
            if( response.err )
            {
                AuthenticationAPI._resetUser();
                return { error: response.err };
            }
    
            if( response.data.token )
            {
                if( response.data.request == '/auth/login' )
                {
                    if( remember && localStorage )
                    {
                        localStorage.setItem( 'email', email );
                        localStorage.setItem( 'password', password );
                    }
                }
    
                var isTry = true;
                if( response.data.request == '/auth/token' )
                {
                    isTry = response.data.isTry;
                }
                
                if( localStorage )
                {
                    localStorage.setItem( 'token', response.data.token );
                }
    
                if( sessionStorage )
                {
                    sessionStorage.setItem( 'token', response.data.token );
                    return { error: false, token: response.data.token, isTry: isTry };
                    /**
                    // "Visitor" context
                    const interfaceContextChangeEvent = new CustomEvent( "context:change", { detail: { context: "visitor" } } );
                    if( email.trim() !== '' && licence !== 'VISITOR' )
                    {
                        // Others contexts
                        interfaceContextChangeEvent = new CustomEvent( "context:change", { detail: { context: "home" } } );
                    }
                    eventBus.dispatchEvent( interfaceContextChangeEvent );
                    */
                }
                else
                {
                    AuthenticationAPI._resetUser();
                    return { error: 'no_session_storage' };
                }
            }
            else
            {
                AuthenticationAPI._resetUser();
                return { error: 'no_token_backed_by_the_server' };
            }
        }
    },
    
    /**
     * @description                                         Reset the user datas in the cache
     * @returns {void}
      */
    _resetUser: function()
    {
        if( localStorage ) localStorage.clear();
        if( sessionStorage ) sessionStorage.clear();
        var event = new CustomEvent( 'auth:reset', { detail: {} } );
        document.dispatchEvent( event );
    }
}