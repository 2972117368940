import { createRoot } from 'react-dom/client';
import Shortcuts from "./app/config/shortcuts";
import TreeViewEvents from "./app/events/treeview.js";
import AuthenticationEvents from "./app/events/authentication.js";
import UIEvents from "./app/events/ui.js";
import ProjectsEvents from "./app/events/projects.js";
import Application from './app/application.js';

document.title = "AOZ Studio";


// Clear the existing HTML content
//eventBus.title = "AOZ Studio";
document.body.innerHTML = '<div id="app"></div>';

// Render your React component instead
const root = createRoot(document.getElementById('app'));
root.render( <Application /> );
