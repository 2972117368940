import GenericUI from "../generic.ui";
import {Translator} from "../../translator";
import TreeView from '../treeview';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {collapseAll, expendAll } from '../treeview/func';
//import { showMessage } from '../messenger/func';
import { ProjectAPI } from '../../../api/project';
import StorageBar from '../storagebar';
import { Menu } from 'primereact/menu';

import {FileUtils} from "../../utils/file.utils";
import {baseUrl} from '../../../api/config';

// CSS
import '../treeview/styles/treeview.css';
import "./styles/desktop.css";
//import "./styles/smartphone.css";
//import "./styles/tablet.css";

function hideAllMenus()
{
    document.getElementById( 'projectsCtxFiles' ).style.display = 'none';
    document.getElementById( 'projectsCtxMenu' ).style.display = 'none';
    document.getElementById( 'projectsCtxResources' ).style.display = 'none';
}

export default class ProjectsTree extends GenericUI
{
    constructor( props ) {
        super( props );
        this.state = {
            projects: undefined,
            tree: undefined,
            selectedItem: null,
            dialog: null,
            showMenu: true,
            menuModel: [
                {
                    id: 1,
                    label: Translator.get('new_project'),
                    icon: 'fa-solid fa-cube',
                    command: () => { this.newProject() }
                },
                {
                    separator: true
                },
                {
                    id: 2,
                    label: Translator.get('project_import'),
                    icon: 'fa-solid fa-file-import',
                    command: () => { this.importProject() }
                }
            ],

            contextMenuModelProject: [
                {
                    id: 1,
                    label: Translator.get('project_properties'),
                    icon: 'fa-solid fa-gears',
                    command: () => { this.editProject() }
                },
                {
                    id: 2,
                    label: Translator.get('project_export'),
                    icon: 'fa-solid fa-download',
                    command: () => { this.exportProject() }
                },
                {
                    id: 3,
                    label: Translator.get('assets_manager'),
                    icon: 'fa-solid fa-star',
                    command: () => { this.showResourcesManager() }
                },
                {
                    separator: true
                },
                {
                    id: 4,
                    label: Translator.get('new_folder'),
                    icon: 'fa-solid fa-folder-plus',
                    command: () => { this.newFolder() }
                },
                {
                    id: 5,
                    label: Translator.get('new_file'),
                    icon: 'fa-solid fa-file-circle-plus',
                    command: () => { this.newFile() }
                },                    
                {
                    id: 6,
                    label: Translator.get('add_resource'),
                    icon: 'fa-solid fa-photo-film',
                    command: () => { this.addResource() }
                },                                                       
                {
                    separator: true
                },
                {
                    id: 7,
                    label: Translator.get('delete'),
                    icon: 'fa-solid fa-trash',
                    command: () => { this.deleteProject() }
                }             
            ],

            contextMenuModelFiles: [
                {
                    id: 1,
                    label: Translator.get('new_folder'),
                    icon: 'fa-solid fa-folder-plus',
                    command: () => { this.newFolder() }
                },
                {
                    id: 2,
                    label: Translator.get('new_file'),
                    icon: 'fa-solid fa-file-circle-plus',
                    command: () => { this.newFile() }
                },
                {
                    id: 3,
                    label: Translator.get('add_resource'),
                    icon: 'fa-solid fa-photo-film',
                    command: () => { this.addResource() }
                },
                {
                    separator: true
                },
                {
                    id: 4,
                    label: Translator.get('rename'),
                    icon: 'fa-solid fa-pen',
                    command: () => { this.renameFile() }
                },
                {
                    id: 5,
                    label: Translator.get('delete'),
                    icon: 'fa-solid fa-trash',
                    command: () => { this.deleteFile() }
                },                                      
            ],

            contextMenuModelResources: [
                {
                    id: 1,
                    label: Translator.get('assets_manager'),
                    icon: 'fa-solid fa-star',
                    command: () => { this.showResourcesManager() }
                },
                {
                    id: 2,
                    label: Translator.get('add_resource'),
                    icon: 'fa-solid fa-photo-film',
                    command: () => { this.addResource() }
                }                                                     
            ]            
        }
        this.ismdwn = 0;
        var self = this;
        this.tools = [
            {
                key: 'tool-new-project',
                label: Translator.get('add'),
                icon: 'fa-solid fa-circle-plus',
                action: () => { this.showAddMenu(); }
            }
        ];

        this.minWidth = 0; //75;
    }

    showAddMenu()
    {
        hideAllMenus();
        var elm = document.getElementById( 'projectsMenu' );
        if( elm ) {
            elm.style.display = 'block';
            setTimeout( () => {
                elm.style.display = 'none';
            }, 3000 );
        }
    }

    showContextMenu( e, item, props )
    {
        hideAllMenus();

        var elm = document.getElementById( 'projectsCtxFiles' );
        if( item && item.icon == 'icon_project')
        {
            elm = document.getElementById( 'projectsCtxMenu' );
        }    

        if( item && item.icon == 'icon_folder_resources' )
        {
            elm = document.getElementById( 'projectsCtxResources' );
        }

        if( elm ) {
            elm.style.display = 'block';
            elm.style.left = e.clientX + 'px';
            elm.style.top = (e.clientY-32) + 'px';
            
            /**
            setTimeout( () => {
                elm.style.display = 'none';
            }, 5000 );
            */
            
        }

        return false;
    }

    newProject()
    {
        hideAllMenus();
        var manifest = ProjectAPI.getDefaultManifest();
        if( window.ProjectPanel )
        {
            window.ProjectPanel.setManifest( manifest );
        }
    }

    importProject()
    {
        hideAllMenus();
        var elm = document.getElementById( 'fileInput' );
        if( elm )
        {
            elm.setAttribute('data-type', 'project');
            if( elm.hasAttribute('multiple') )
            {
                elm.removeAttribute('multiple');
            }
            elm.setAttribute( 'accept', '.aozip' );
            elm.value = '';
            elm.click();
        }
    }
    
    addResource()
    {
        hideAllMenus();
        var elm = document.getElementById( 'fileInput' );
        if( elm )
        {
            elm.setAttribute('data-type', 'resource');
            elm.setAttribute( 'multiple', '' );
            elm.setAttribute( 'accept', '*.*' );
            elm.value = '';
            elm.click();
        }
    }

    checkSelectedFileForImport( e )
    {
        var elm = document.getElementById( 'fileInput' );
        if( elm )
        {
            if( elm.files && elm.files.length > 0)
            {
                if( elm.getAttribute('data-type') === 'project' )
                {
                    var file = elm.files[0];
                    if( file.size > 0 )
                    {
                        if( window.LoaderAnim )
                        {
                            window.LoaderAnim.show( 'spinner', Translator.get('importing_project') );
                        }
                        
                        ProjectAPI.importProject( file )
                            .then( ( response ) => {
                                if( window.LoaderAnim )
                                {
                                    window.LoaderAnim.hide();
                                }
            
                                if( window.ProjectsTree )
                                {
                                    if( window.ProjectsTree )
                                        {
                                            window.ProjectsTree.loadProjects( true );
                                            setTimeout( () => {
                                                window.ProjectsTree.checkFile(null,response);
                                            },1000);
                                        }
                                }
                            })
                            .catch( (error) => {
                                if( window.LoaderAnim )
                                {
                                    window.LoaderAnim.hide();
                                }

                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', error );
                                }
                            });
                    }
                }
                else
                {
                    hideAllMenus();
                    var nodes = document.querySelectorAll( '.treeview-content .selected' );
                    var node = undefined;
                    var path = undefined;
                    if( nodes && nodes.length > 0 )
                    {
                        node = nodes[0].parentNode;
                    }
            
                    if( node )
                    {
                        path = node.getAttribute( 'id' );
                    }                    

                    if( node.querySelector( '.icon_folder' ) == undefined && node.querySelector( '.icon_project' ) == undefined && node.querySelector( '.icon_folder_assets' ) == undefined )
                    {
                        path = path.split("/")[1];
                    }

                    var files = elm.files;
                    if( files.length > 0 )
                    {
                        if( window.LoaderAnim )
                        {
                            window.LoaderAnim.show( 'spinner', Translator.get('uploading_resources') );
                        }
                        
                        ProjectAPI.importResources( path, files )
                            .then( (data) => {
                                if( window.LoaderAnim )
                                {
                                    window.LoaderAnim.hide();
                                }

                                if( window.ProjectsTree )
                                {
                                    window.ProjectsTree.loadProjects( true );
                                }
                            })
                            .catch( (error) => {
                                if( window.LoaderAnim )
                                {
                                    window.LoaderAnim.hide();
                                }

                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', error.msgError, [ error.file ] );
                                }
                            });
                    }
                }
            }
        }
    }

    editProject()
    {
        hideAllMenus();
        var nodes = document.querySelectorAll( '.treeview-content .selected' );
        var node = undefined;
        var path = undefined;
        if( nodes && nodes.length > 0 )
        {
            node = nodes[0].parentNode;
        }

        if( node )
        {
            path = node.getAttribute( 'id' );
        }

        if( window.LoaderAnim )
        {
            window.LoaderAnim.show( 'spinner', Translator.get('open_project_properties') );
        }
        ProjectAPI.loadProperties( path )
        .then(response => {
            if( window.LoaderAnim )
            {
                window.LoaderAnim.hide();
            }

            if( window.ProjectPanel )
            {
                window.ProjectPanel.setManifest( response, true );
            }
        })
        .catch(error => {
            if( window.Messenger )
            {
                if( window.LoaderAnim )
                {
                    window.LoaderAnim.hide();
                }
                window.Messenger.showMessage( 'error', error );
            }
        });        

    }

    exportProject()
    {
        hideAllMenus();
        var nodes = document.querySelectorAll( '.treeview-content .selected' );
        var node = undefined;
        var path = undefined;
        if( nodes && nodes.length > 0 )
        {
            node = nodes[0].parentNode;
        }

        if( node )
        {
            path = node.getAttribute( 'id' );
        }

        if( window.LoaderAnim )
        {
            window.LoaderAnim.show( 'spinner', Translator.get('exporting_project') );
        }

        ProjectAPI.exportProject( path )
            .then(response => {
                if( window.LoaderAnim )
                {
                    window.LoaderAnim.hide();
                }
                window.open( baseUrl + '/export?t=' + response, '_blank' );
            })
            .catch(error => {
                if( window.Messenger )
                {
                    if( window.LoaderAnim )
                    {
                        window.LoaderAnim.hide();
                    }
                    window.Messenger.showMessage( 'error', error );
                }
            });

    }

    deleteProject()
    {
        hideAllMenus();
        if( window.ConfirmDialogBox )
        {
            window.ConfirmDialogBox.show( 'confirm_delete', 'delete_project', () => {
                var nodes = document.querySelectorAll( '.treeview-content .selected' );
                var node = undefined;
                var path = undefined;
                if( nodes && nodes.length > 0 )
                {
                    node = nodes[0].parentNode;
                }
        
                if( node )
                {
                    path = node.getAttribute( 'id' );
                }

                if( window.LoaderAnim )
                {
                    window.LoaderAnim.show( 'spinner', Translator.get('deleting_project') );
                }
                ProjectAPI.deleteProject( path )
                    .then( ( response ) => {
                        if( window.LoaderAnim )
                        {
                            window.LoaderAnim.hide();
                        }
                        window.open('index.html', '_self');
                    })
                    .catch( (error) => {
                        if( window.LoaderAnim )
                        {
                            window.LoaderAnim.hide();
                        }
    
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                        }
                    });
            }, () => {
                console.log('cancel delete project');
            } );
        }
    }

    showResourcesManager()
    {
        hideAllMenus();
    }

    newFolder()
    {
        hideAllMenus();
        if( window.NewItemPanel )
        {
            window.NewItemPanel.setState( { visible: true, path: undefined, type: 'folder', name: '' } );
        }
    }

    newFile()
    {
        hideAllMenus();
        if( window.NewItemPanel )
        {
            window.NewItemPanel.setState( { visible: true, path: undefined, type: 'file', name: '' } );
        }   
    }

    renameFile()
    {
        hideAllMenus();
        var nodes = document.querySelectorAll( '.treeview-content .selected' );
        var node = undefined;
        var path = undefined;
        var type = 'folder'
        if( nodes && nodes.length > 0 )
        {
            node = nodes[0].parentNode;
        }

        if( node )
        {
            path = node.getAttribute( 'id' );
            type = (node.querySelector('.icon_folder') ? 'folder' : 'file');
        }
        console.log( path, type );
        this.editItem(path);
    }

    deleteFile()
    {
        hideAllMenus();
        var nodes = document.querySelectorAll( '.treeview-content .selected' );
        var node = undefined;
        var path = undefined;
        var type = 'folder'
        if( nodes && nodes.length > 0 )
        {
            node = nodes[0].parentNode;
        }

        if( node )
        {
            path = node.getAttribute( 'id' );
            type = (node.querySelector('.icon_folder') ? 'folder' : 'file');
        }

        if( window.ConfirmDialogBox )
        {
            window.ConfirmDialogBox.show( 'confirm_delete', 'delete_' + type, () => {
                ProjectAPI.deleteItem( path, type )
                    .then( ( response ) => {
                        if( window.ProjectsTree )
                        {
                            if( type= 'file' && window.TabBar )
                            {
                                var event = new CustomEvent( 'tabbar:close-tab', { detail: { path: path } } );
                                document.dispatchEvent( event );
                            }
                            window.ProjectsTree.loadProjects( true );
                        }
                    })
                    .catch( (error) => {
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                        }
                    }
                );
            }, () => {
                console.log('cancel delete element');
            } );
        }
    }
    
    componentDidMount()
    {
        window.ProjectsTree = this;
        var panel = document.querySelector('.projects-tree');
        const md = (event ) => {
            window.ProjectsTree.ismdwn = 1
            document.body.addEventListener('mousemove', mV);
            document.body.addEventListener('mouseup', end);
        }

        const mV = (event) => {
            if (window.ProjectsTree.ismdwn === 1 )
            {
                this.resize ( event.clientX )
            } else {
                end();
            }            
        };

        const end = (event) => {
            window.ProjectsTree.ismdwn = 0
            document.body.removeEventListener('mouseup', end)
            document.body.removeEventListener('mousemove', mV)
            //elmSplitter.removeEventListener('mousemove', mV)
        };

        var elmSplitter = document.querySelector('.projects-tree .splitter');
        if( elmSplitter )
        {
            elmSplitter.addEventListener('mousedown', md);
        }
    }

    /* sz: (num) or 
           {size: (num), min: (num), max: (num), force: (bool)}    force: ignore absolute limits
        percentage: (bool)
    */
    resize ( sz, percentage, disableFullScreen = true ) 
    {
        if ( typeof sz === 'number' ) sz = { size: sz };

        if ( percentage ) {
            // conversion to pixels
            if ( typeof sz.size !== 'undefined' ) sz.size = window.innerWidth * sz.size;
            if ( typeof sz.min !== 'undefined' ) sz.min = window.innerWidth * sz.min;
            if ( typeof sz.max !== 'undefined' ) sz.max = window.innerWidth * sz.max;
        }

        // check absolute limits
        if (typeof sz.min === 'undefined' || sz.min < this.minWidth) sz.min = sz.force ? sz.size : this.minWidth;
        if (typeof sz.max === 'undefined' || sz.max > window.innerWidth) sz.max = sz.force ? sz.size : window.innerWidth;
        
        let panel = document.querySelector('.projects-tree');
        if( panel )
        {
            if ( typeof sz.size === 'undefined' ) sz.size = panel.getBoundingClientRect().width;
            sz.size = Math.min(Math.max(sz.size, sz.min), sz.max);

            panel.style.width = sz.size + "px";
            panel.style.right = "0px";
            let elmEditor = document.querySelector('.code-editor-container');
            if( elmEditor )
            {
                elmEditor.style.left = panel.style.width;
            }
            
        }

        if (disableFullScreen && window.ToolBar) window.ToolBar.fullEditor = false;
    }

    loadProjects( force = false )
    {
        // If the nodes are not loaded, get them
        var self = this;
        
        if( this.state.projects === undefined || force === true )
        {
            if( window.LoaderAnim )
            {
                window.LoaderAnim.show( 'cube', Translator.get('loading_projects') );
            }

            // get the Projects List
            ProjectAPI.getProjectsList()
                .then( ( response ) => {
                    var projects = response;
                    ProjectAPI.getTreeFiles()
                        .then( ( response ) => {
                            if( window.LoaderAnim )
                            {
                                window.LoaderAnim.hide();
                            }
                            var event = new CustomEvent( 'treeview:update', { detail: { projects: projects, tree: response.children } } );
                            document.dispatchEvent( event );
                        })
                        .catch( (error) => {
                            if( window.LoaderAnim )
                            {
                                window.LoaderAnim.hide();
                            }
                            setTimeout( () => {
                                if( window.Messenger )
                                {
                                    window.Messenger.showMessage( 'error', error );
                                }
                                if( window.Application )
                                {
                                    window.Application.setState( { context: '' } );
                                }
                                setTimeout( () => {
                                    if( localStorage) localStorage.clear();
                                    if( sessionStorage) sessionStorage.clear();
                                    var event = new CustomEvent( 'account:show', { detail: { restart: true } });
                                    document.dispatchEvent( event );
                                }, 500 );
                            },500);

                        });
                } )
                .catch( (error) => {
                    if( window.LoaderAnim )
                    {
                        window.LoaderAnim.hide();
                    }

                    setTimeout( () => {
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                        }
                        if( window.Application )
                        {
                            window.Application.setState( { context: '' } );
                        }
                        setTimeout( () => {
                            if( localStorage) localStorage.clear();
                            if( sessionStorage) sessionStorage.clear();
                            var event = new CustomEvent( 'account:show', { detail: { restart: true} });
                            document.dispatchEvent( event );
                        }, 500 );
                    },500 )
                } );
        }
    }

    checkFile( e, item )
    {
        var n = -1;
        var path = item.key;
        var ext = FileUtils.getExtension( path );
        if( 
            ext == 'aoz' || 
            ext == 'aozinc' || 
            ext == 'js' || 
            ext == 'html' || 
            ext == 'css' || 
            ext == 'json' || 
            ext == 'hjson' ||
            ext == 'txt' ||
            ext == 'md' ||
            ext == 'xml' ||
            ext == 'csv'
        )
        {
            if( window.TabBar )
            {
                var result = window.TabBar.selectTab( path );
                if( result == false )
                {
                    if( window.ProjectsTree)
                    {
                        window.ProjectsTree.openFile( e, item );
                    }
                }
            }
            else
            {
                if( window.ProjectsTree)
                {
                    window.ProjectsTree.openFile( e, item );
                }
            }
        }
    }

    actionFile( e, item )
    {
        hideAllMenus();
        var n = -1;
        var path = item.key;
        if( 
            item.icon != 'icon_aoz_file' &&
            item.icon != 'icon_folder_resources' &&
            item.icon != 'icon_folder_images' &&
            item.icon != 'icon_folder_samples' &&
            item.icon != 'icon_folder_icons' &&
            item.icon != 'icon_folder_assets' &&
            item.icon != 'icon_folder_fs' &&
            item.icon != 'icon_folder_musics'
        )
        {
            item.edit = true;
        }
        else
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'warn', 'cannot_edit_name' );
            }
        }
    }

    updateItem( item, items )
    {
        if( item.icon == 'icon_folder' || ( item.children && item.children.length > 0 ) )
        {
            if( 
                item.label.indexOf( '/' ) >= 0
                || item.label.indexOf( '\\' ) >= 0
                || item.label.indexOf( '.' ) >= 0
                || item.label.indexOf( ':' ) >= 0
                || item.label.indexOf( '*' ) >= 0
                || item.label.indexOf( '?' ) >= 0
                || item.label.indexOf( '"' ) >= 0
                || item.label.indexOf( '<' ) >= 0
                || item.label.indexOf( '>' ) >= 0
                || item.label.indexOf( '|' ) >= 0 
            )
            {
                if( window.Messenger )
                {
                    window.Messenger.showMessage( 'error', "invalid_folder_name" );
                    item.label = FileUtils.getBasename( item.key );
                    return;
                }
            }

            if( FileUtils.getBasename(item.key) == item.label )
            {
                return;
            }

            ProjectAPI.renameFolder( item.key, item.label )
                .then( ( response ) => {
                    item.key = response;
                })
                .catch( (error) => {
                    if( window.Messenger )
                    {
                        window.Messenger.showMessage( 'error', error );
                    }
                    item.label = FileUtils.getBasename( item.key );
                });
        }
        else
        {
            if( 
                item.label.indexOf( '/' ) >= 0
                || item.label.indexOf( '\\' ) >= 0
                || item.label.indexOf( ':' ) >= 0
                || item.label.indexOf( '*' ) >= 0
                || item.label.indexOf( '?' ) >= 0
                || item.label.indexOf( '"' ) >= 0
                || item.label.indexOf( '<' ) >= 0
                || item.label.indexOf( '>' ) >= 0
                || item.label.indexOf( '|' ) >= 0 
            )
            {
                if( window.Messenger )
                {
                    window.Messenger.showMessage( 'error', "invalid_file_name" );
                    item.label = FileUtils.getBasename( item.key );
                    return;
                }
            } 

            if( FileUtils.getBasename(item.key) == item.label )
            {
                return;
            }         
            
            ProjectAPI.renameFile( item.key, item.label )
                .then( ( response ) => {
                    var lastKey = item.key;
                    item.key = response;
                    if( window.TabBar )
                    {
                        window.TabBar.updateItem( item, lastKey );
                    }
                })
                .catch( (error) => {
                    if( window.Messenger )
                    {
                        window.Messenger.showMessage( 'error', error );
                    }
                    item.label = FileUtils.getBasename( item.key );
                });
        }
    }

    editItem( key ) {
        var items = this.state.tree;
        var find = ( items ) => {
            for( var i = 0; i < items.length; i++ )
            {
                if( items[i].key == key )
                {
                    items[i].edit = true;
                }
                else
                {
                    items[i].edit = false;
                }

                if( items[i].children && items[i].children.length > 0 )
                {
                    find( items[i].children );
                }
            }
        }
        find( items );
        this.setState( {tree: items} );
    }

    showStorageBar()
    {
        return(<StorageBar />);
    }

    openFile( e, item ) 
    {
        hideAllMenus();
        var event = new CustomEvent( 'codeeditor:open-file', { detail: { item: item } } );
        document.dispatchEvent( event );
    }

    render() 
    {
        // Show the treeview on the screen
        super.render();
        return (
            <div className="projects-tree">
                <TreeView noFilters={true} onContextMenu={this.showContextMenu} items={this.state.tree} forFooters={this.showStorageBar} onUpdateItem={this.updateItem} onClick={this.checkFile} onDoubleClick={this.actionFile} tools={this.tools} />
                <div className="splitter"></div>
                <Menu id="projectsMenu" className="projects-menu" model={this.state.menuModel} />
                <Menu id="projectsCtxMenu" className="projects-menu projects-ctx-menu" model={this.state.contextMenuModelProject} />
                <Menu id="projectsCtxFiles" className="projects-menu files-ctx-menu" model={this.state.contextMenuModelFiles} />
                <Menu id="projectsCtxResources" className="projects-menu files-ctx-menu" model={this.state.contextMenuModelResources} />

                <input type="file" id="fileInput" style={{display: 'none'}} accept=".aozip" title={Translator.get('import_project')} onChange={ (e) => this.checkSelectedFileForImport(e)}/>
            </div>
        );
    }
}
