import GenericUI from '../generic.ui.js'; 
import {collapseAll, expendAll, getTreeFooters, getTreeHeaders, getTreeItems, deleteItem, addItem, editItem, validateItem, selectItem} from './func/index.js';

export default class TreeView extends GenericUI {

    constructor( props ) {
        super( props );
        this.state = { items: props.items, selectedItem: null };
        var self = this;
    }

    componentDidMount() {
        window.TreeView = this;
    }
    
    render() {
        super.render();
        return(
            <div className="treeview">
                { getTreeHeaders( this.props ) }
                <div className="treeview-content-scroller">
                    <div className="treeview-content">
                        <ul>
                            { getTreeItems( this.state.items, this.state.selectedItem, this.props ) }
                        </ul>
                    </div>
                </div>
                { getTreeFooters( this.props ) }
            </div>
        );
    }
}
