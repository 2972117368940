/**
 * @description                                         The file utilities
 */
export const FileUtils = {
    /**
     * @description                                     Get the extension of the file
     * @param {String} path                             The path of the file
     * @returns {String}                                The extension of the file
     */
    getExtension: function( path )
    {
        var parts = path.split( '.' );
        if( parts )
        {
            var ext = parts[ parts.length - 1 ].toLowerCase();
            if( ext )
            {
                return ext;
            }
        }
        return "";
    },

    /**
     * @description                                     Get the basename of the file
     * @param {String} path                             The path of the file 
     * @returns {String}                                The basename of the file
     */
    getBasename: function( path )
    {
        var parts = path.split( '/' );
        if( parts )
        {
            return parts[ parts.length - 1 ];
        }
        return "";
    },

    dirname: function( path )
    {
        var parts = path.split( '/' );
        if( parts )
        {
            return parts.slice( 0, parts.length - 1 ).join( '/' );
        }
        return "";
    }
};