import React from 'react';
import GenericUI from '../generic.ui';
import Translator from '../../translator';
import './styles/desktop.css';
//import './styles/smartphone.css';
//import './styles/tablet.css';
import AOZMagic from '../../aoz_magic';

export default class AIDocPanel extends GenericUI {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1
        }; 
        this.ismdwn = 0;
        
        this.app_aoz_magic_url = AOZMagic.url();

        // ref pour l'envoi de messages
        this.app_aoz_magic_ref = React.createRef();
        this.app_aoz_doc_ref = React.createRef();

        this.minWidth = 6;
    }

    componentDidMount() {
        window.AIDocPanel = this;
        window.AOZMagic = new AOZMagic(window.AIDocPanel.app_aoz_magic_ref.current);

        const md = (event ) => {
            window.AIDocPanel.ismdwn = 1
            document.body.addEventListener('mousemove', mV);
            document.body.addEventListener('mouseup', end);

            window.AIDocPanel.app_aoz_magic_ref.current.style.pointerEvents = 'none';
            window.AIDocPanel.app_aoz_doc_ref.current.style.pointerEvents = 'none';
        }

        const mV = (event) => {
            if (window.AIDocPanel.ismdwn === 1 )
            {
                this.resize ( window.innerWidth - event.clientX );
            } else {
                end();
            }            
        };

        const end = (event) => {
            window.AIDocPanel.ismdwn = 0
            document.body.removeEventListener('mouseup', end)
            document.body.removeEventListener('mousemove', mV)
            window.AIDocPanel.app_aoz_magic_ref.current.style.pointerEvents = '';
            window.AIDocPanel.app_aoz_doc_ref.current.style.pointerEvents = '';

            //elmSplitter.removeEventListener('mousemove', mV)
        };

        var elmSplitter = document.querySelector('.aidocpanel .splitter');
        if( elmSplitter )
        {
            elmSplitter.addEventListener('mousedown', md);
        }

    }

    componentWillUnmount() {
        window.AOZMagic.stop();
    }

    /* sz: (num) or 
           {size: (num), min: (num), max: (num), force: (bool)}   force = ignore absolute limits
        percentage: (bool)
    */
    resize ( sz, percentage, disableFullScreen = true) {
        if ( typeof sz === 'number' ) sz = { size: sz };

        if ( percentage ) {
            // conversion to pixels
            if ( typeof sz.size !== 'undefined' ) sz.size = window.innerWidth * sz.size;
            if ( typeof sz.min !== 'undefined' ) sz.min = window.innerWidth * sz.min;
            if ( typeof sz.max !== 'undefined' ) sz.max = window.innerWidth * sz.max;
        }

        // check absolute limits
        if (typeof sz.min === 'undefined' || sz.min < this.minWidth) sz.min = sz.force ? sz.size : this.minWidth;
        if (typeof sz.max === 'undefined' || sz.max > window.innerWidth) sz.max = sz.force ? sz.size : window.innerWidth;
        
        let panel = document.querySelector('.aidocpanel');
        if( panel )
        {
            if ( typeof sz.size === 'undefined' ) sz.size = panel.getBoundingClientRect().width;
            sz.size = Math.min(Math.max(sz.size, sz.min), sz.max);

            panel.style.width = sz.size + "px";
            panel.style.right = "0px";
            let elmEditor = document.querySelector('.code-editor-container');
            if( elmEditor )
            {
                elmEditor.style.right = panel.style.width;
            }
            
        }

        if (disableFullScreen && window.ToolBar) window.ToolBar.fullEditor = false;
    }

    render() {
        super.render();
        return (
            <div className="aidocpanel">
                <div className="aidocpanel-tabs">
                    <table>
                        <tbody>
                            <tr>
                                <td className={ (this.state.tab == 1 ) ? 'active' : ''} onClick={ (e) => { this.setState( { tab: 1 } ) } }>
                                    AOZ&nbsp;Magic
                                </td>
                                <td className={ (this.state.tab == 2 ) ? 'active' : ''} onClick={ (e) => { this.setState( { tab: 2 } ) } }>
                                    Documentation
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="aidocpanel-content">
                    <iframe ref={this.app_aoz_magic_ref} id="app_aoz_magic" className="aidocpanel-iframe" style={this.state.tab != 1 ? {display:'none'} : {}} src= {this.app_aoz_magic_url} title="AOZ Magic" allow="clipboard-write"></iframe>
                    <iframe ref={this.app_aoz_doc_ref} id="app_aoz_doc" className="aidocpanel-iframe" style={this.state.tab != 2 ? {display:'none'} : {}} src="https://doc.aoz.studio/topics.php" title="AOZ Documentation"></iframe>
                </div>
                <div className="splitter"></div>
            </div>
            
        );
    }
}