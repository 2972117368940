// Register a completion item provider for the new language
import {aoz_documentation} from "./aoz-documentation.js";
export function initSuggestions( monaco )
{
	monaco.languages.registerCompletionItemProvider('aoz', {
		provideCompletionItems: () => {
			var suggestions = [
				{
		label: "Actor",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Actor ${1:Index}, Image$="${2:Image}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Image manipulation on the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor" ].value
		},
	},
	{
		label: "Actor Alpha",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor Alpha( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of the Actor\'s Alpha',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_alpha" ].value
		},
	},
	{
		label: "Actor Angle",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor Angle( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of the Actor\'s Angle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_angle" ].value
		},
	},
	{
		label: "Actor Anim$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Actor Anim$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of the Actor\'s Animation',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_anim$" ].value
		},
	},
	{
		label: "Actor Anim Frame",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Anim Frame( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of the frame of the Actor\'s Animation',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_anim_frame" ].value
		},
	},
	{
		label: "Actor Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Col( ${2:index}, index2 = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests the collision between 2 actors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_col" ].value
		},
	},
	{
		label: "Actor Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Actor Del ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_del" ].value
		},
	},
	{
		label: "Actor Exists",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Exists( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests if an actor exists',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_exists" ].value
		},
	},
	{
		label: "Actor Group$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Actor Group$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Group value of the Actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_group$" ].value
		},
	},
	{
		label: "Actor Height",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor Height( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the height of the Actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_height" ].value
		},
	},
	{
		label: "Actor Image",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Image( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of the image used by the actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_image" ].value
		},
	},
	{
		label: "Actor Image$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Actor Image$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of the image used by the actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_image$" ].value
		},
	},
	{
		label: "Actor IsEnable",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor IsEnable( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests if an actor is enabled',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_isenable" ].value
		},
	},
	{
		label: "Actor IsHRev",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor IsHRev( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests if an actor is horizontaly reversed',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_ishrev" ].value
		},
	},
	{
		label: "Actor IsVisible",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor IsVisible( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests if an actor is visible',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_isvisible" ].value
		},
	},
	{
		label: "Actor IsVRev",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor IsVRev( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Tests if an actor is verticaly reversed',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_isvrev" ].value
		},
	},
	{
		label: "Actor Reset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Actor Reset ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset all properties of one or all the actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_reset" ].value
		},
	},
	{
		label: "Actor ScaleX",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor ScaleX( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the factor X of the scaling',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_scalex" ].value
		},
	},
	{
		label: "Actor ScaleY",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor ScaleY( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the factor Y of the scaling',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_scaley" ].value
		},
	},
	{
		label: "Actor SkewX",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor SkewX( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the factor X of the skew',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_skewx" ].value
		},
	},
	{
		label: "Actor SkewY",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor SkewY( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the factor Y of the skew',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_skewy" ].value
		},
	},
	{
		label: "Actor Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Actor Width( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the width of an actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_width" ].value
		},
	},
	{
		label: "Actor X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor X( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the X position of an actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_x" ].value
		},
	},
	{
		label: "Actor Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Y( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Y position of an actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_y" ].value
		},
	},
	{
		label: "Actor Z",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Actor Z( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Z position of an actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_z" ].value
		},
	},
	{
		label: "Del Actor",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Actor ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_actor" ].value
		},
	},
	{
		label: "Del All Actors",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del All Actors',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete all the actors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_all_actors" ].value
		},
	},
	{
		label: "Reset Actor",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reset Actor ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset all the properties of an Actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reset_actor" ].value
		},
	},
	{
		label: "Set Gravity",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Gravity',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the Gravity to apply on all Actors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_gravity" ].value
		},
	},
	{
		label: "Show Physic Bodies",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Show Physic Bodies',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show each physic body on the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "show_physic_bodies" ].value
		},
	},
	{
		label: "Hide Physic Bodies",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hide Physic Bodies',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide each physic body on the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hide_physic_bodies" ].value
		},
	},
	{
		label: "Actor Animation",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Actor Animation ${1:index}, Anim$ = "${2:value2}", Sequence$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Addt an animation sequence for an Actor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "actor_animation" ].value
		},
	},
	{
		label: "Amal",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Amal ${1:index}, string$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amal" ].value
		},
	},
	{
		label: "Amal Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Amal Freeze',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amal_freeze" ].value
		},
	},
	{
		label: "Amal Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Amal Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amal_off" ].value
		},
	},
	{
		label: "Amal On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Amal On ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amal_on" ].value
		},
	},
	{
		label: "Amalerr$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Amalerr$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amalerr$" ].value
		},
	},
	{
		label: "Amalerr",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Amalerr()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amalerr" ].value
		},
	},
	{
		label: "Amreg",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Amreg( channel = ${2:value2}, register = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amreg" ].value
		},
	},
	{
		label: "Chanan",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Chanan( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "chanan" ].value
		},
	},
	{
		label: "Chanmv",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Chanmv( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "chanmv" ].value
		},
	},
	{
		label: "Set Hardcol",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Hardcol bitmap1 = ${1:value1}, bitmap2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set hardware register for hardware Sprite collision detection. This instruction has This instruction has effect in AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_hardcol" ].value
		},
	},
	{
		label: "Set Hardcol",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Set Hardcol()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return collision status after a Set Hardcol instruction. Returns 0 in AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_hardcol" ].value
		},
	},
	{
		label: "AReg",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = AReg( register = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'variable',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "areg" ].value
		},
	},
	{
		label: "Copper On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Copper On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Re-start automatic copper generation. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "copper_on" ].value
		},
	},
	{
		label: "Copper Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Copper Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop automatic copper generation. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "copper_off" ].value
		},
	},
	{
		label: "Cop Swap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cop Swap',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Swap logical and physical copper lists. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_swap" ].value
		},
	},
	{
		label: "Cop Reset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cop Reset',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Re-set copper list pointer. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_reset" ].value
		},
	},
	{
		label: "Cop Wait",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cop Wait',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Insert a WAIT instruction into copper list. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_wait" ].value
		},
	},
	{
		label: "Cop Movel",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cop Movel',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write a long MOVE instruction to copper list. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_movel" ].value
		},
	},
	{
		label: "Cop Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cop Move',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write a MOVE instruction to current copper list. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_move" ].value
		},
	},
	{
		label: "Cop Logic",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Cop Logic()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Give address of logical copper list. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cop_logic" ].value
		},
	},
	{
		label: "Multi Wait",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Multi Wait',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Manage multi-tasking on the Amiga. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "multi_wait" ].value
		},
	},
	{
		label: "AMOS To Front",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'AMOS To Front',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Bring AMOS IDE in the front of display. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amos_to_front" ].value
		},
	},
	{
		label: "AMOS To Back",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'AMOS To Back',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Bring AMOS IDE in the back of display. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amos_to_back" ].value
		},
	},
	{
		label: "AMOS Here",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = AMOS Here()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Detect if AMOS is running. Emulation returns TRUE',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amos_here" ].value
		},
	},
	{
		label: "AMOS Lock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'AMOS Lock',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Blocks Amiga-A key. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "amos_lock" ].value
		},
	},
	{
		label: "Close Workbench",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Close Workbench',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Cclose the Workbench. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "close_workbench" ].value
		},
	},
	{
		label: "Set Buffer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Buffer',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the size of the variable area. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_buffer" ].value
		},
	},
	{
		label: "Equ",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Equ( string = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get an equate. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "equ" ].value
		},
	},
	{
		label: "Lvo",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Lvo()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the Library Vector Offset. This instruction will not be implemented in AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lvo" ].value
		},
	},
	{
		label: "Set Double Precision",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Double Precision',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Engage double precision accuracy. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_double_precision" ].value
		},
	},
	{
		label: "Request WB",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Request WB',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Use the Workbench system requester. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "request_wb" ].value
		},
	},
	{
		label: "Request ON",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Request ON',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Use the AMOS Professional requester routine. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "request_on" ].value
		},
	},
	{
		label: "Request OFF",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Request OFF',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Used to cancel the requester. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "request_off" ].value
		},
	},
	{
		label: "NTSC",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = NTSC()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if the display is emulating a NTSC TV set.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ntsc" ].value
		},
	},
	{
		label: "Load Iff",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Iff path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load an IFF image into a screen (deprecated, use "Load Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_iff" ].value
		},
	},
	{
		label: "Save Iff",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save Iff path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save an IFF image out of a screen (deprecated, use "Save Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_iff" ].value
		},
	},
	{
		label: "PLoad",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'PLoad path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load machine code directly into memory. This instruction will not be implemented in AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pload" ].value
		},
	},
	{
		label: "Mask Iff",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Mask Iff mask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicate what sections of an IFF file to load the next time the Load IFF instruction is used',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mask_iff" ].value
		},
	},
	{
		label: "Frame Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Frame Load channel = ${1:value1}, bank = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load frames of an IFF channel into memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frame_load" ].value
		},
	},
	{
		label: "Frame Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Frame Play bank = ${1:value1}, number = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play IFF frameson screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frame_play" ].value
		},
	},
	{
		label: "IFF Anim",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IFF Anim path$ = "${1:value1}", screen = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play IFF frameson screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "iff_anim" ].value
		},
	},
	{
		label: "Frame Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Frame Length( channel = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if a file or directory exists on the disc',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frame_length" ].value
		},
	},
	{
		label: "Frame Skip",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Frame Skip( bank = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Skip past an animation frame',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frame_skip" ].value
		},
	},
	{
		label: "Frame Param",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Frame Param()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a parameter after playing a frame',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frame_param" ].value
		},
	},
	{
		label: "Get Fonts",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Fonts',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scan the system for new fonts, will scan both Google and Amiga fonts.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_fonts" ].value
		},
	},
	{
		label: "Get Disc Fonts",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Disc Fonts',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scan the system for Amiga fonts.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_disc_fonts" ].value
		},
	},
	{
		label: "Get Rom Fonts",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Rom Fonts',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scan the system for Google fonts.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_rom_fonts" ].value
		},
	},
	{
		label: "Get CBlock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get CBlock ${1:index}, X1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the graphics from a screen into a buffer. (deprecated, use Get Block)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_cblock" ].value
		},
	},
	{
		label: "Put CBlock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Put CBlock ${1:index}, x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a previously capture block in the current screen. (deprecated, use Put Block)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "put_cblock" ].value
		},
	},
	{
		label: "Del CBlock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del CBlock ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys an existing CBlock and free memory (deprecated, use Del Block)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_cblock" ].value
		},
	},
	{
		label: "Reserve As Chip Work",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reserve As Chip Work ${1:index}, length = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserve and allocates memory for a new bank in Amiga chip memory, and add it to the list of banks (deprecated)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reserve_as_chip_work" ].value
		},
	},
	{
		label: "Reserve As Chip Data",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reserve As Chip Data ${1:index}, length = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserve and allocates memory for a new bank of "Data" type in Amiga chip memory, and add it to the list of banks (deprecated)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reserve_as_chip_data" ].value
		},
	},
	{
		label: "Pack",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pack screen = ${1:value1}, bank = ${2:value2}, x1 = ${3:value3}, y1 = ${4:value4}, x2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Compress an screen into a memory bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pack" ].value
		},
	},
	{
		label: "SPack",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'SPack screen = ${1:value1}, bank = ${2:value2}, x1 = ${3:value3}, y1 = ${4:value4}, x2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Compress an screen into a memory bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "spack" ].value
		},
	},
	{
		label: "Unpack",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unpack bank = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Unpack a compacted screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unpack" ].value
		},
	},
	{
		label: "Set Tempras",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Tempras buffer = ${1:value1}, integer = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set Temporary Raster',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_tempras" ].value
		},
	},
	{
		label: "Icon Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Icon Base( iconIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the address of the Icon whose number is specified in brackets. Not implemented in AOZ',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "icon_base" ].value
		},
	},
	{
		label: "Free",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Free()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the amount of free memory on the machine.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "free" ].value
		},
	},
	{
		label: "Chip Free",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Chip Free()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the amount of free memory on the machine. Warnign, not tested and maybe deprecated in the future./ The original AMOS instruction reported the amount of free chip memory of the machine, which has no meaning tocay',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "chip_free" ].value
		},
	},
	{
		label: "Fast Free",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Fast Free()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the amount of free memory on the machine. Warnign, not tested and maybe deprecated in the future./ The original AMOS instruction reported the amount of free fast memory of the machine, which has no meaning tocay',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fast_free" ].value
		},
	},
	{
		label: "Set Stack",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Stack space = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, we used to set the stack space on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_stack" ].value
		},
	},
	{
		label: "Set Equate Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Equate Bank bank = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_equate_bank" ].value
		},
	},
	{
		label: "Call",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Call Address = ${1:value1}, parameter = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "call" ].value
		},
	},
	{
		label: "ExeCall",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'ExeCall offset = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "execall" ].value
		},
	},
	{
		label: "GfxCall",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'GfxCall offset = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gfxcall" ].value
		},
	},
	{
		label: "DosCall",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DosCall offset = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "doscall" ].value
		},
	},
	{
		label: "IntCall",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IntCall offset = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deprecated, was used on the Amiga',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "intcall" ].value
		},
	},
	{
		label: "Menu Base",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Base x = ${1:value1}, y = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the starting position of a menu. Amiga only, This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_base" ].value
		},
	},
	{
		label: "Set Menu",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Menu item = ${1:value1}, x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move a menu item. Amiga only, This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_menu" ].value
		},
	},
	{
		label: "Menu TLine",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu TLine level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display menu items as a total horizontal bar. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_tline" ].value
		},
	},
	{
		label: "Menu Movable",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Movable level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate automatic menu movement. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_movable" ].value
		},
	},
	{
		label: "Menu Static",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Static level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop automatic menu movement. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_static" ].value
		},
	},
	{
		label: "Menu Item Static",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Item Static level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Fix items in static positions. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_item_static" ].value
		},
	},
	{
		label: "Menu Item Movable",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Item Movable level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move individual menu options. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_item_movable" ].value
		},
	},
	{
		label: "Menu Active",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Active level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate a menu item. Will be implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_active" ].value
		},
	},
	{
		label: "Menu Separate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Separate level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Separate a list of menu items. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_separate" ].value
		},
	},
	{
		label: "Menu Link",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Link LEVEL = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Link a list of menu items. Will be implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_link" ].value
		},
	},
	{
		label: "Menu Called",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Called level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Re-draw a menu item continually. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_called" ].value
		},
	},
	{
		label: "Menu Once",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Once level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn off automatic re-drawing. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_once" ].value
		},
	},
	{
		label: "X Menu",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Menu( level = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the graphical x-coordinate of a menu item. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_menu" ].value
		},
	},
	{
		label: "Y Menu",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Menu( level = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the graphical x-coordinate of a menu item. This instruction may be implemented in a future version of AOZ.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_menu" ].value
		},
	},
	{
		label: "Screen Swap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Swap',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Swaps the physical and logical buffers of a screen. (Deprecated in AOZ, legacy instruction, has no real effect)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_swap" ].value
		},
	},
	{
		label: "Dual Playfield",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dual Playfield screen = ${1:value1}, screen2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Associate the display of two screens into a parallax display. (Deprecated, use "Set Transparent" and "Screen Offset" to associate more than two screens together)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dual_playfield" ].value
		},
	},
	{
		label: "Dual Priority",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dual Priority screen1 = ${1:value1}, screen2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the display priority of two screens associate in Dual Playfield. (Deprecated, use "Set Transparent" and "Screen Offset" to associate more than two screens together, and then "Screen To Front" or "Screen To Back" to handle the display priority)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dual_priority" ].value
		},
	},
	{
		label: "Phybase",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Phybase( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the address of the first pixel in the bitmap buffer of the current screen (deprecated, there is no difference between logical and physical screen buffers in AOZ)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "phybase" ].value
		},
	},
	{
		label: "Logbase",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Logbase( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the address of the first pixel in the bitmap buffer of the current screen (deprecated, there is no difference between logical and physical screen buffers in AOZ)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "logbase" ].value
		},
	},
	{
		label: "Physic",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Physic( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number representing the physical buffer of the current screen, to be used in "Screen Copy" and all Screen instructions that necessitate a Screen Index. (deprecated, no such thing as physical or logic al screen in AOZ)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "physic" ].value
		},
	},
	{
		label: "Logic",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Logic( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number representing the logical buffer of the current screen, to be used in "Screen Copy" and all Screen instructions that necessitate a Screen Index. (deprecated, no such thing as physical or logic al screen in AOZ)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "logic" ].value
		},
	},
	{
		label: "Autoback",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Autoback ${1:index}, mode = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the autoback background preservation system for graphical instruction (Deprecated, has no effect in AOZ)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "autoback" ].value
		},
	},
	{
		label: "Screen Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Base()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Not implemented, deprecated, will return 0',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_base" ].value
		},
	},
	{
		label: "Hires",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Hires()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number to be used in the "Screen Open" instruction, enforce a horizontal compression by half of the pixels',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hires" ].value
		},
	},
	{
		label: "Lowres",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Lowres()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number to be used in the "Screen Open" instruction, display pixel with their original horizontal ratio',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lowres" ].value
		},
	},
	{
		label: "Laced",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Laced()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number to be used in the "Screen Open" instruction, enforce a vertical compression by half of the pixels',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "laced" ].value
		},
	},
	{
		label: "Halfbright",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Halfbright()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical number to be used in the "Screen Open" instruction, and set the screen in Amiga-compatible Halfbright colour mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "halfbright" ].value
		},
	},
	{
		label: "X Hard",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Hard()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Converts a horizontal coordinate in a given screen to its equivalent in hardware coordinates taking into account the position, scale and rotation factor of the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_hard" ].value
		},
	},
	{
		label: "Y Hard",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Hard()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Converts a vertical coordinate in the current screen to its equivalent in hardware coordinates taking into account the position, scale and rotation factor of the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_hard" ].value
		},
	},
	{
		label: "Screen Mode",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Mode()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the "mode" parameter of a screen, as used in the "Screen Open" instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_mode" ].value
		},
	},
	{
		label: "Double Buffer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Double Buffer',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns the display system into double-buffering. Deprecated',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "double_buffer" ].value
		},
	},
	{
		label: "HZone",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = HZone(, x = ${2:value2}, y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check if a given hardware coordinate lay inside of a zone. Hardware coordinate are only different from screen coordinate for retro-machine emulation (Amiga, Atari etc.)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hzone" ].value
		},
	},
	{
		label: "Sprite Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sprite Base()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get Sprite table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_base" ].value
		},
	},
	{
		label: "Close Editor",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Close Editor',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close the AMOS Professional Editor Window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "close_editor" ].value
		},
	},
	{
		label: "Set Sprite Buffer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Sprite Buffer',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the height of hardware sprites',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_sprite_buffer" ].value
		},
	},
	{
		label: "Anim",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Anim channelIndex = ${1:value1}, definition$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts a simple animation',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "anim" ].value
		},
	},
	{
		label: "Anim Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Anim Off channelIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the animations of an animation channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "anim_off" ].value
		},
	},
	{
		label: "Anim On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Anim On channelIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts the animations of an animation channel and eventually set the refresh frequency',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "anim_on" ].value
		},
	},
	{
		label: "Anim Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Anim Freeze channelIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause the animations of an animation channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "anim_freeze" ].value
		},
	},
	{
		label: "Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move ${1:index}, definitionX$ = "${2:value2}", definitionY$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts a simple movement horizontally and vertically. You must use "Move On" to actually see the object moving.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move" ].value
		},
	},
	{
		label: "Move X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move X channelIndex = ${1:value1}, definition$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts a simple movement horizontally. You must use "Move On" to actually see the object moving.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move_x" ].value
		},
	},
	{
		label: "Move Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move Y channelIndex = ${1:value1}, definition$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts a simple movement horizontally. You must use "Move On" to actually see the object moving.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move_y" ].value
		},
	},
	{
		label: "Move Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the movement of an animation channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move_off" ].value
		},
	},
	{
		label: "Move On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts the movement of an animation channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move_on" ].value
		},
	},
	{
		label: "Move Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Move Freeze channelIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the movement of an animation channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "move_freeze" ].value
		},
	},
	{
		label: "Movon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Movon channelIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if the animation channel is moving, false if not.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "movon" ].value
		},
	},
	{
		label: "Synchro On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Synchro On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn ON the automatic execution of the animations and movements',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "synchro_on" ].value
		},
	},
	{
		label: "Synchro Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Synchro Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn OFF the automatic execution of the AMAL programs',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "synchro_off" ].value
		},
	},
	{
		label: "Synchro",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Synchro',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Executes on step of animation of all AMAL channels. "Synchro Off" must have been used before calling this instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "synchro" ].value
		},
	},
	{
		label: "Show HTML",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Show HTML ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays the HTML content loaded with the Load Asset statement.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "show_html" ].value
		},
	},
	{
		label: "HTML Element",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'HTML Element ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Manipulation of a component of the HTML page',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "html_element" ].value
		},
	},
	{
		label: "HTML Code$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = HTML Code$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'returns the HTML code of an element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "html_code$" ].value
		},
	},
	{
		label: "HTML Element Attribute",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = HTML Element Attribute( ${2:index}, ATTRIBUTE$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'returns the attribute value of a HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "html_element_attribute" ].value
		},
	},
	{
		label: "HTML Element Attribute#",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = HTML Element Attribute#( ${2:index}, ATTRIBUTE$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'returns the attribute value of a HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "html_element_attribute#" ].value
		},
	},
	{
		label: "HTML Element Attribute$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = HTML Element Attribute$( ${2:index}, ATTRIBUTE$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'returns the attribute value of a HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "html_element_attribute$" ].value
		},
	},
	{
		label: "Console Log",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Log text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write a text in the JS Console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_log" ].value
		},
	},
	{
		label: "Console Error",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Error text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write an error text in the JS Console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_error" ].value
		},
	},
	{
		label: "Console Warn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Warn text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write a warn text in the JS Console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_warn" ].value
		},
	},
	{
		label: "JS Execute",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JS Execute javascript$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Executes the Javascript code defined in the parameter string..',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "js_execute" ].value
		},
	},
	{
		label: "Load Asset Listener",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Asset Listener onLoad$ = "${1:value1}", onLoad$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Définit les procédures AOZ a appeler lors du chargement des Assets',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_asset_listener" ].value
		},
	},
	{
		label: "Load Asset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Asset path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load an external file for Image, Audio, video or JSON. If the file is an image, it can be used with the "Bob" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_asset" ].value
		},
	},
	{
		label: "Del Asset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Asset type$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an asset from the memory.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_asset" ].value
		},
	},
	{
		label: "JSON Asset Property",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JSON Asset Property( ${2:index}, path$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a numeric value from the JSON file loaded with the "Load Asset" command',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_asset_property" ].value
		},
	},
	{
		label: "JSON Asset Property$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JSON Asset Property$( ${2:index}, path$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a string value from the JSON file loaded with the "Load Asset" command',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_asset_property$" ].value
		},
	},
	{
		label: "Asset Loaded",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Asset Loaded( ${2:index}, type$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return True if an asset has been loaded with the "Load Asset" command, else False',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "asset_loaded" ].value
		},
	},
	{
		label: "Bank Swap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bank Swap bank1 = ${1:value1}, bank2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Swap two banks in the list of banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bank_swap" ].value
		},
	},
	{
		label: "Bank Shrink",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bank Shrink bannkIndex = ${1:value1}, length = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the size of a memory bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bank_shrink" ].value
		},
	},
	{
		label: "Start",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Start( bankIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the address of the start of a memory bank (composite number, only useable with AOZ mmemory instruction)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "start" ].value
		},
	},
	{
		label: "Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Length( bankIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the length of a memory bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "length" ].value
		},
	},
	{
		label: "Erase",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Erase bankIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroy the content of a memory bank and remove it from the list of banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "erase" ].value
		},
	},
	{
		label: "Erase All",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Erase All',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroy all memory banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "erase_all" ].value
		},
	},
	{
		label: "Erase Temp",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Erase Temp',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroy all temporary memory banks (bank marked as "Work")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "erase_temp" ].value
		},
	},
	{
		label: "List Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'List Bank',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays the list of one or all the memory banks in the current screen, type and length',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "list_bank" ].value
		},
	},
	{
		label: "Reserve As Work",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reserve As Work bankIndex = ${1:value1}, length = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserve and allocates memory for a new bank of "Work" type, and add it to the list of banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reserve_as_work" ].value
		},
	},
	{
		label: "Reserve As Data",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reserve As Data bankIndex = ${1:value1}, length = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserve and allocates memory for a new bank of "Data" type, and add it to the list of banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reserve_as_data" ].value
		},
	},
	{
		label: "Image Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Image Width( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width in pixels of an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "image_width" ].value
		},
	},
	{
		label: "Image Height",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Image Height( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the height in pixels of an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "image_height" ].value
		},
	},
	{
		label: "Image DataURL$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Image DataURL$( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a data URI containing a representation of an image in the Images bank. The returned image is in a resolution of 96 dpi.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "image_dataurl$" ].value
		},
	},
	{
		label: "HRev",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = HRev( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a flag of bits to be used in Bob and Sprite instructions that will mirror the image horizontally',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hrev" ].value
		},
	},
	{
		label: "VRev",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = VRev( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a flag of bits to be used in Bob and Sprite instructions that will mirror the image vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vrev" ].value
		},
	},
	{
		label: "Rev",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Rev( imageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a flag of bits to be used in Bob and Sprite instructions that will mirror the image horizontally and vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rev" ].value
		},
	},
	{
		label: "Ins Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Image position = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts an empty image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_image" ].value
		},
	},
	{
		label: "Ins Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Image start = ${1:value1}, end = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts a number of empty images in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_image" ].value
		},
	},
	{
		label: "Del Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Image imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_image" ].value
		},
	},
	{
		label: "Del Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Image start = ${1:value1}, end = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a range of images from the image bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_image" ].value
		},
	},
	{
		label: "Get Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Image, imageIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the current screen, create a transparency mask out of true black (RGB #000000) and add the image to the Images bank.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_image" ].value
		},
	},
	{
		label: "Make Mask",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Make Mask imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a transparency mask out of the full black color of an image in the Images bank (RGB = $000000)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "make_mask" ].value
		},
	},
	{
		label: "No Mask",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'No Mask imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Removes the transparency mask of an image in the Images bank, turning the image full opaque',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "no_mask" ].value
		},
	},
	{
		label: "Hot Spot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hot Spot imageIndex = ${1:value1}, x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the hot-spot of an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hot_spot" ].value
		},
	},
	{
		label: "Hot Spot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hot Spot imageIndex = ${1:value1}, flags = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Calculates the hot-spot of an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hot_spot" ].value
		},
	},
	{
		label: "Hot Spot",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Hot Spot( imageIndex = ${2:value2}, direction$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the coordinate of the hot-spot of an image in the Images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hot_spot" ].value
		},
	},
	{
		label: "BGrab",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BGrab imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Grab a memory bank used by the previous program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bgrab" ].value
		},
	},
	{
		label: "BSend",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BSend imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Transfer a memory bank from the current program to the previous program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bsend" ].value
		},
	},
	{
		label: "=BStart",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: '=BStart imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the address of a memory bank from a previous program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bstart" ].value
		},
	},
	{
		label: "Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob ${1:index}, x = ${2:value2}, y = ${3:value3}, image = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the image of a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob" ].value
		},
	},
	{
		label: "Bob Camera",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Camera ${1:index}, x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Creates or sets the point of view on a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_camera" ].value
		},
	},
	{
		label: "Bob Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys all or one bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_off" ].value
		},
	},
	{
		label: "Bob Update Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Update Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the automatic bob coordinate update system. After it, all "Bob" instruction will no longer have a visible effect until an "Bob Update" instruction is useD',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_update_off" ].value
		},
	},
	{
		label: "Bob Update On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Update On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns on the automatic bob coordinate update system. After it, the effect of all "Bob" instructions will be visible on display without intervention on yoru part.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_update_on" ].value
		},
	},
	{
		label: "Bob Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Update',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Performs one step of animation of all the bobs in all the screen and displays the result',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_update" ].value
		},
	},
	{
		label: "Limit Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Limit Bob X1 = ${1:value1}, Y1 = ${2:value2}, X2 = ${3:value3}, Y2 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clip the position (but not the display) of all bobs to a limited area in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "limit_bob" ].value
		},
	},
	{
		label: "Clip Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clip Bob X1 = ${1:value1}, Y1 = ${2:value2}, X2 = ${3:value3}, Y2 = ${4:value4}, width = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clip the position (but not the display) of all bobs to a limited area in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clip_bob" ].value
		},
	},
	{
		label: "Paste Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Paste Bob x = ${1:value1}, y = ${2:value2}, image = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws an image from the image bank in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paste_bob" ].value
		},
	},
	{
		label: "Bob Alpha",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Alpha ${1:index}, alpha# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the global transparency of a bob, also called "Alpha"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_alpha" ].value
		},
	},
	{
		label: "Bob Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Show ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a bob visible on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_show" ].value
		},
	},
	{
		label: "Bob Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Hide ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a bob invisible',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_hide" ].value
		},
	},
	{
		label: "Bob Scale",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Scale idnex = ${1:value1}, scaleX# = ${2:value2}, scaleY# = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the scale (zoom) of a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_scale" ].value
		},
	},
	{
		label: "Bob Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Rotate ${1:index}, angle# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotates a bob around it\'s hot-spot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_rotate" ].value
		},
	},
	{
		label: "Bob Skew",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Skew ${1:index}, skewX# = ${2:value2}, skewY# = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Distorts a bob horizontally or vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_skew" ].value
		},
	},
	{
		label: "Put Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Put Bob ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws the bob in in the current screen as a stamp, at its current position with it\'s current scale, rotation and skew',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "put_bob" ].value
		},
	},
	{
		label: "Bob Shadow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Shadow ${1:index}, offsetX = ${2:value2}, offsetY = ${3:value3}, blur = ${4:value4}, rgba = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a shadow beneath the bob.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_shadow" ].value
		},
	},
	{
		label: "Bob Shadow Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Shadow Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off one or all bob shadows by setting the offsets to zero.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_shadow_off" ].value
		},
	},
	{
		label: "Priority On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Priority On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns on automatic sorting of the Z-order of the bobs in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "priority_on" ].value
		},
	},
	{
		label: "Priority Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Priority Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off automatic sorting of the Z-order of the bobs in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "priority_off" ].value
		},
	},
	{
		label: "Priority Reverse On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Priority Reverse On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inverts the Z-Order of the bobs in the current screen if Priority is activated. After this instruction, bobs with the highest Y coordinate will be displayed in the back of others',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "priority_reverse_on" ].value
		},
	},
	{
		label: "Priority Reverse Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Priority Reverse Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the inversion of the Z-Order of the bobs in the current screen if Priority is activated. After this instruction, bobs with the lowest Y coordinate will be displayed in the back of others',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "priority_reverse_off" ].value
		},
	},
	{
		label: "BobPriority",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BobPriority onOff = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns on or off automatic sorting of the Z-order of the bobs',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bobpriority" ].value
		},
	},
	{
		label: "X Bob",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Bob()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the horizontal coordinate of the bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_bob" ].value
		},
	},
	{
		label: "Y Bob",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Bob()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns vertical coordinate of the bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_bob" ].value
		},
	},
	{
		label: "I Bob",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = I Bob()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the index of the current image displayed by the bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "i_bob" ].value
		},
	},
	{
		label: "I Bob$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = I Bob$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of the current image displayed by the bob as a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "i_bob$" ].value
		},
	},
	{
		label: "IsBob",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = IsBob()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Checks if a bob is defined without generating an error',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "isbob" ].value
		},
	},
	{
		label: "Get Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Bob, imageIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the current screen, create a transparency mask out of true black (RGB #000000) and add the image to the Images bank. (Deprecated, use "Get Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_bob" ].value
		},
	},
	{
		label: "Ins Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Bob position = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts an empty image in the Images bank (deprecated, use "Ins Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_bob" ].value
		},
	},
	{
		label: "Ins Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Bob start = ${1:value1}, end = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts a number of empty images in the Images bank (deprecated, use "Ins Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_bob" ].value
		},
	},
	{
		label: "Del Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Bob imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an image in the Images bank (deprecated, use "Del Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_bob" ].value
		},
	},
	{
		label: "Bob Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move ${1:index}, movementX$ = "${2:value2}", movementY$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the movement of a bob horizontally and vertically.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move" ].value
		},
	},
	{
		label: "Bob Move X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move X ${1:index}, movement$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the horizontal movement of a bob. The bob starts to move immediately. For finer control please use the "Channel" and "MoveX" instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move_x" ].value
		},
	},
	{
		label: "Bob Move Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move Y ${1:index}, movement$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the horizontal movement of a bob. The bob starts to move immediately. For finer control please use the "Channel" and "Move Y" instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move_y" ].value
		},
	},
	{
		label: "Bob Move Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the movement of one or all the bobs.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move_off" ].value
		},
	},
	{
		label: "Bob Move On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move On ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Start the movement of one or all the bobs.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move_on" ].value
		},
	},
	{
		label: "Bob Move Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Move Freeze ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause the movement of one or all the bobs.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_move_freeze" ].value
		},
	},
	{
		label: "Bob Moveon",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Bob Moveon( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if the movement of one bob is completed or not.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_moveon" ].value
		},
	},
	{
		label: "Bob Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Filter ${1:index}, filterName$ = "${2:value2}", value1# = ${3:value3}, value2# = ${4:value4}, value3# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_filter" ].value
		},
	},
	{
		label: "Bob Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Filter Del ${1:index}, filterName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters applied to a bob.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_filter_del" ].value
		},
	},
	{
		label: "Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Filter$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filter$" ].value
		},
	},
	{
		label: "Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Filter( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of the given filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filter" ].value
		},
	},
	{
		label: "Bob Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Bob Col( ${2:index}, ${3:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if two bobs are in collision',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_col" ].value
		},
	},
	{
		label: "Bob Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Bob Col( ${2:index}, start = ${3:value3}, end = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if one bob is colliding with a set of bobs',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_col" ].value
		},
	},
	{
		label: "BobSprite Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BobSprite Col( ${2:index}, ${3:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if a bob is colliding with a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bobsprite_col" ].value
		},
	},
	{
		label: "BobSprite Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BobSprite Col( ${2:index}, start = ${3:value3}, end = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if one bob is colliding with a set of sprites',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bobsprite_col" ].value
		},
	},
	{
		label: "Sprite Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sprite Col( ${2:index}, start = ${3:value3}, end = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if two sprites are in collision',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_col" ].value
		},
	},
	{
		label: "SpriteBob Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = SpriteBob Col( ${2:index}, start = ${3:value3}, end = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if a bob is colliding with a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "spritebob_col" ].value
		},
	},
	{
		label: "Col",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Col( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test the status of an object after collision detection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "col" ].value
		},
	},
	{
		label: "Bob Collide With",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Collide With ${1:index}, list = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicate the category of bobs or sprites a bob collides with',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_collide_with" ].value
		},
	},
	{
		label: "Bob Collide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bob Collide ${1:index}, with = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the collision categories of a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_collide" ].value
		},
	},
	{
		label: "Bob Point",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Bob Point( ${2:index}, x = ${3:value3}, y = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if a point lays inside of a bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bob_point" ].value
		},
	},
	{
		label: "Sprite Point",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sprite Point( ${2:index}, x = ${3:value3}, y = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if a point lays over a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_point" ].value
		},
	},
	{
		label: "Get Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Palette ${1:index}, mask = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Grab the palette of another screen in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_palette" ].value
		},
	},
	{
		label: "Get Images Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Images Palette mask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy the colour palette from the Images bank to the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_images_palette" ].value
		},
	},
	{
		label: "Colour Back",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Colour Back number = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign a colour to the screen background',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "colour_back" ].value
		},
	},
	{
		label: "Colour",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Colour number = ${1:value1}, rgb = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the RGB value of a colour in the palette the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "colour" ].value
		},
	},
	{
		label: "Color",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Color number = ${1:value1}, rgb = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the RGB value of a colour in the palette the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "color" ].value
		},
	},
	{
		label: "Colour",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Colour( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the RGB value of a colour from the palette of the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "colour" ].value
		},
	},
	{
		label: "Shade",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shade intensity = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the current ink colour value of any colour from the palette of the current screen .',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shade" ].value
		},
	},
	{
		label: "Color",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Color( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the RGB value of a colour from the palette of the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "color" ].value
		},
	},
	{
		label: "RGB$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = RGB$( red = ${2:value2}, green = ${3:value3}, blue = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a string with the given RGB colors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rgb$" ].value
		},
	},
	{
		label: "RGB$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = RGB$( color$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a string with the RGB values of a Javascript color',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rgb$" ].value
		},
	},
	{
		label: "RGB",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = RGB( res = ${2:value2}, green = ${3:value3}, blue = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a number with the RGB value encoded',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rgb" ].value
		},
	},
	{
		label: "RGBA",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = RGBA( red = ${2:value2}, green = ${3:value3}, blue = ${4:value4}, alpha = ${5:value5})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a number with the RGBA value encoded',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rgba" ].value
		},
	},
	{
		label: "RGB",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = RGB( color$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a number with the RGB value of a Javascript color encoded',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rgb" ].value
		},
	},
	{
		label: "Flash Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Flash Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the flashing colours sequence',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "flash_off" ].value
		},
	},
	{
		label: "Flash",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Flash number = ${1:value1}, definition$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the flashing color sequence',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "flash" ].value
		},
	},
	{
		label: "Shift Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shift Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn off all colour shifts for current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shift_off" ].value
		},
	},
	{
		label: "Shift Up",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shift Up delay = ${1:value1}, firstColor = ${2:value2}, lastColor = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the colour values of the current screen upwards',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shift_up" ].value
		},
	},
	{
		label: "Shift Down",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shift Down delay = ${1:value1}, firstColor = ${2:value2}, lastColor = ${3:value3}, rotateFirstColor = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the colour values of the current screen downwards',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shift_down" ].value
		},
	},
	{
		label: "Fade",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fade delay = ${1:value1}, screenIndex = ${2:value2}, mask = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'TODO! Blend the colours of the current screen to the values of the palette of another screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fade" ].value
		},
	},
	{
		label: "Fade",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fade delay = ${1:value1}, colorList = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Blend colours to new values',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fade" ].value
		},
	},
	{
		label: "Default Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Default Palette colorList = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the default palette to be used in further "Screen Open" instructions',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "default_palette" ].value
		},
	},
	{
		label: "Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Palette colorList = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the palette of the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "palette" ].value
		},
	},
	{
		label: "Default",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Default',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restore the display to the state it was when the application start. Close all screen, all windows, detroys all bobs and sprites and open the default screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "default" ].value
		},
	},
	{
		label: "Joy",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Joy( joystick = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current state of the joystick',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "joy" ].value
		},
	},
	{
		label: "JUp",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JUp( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement up',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jup" ].value
		},
	},
	{
		label: "JDown",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JDown( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement down',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jdown" ].value
		},
	},
	{
		label: "JLeft",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JLeft( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement left',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jleft" ].value
		},
	},
	{
		label: "JRight",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JRight( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jright" ].value
		},
	},
	{
		label: "JUpLeft",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JUpLeft( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement up and left',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jupleft" ].value
		},
	},
	{
		label: "JUpRight",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JUpRight( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement up and right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jupright" ].value
		},
	},
	{
		label: "JDownLeft",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JDownLeft( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement down and left',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jdownleft" ].value
		},
	},
	{
		label: "JDownRight",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JDownRight( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick movement down and right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "jdownright" ].value
		},
	},
	{
		label: "Fire",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Fire( joystick = ${2:value2}, lock = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for joystick fire button',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fire" ].value
		},
	},
	{
		label: "JoyKey",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JoyKey',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Associates keystroke with joystick action',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "joykey" ].value
		},
	},
	{
		label: "Gamepad Threshold",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Gamepad Threshold()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get threshold to trigger up/down/left/right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_threshold" ].value
		},
	},
	{
		label: "Gamepad Threshold",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gamepad Threshold THRESHOLD = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set threshold to trigger up/down/left/right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_threshold" ].value
		},
	},
	{
		label: "Gamepad Button",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Gamepad Button( GAMEPAD = ${2:value2}, BUTTON = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for a button depressed on a complex gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_button" ].value
		},
	},
	{
		label: "Gamepad Buttons",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Gamepad Buttons( gamepad = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return number of selected gamepads',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_buttons" ].value
		},
	},
	{
		label: "Gamepad Axes",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Gamepad Axes( GAMEPAD = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return number of axes for selected gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_axes" ].value
		},
	},
	{
		label: "Gamepad Axis",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Gamepad Axis( GAMEPAD = ${2:value2}, AXIS = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of one of the axes of a gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_axis" ].value
		},
	},
	{
		label: "Gamepad trigger",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Gamepad trigger( GAMEPAD = ${2:value2}, TRIGGER = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of one of the triggers of a gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_trigger" ].value
		},
	},
	{
		label: "Gamepad Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Gamepad Name$( PORT = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the brand name of the gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_name$" ].value
		},
	},
	{
		label: "Gamepad Vendor$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Gamepad Vendor$( GAMEPAD = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the vendor code for the specified gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_vendor$" ].value
		},
	},
	{
		label: "Gamepad Product$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Gamepad Product$( GAMEPAD = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the product code for the specified gamepad',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_product$" ].value
		},
	},
	{
		label: "Gamepad Connected",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Gamepad Connected( GAMEPAD = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test if a gamepad is connected to the computer',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_connected" ].value
		},
	},
	{
		label: "Gamepad Map Buttons",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gamepad Map Buttons GAMEPAD = ${1:value1}, MAPS = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new mapping for the buttons of non-standard compliant gamepads. Doc to come.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_map_buttons" ].value
		},
	},
	{
		label: "Gamepad Map Axes",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gamepad Map Axes GAMEPAD = ${1:value1}, MAPS = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new mapping for the axes of non-standard compliant gamepads. Doc to come.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_map_axes" ].value
		},
	},
	{
		label: "Gamepad Map Triggers",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gamepad Map Triggers GAMEPAD = ${1:value1}, MAPS = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new mapping for the axes of non-standard compliant gamepads. Doc to come.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gamepad_map_triggers" ].value
		},
	},
	{
		label: "DB",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Context = ${1:value1}, DB$ = "${2:value2}", Share$ = "${3:value3}", Table$ = "${4:value4}", New = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switches to DB context. You can immediately execute some DB actions too.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db" ].value
		},
	},
	{
		label: "DB Database",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Database DB$ = "${1:value1}", Share$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the database that will the current one for the next DB calls',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_database" ].value
		},
	},
	{
		label: "DB Delete Column",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Delete Column Columns$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deletes a column and all the associated data from the current table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_delete_column" ].value
		},
	},
	{
		label: "DB Delete Database",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Delete Database DB$ = "${1:value1}", Share$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deletes a database',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_delete_database" ].value
		},
	},
	{
		label: "DB Delete Record",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Delete Record',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deletes the currently selected record and all the associated data from the current table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_delete_record" ].value
		},
	},
	{
		label: "DB Delete Table",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Delete Table Table$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deletes a table from the current database',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_delete_table" ].value
		},
	},
	{
		label: "DB First",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB First',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the first record among those found thanks to the last call to DB Search and its variants',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_first" ].value
		},
	},
	{
		label: "DB Last",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Last',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the last record among those found thanks to the last call to DB Search',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_last" ].value
		},
	},
	{
		label: "DB New",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB New',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects a new record',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_new" ].value
		},
	},
	{
		label: "DB Next",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Next',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the next record among those found thanks to the last call to DB Search',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_next" ].value
		},
	},
	{
		label: "DB Point On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Point On Id = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects a record to which apply DB Read, DB Write or DB Delete instructions',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_point_on" ].value
		},
	},
	{
		label: "DB Pointer",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = DB Pointer()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the identifier representing the pointer to the currently selected record',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_pointer" ].value
		},
	},
	{
		label: "DB Previous",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Previous',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the previous record among those found thanks to the last call to DB Search',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_previous" ].value
		},
	},
	{
		label: "DB Read",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Read Columns$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reads some column values from the currently selected record and store them into matching variables',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_read" ].value
		},
	},
	{
		label: "DB Rename Column",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Rename Column Column$ = "${1:value1}", NewName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Give a new name to a column into the current table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_rename_column" ].value
		},
	},
	{
		label: "DB Rename Database",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Rename Database NewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Give a new name to the current database',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_rename_database" ].value
		},
	},
	{
		label: "DB Rename Table",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Rename Table NewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Give a new name to the current table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_rename_table" ].value
		},
	},
	{
		label: "DB Search",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Search Search$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Search records into the current table that match the simplified search expression',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_search" ].value
		},
	},
	{
		label: "DB Search All",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Search All',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Search all records of the current table, without any filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_search_all" ].value
		},
	},
	{
		label: "DB Search Sql",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Search Sql Search$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Search records into the current table that match the SQL search expression',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_search_sql" ].value
		},
	},
	{
		label: "DB Sql",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Sql Query$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Executes a SQL query',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_sql" ].value
		},
	},
	{
		label: "DB Table",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Table Table$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects the table from current database that will the current one for the next DB calls',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_table" ].value
		},
	},
	{
		label: "DB Write",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Write Columns$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Writes the values of the listed columns from the program variables to the currently selected record of the current table',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_write" ].value
		},
	},
	{
		label: "DB Write New",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Write New Columns$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'DB Write New is a shortcut for DB New',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_write_new" ].value
		},
	},
	{
		label: "Dialog Box",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Box interface$ = "${1:value1}", value = ${2:value2}, parameters$ = "${3:value3}", x = ${4:value4}, y = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display dialogue box on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_box" ].value
		},
	},
	{
		label: "Dialog Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Open channel = ${1:value1}, interface$ = "${2:value2}", nVar = ${3:value3}, buffer = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display dialogue box on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_open" ].value
		},
	},
	{
		label: "Dialog Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Close channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display dialogue box on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_close" ].value
		},
	},
	{
		label: "Dialog Run",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Dialog Run( channel = ${2:value2}, label = ${3:value3}, x = ${4:value4}, y = ${5:value5})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'TODO! Display dialogue box on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_run" ].value
		},
	},
	{
		label: "Dialog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Dialog( channel = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return status of an open dialogue box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog" ].value
		},
	},
	{
		label: "VDialog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = VDialog( channel = ${2:value2}, variableIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved variable. Assign or read an Interface value',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vdialog" ].value
		},
	},
	{
		label: "VDialog$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = VDialog$( channel = ${2:value2}, variableIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved variable. Assign or read an Interface string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vdialog$" ].value
		},
	},
	{
		label: "RDialog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = RDialog( channel = ${2:value2}, button = ${3:value3}, object = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read the status of a zone or button',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rdialog" ].value
		},
	},
	{
		label: "RDialog$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = RDialog$( channel = ${2:value2}, button = ${3:value3}, object = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read the status of a zone or button',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rdialog$" ].value
		},
	},
	{
		label: "EDialog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = EDialog()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Find an error in an Interface program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "edialog" ].value
		},
	},
	{
		label: "Dialog Clr",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Clr channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear a dialogue box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_clr" ].value
		},
	},
	{
		label: "Dialog Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Update channel = ${1:value1}, zone1 = ${2:value2}, zone2 = ${3:value3}, zoneXXX = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update a zone of a dialog box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_update" ].value
		},
	},
	{
		label: "Dialog Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Freeze channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop dialogue channel input',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_freeze" ].value
		},
	},
	{
		label: "Dialog Unfreeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dialog Unfreeze channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restart dialogue channel input',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dialog_unfreeze" ].value
		},
	},
	{
		label: "Resource Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resource Bank bankIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Select a bank to be used for resources',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resource_bank" ].value
		},
	},
	{
		label: "Resource$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Resource$( messageIndex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a message from the Resource Bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resource$" ].value
		},
	},
	{
		label: "Resource Screen Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resource Screen Open number = ${1:value1}, width = ${2:value2}, height = ${3:value3}, flash = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a screen using the resource settings',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resource_screen_open" ].value
		},
	},
	{
		label: "Resource Unpack",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resource Unpack number = ${1:value1}, x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Unpack an image from the Resource Bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resource_unpack" ].value
		},
	},
	{
		label: "ZDialog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ZDialog( channel = ${2:value2}, param1 = ${3:value3}, param2 = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Z position of a dialog (?)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zdialog" ].value
		},
	},
	{
		label: "Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Freeze',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Prevent the display to be updated while keeping the application running in the back',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "freeze" ].value
		},
	},
	{
		label: "Unfreeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unfreeze',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restore display update after "Freeze" has been used.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unfreeze" ].value
		},
	},
	{
		label: "View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'View',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'If "Auto View Off" has been used, update the display immediately',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view" ].value
		},
	},
	{
		label: "Auto View Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Auto View Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle viewing mode off',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "auto_view_off" ].value
		},
	},
	{
		label: "Auto View On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Auto View On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle viewing mode on',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "auto_view_on" ].value
		},
	},
	{
		label: "Update Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Update Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn off the automatic Object re-drawing system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "update_off" ].value
		},
	},
	{
		label: "Update On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Update On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn on the automatic Object re-drawing system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "update_on" ].value
		},
	},
	{
		label: "Update Every",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Update Every delay = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Fix the rate of the automatic update system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "update_every" ].value
		},
	},
	{
		label: "Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Update',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Force an update of the display. Will only work in "Auto Mode Off" mode.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "update" ].value
		},
	},
	{
		label: "Display Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Display Width',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width of the display canvas in PC mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "display_width" ].value
		},
	},
	{
		label: "Display Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Display Height',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width of the display canvas in PC mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "display_height" ].value
		},
	},
	{
		label: "Set Display",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Display width = ${1:value1}, height = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the resolution of the display in pixels.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_display" ].value
		},
	},
	{
		label: "Draw Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Image image = ${1:value1}, x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an image loaded with "Load Asset" command, in the current screen, with a specific size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_image" ].value
		},
	},
	{
		label: "Audio Loop On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Audio Loop On audio = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat the playing at the end of audio.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "audio_loop_on" ].value
		},
	},
	{
		label: "Audio Loop Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Audio Loop Off audio = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop the repeat the playing at the end of audio.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "audio_loop_off" ].value
		},
	},
	{
		label: "Play Audio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Play Audio audio = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play an audio file loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "play_audio" ].value
		},
	},
	{
		label: "Stop Audio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Stop Audio audio = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop an audio loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "stop_audio" ].value
		},
	},
	{
		label: "Pause Audio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pause Audio audio = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause an audio loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pause_audio" ].value
		},
	},
	{
		label: "Volume Audio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Volume Audio audio = ${1:value1}, volume = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the volume of an audio loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "volume_audio" ].value
		},
	},
	{
		label: "Time Audio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Time Audio audio = ${1:value1}, time = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the position of an audio loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "time_audio" ].value
		},
	},
	{
		label: "Time Audio",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Time Audio( audio = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of audio',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "time_audio" ].value
		},
	},
	{
		label: "Video Loop On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Video Loop On video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat the playing at the end of video loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "video_loop_on" ].value
		},
	},
	{
		label: "Video Loop Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Video Loop Off video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop Repeat the playing at the end of video',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "video_loop_off" ].value
		},
	},
	{
		label: "Play Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Play Video video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a video loaded with the "Load Asset" command',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "play_video" ].value
		},
	},
	{
		label: "Stop Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Stop Video video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop a video loaded by the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "stop_video" ].value
		},
	},
	{
		label: "Pause Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pause Video video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause a video loaded with the "Load Asset" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pause_video" ].value
		},
	},
	{
		label: "Volume Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Volume Video video = ${1:value1}, volume = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the video volume',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "volume_video" ].value
		},
	},
	{
		label: "Time Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Time Video video = ${1:value1}, time = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the position of video in seconds',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "time_video" ].value
		},
	},
	{
		label: "Time Video",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Time Video( video = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of video in seconds',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "time_video" ].value
		},
	},
	{
		label: "Draw Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Video video = ${1:value1}, x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a video loaded with the "Load Asset" command on the current screen with a specific size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_video" ].value
		},
	},
	{
		label: "Video Bob",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Video Bob videon = ${1:value1}, bobindex = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign a video loaded with the "Load Asset" command as a Bob Image',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "video_bob" ].value
		},
	},
	{
		label: "Show Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Show Video video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show the video in fullscreen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "show_video" ].value
		},
	},
	{
		label: "Hide Video",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hide Video video = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the video',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hide_video" ].value
		},
	},
	{
		label: "Load Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Text path$ = "${1:value1}", string$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load an ascii file into a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_text" ].value
		},
	},
	{
		label: "Save Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save Text path$ = "${1:value1}", string$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save the content of a string as an ASCII file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_text" ].value
		},
	},
	{
		label: "Load Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Image path$ = "${1:value1}", ${2:index}, tags$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load an image into a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_image" ].value
		},
	},
	{
		label: "Save Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save Image path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save an image out of a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_image" ].value
		},
	},
	{
		label: "Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a previously saved memory bank, or a set of banks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load" ].value
		},
	},
	{
		label: "BLoad",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BLoad path$ = "${1:value1}", address = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a binary file into a bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bload" ].value
		},
	},
	{
		label: "BSave",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BSave path$ = "${1:value1}", start = ${2:value2}, end = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'BSave, short for Binary Save, saves the binary content of a block of memory. This instruction is normally used for saving the content of memory banks.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bsave" ].value
		},
	},
	{
		label: "Save",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Saves anything to a file, using the most appropriate format, based on the file extension',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save" ].value
		},
	},
	{
		label: "Save Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save Bank path$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save a memory bank to a file. This file can later be loaded with "Load Bank"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_bank" ].value
		},
	},
	{
		label: "DFree",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = DFree()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the amount of free space of the disc pointed to by the current path',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dfree" ].value
		},
	},
	{
		label: "MkDir",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'MkDir path$ = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Creates a new directory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mkdir" ].value
		},
	},
	{
		label: "Open Random",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open Random channel = ${1:value1}, path$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a random access file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_random" ].value
		},
	},
	{
		label: "Open In",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open In channel = ${1:value1}, path$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a file for input only',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_in" ].value
		},
	},
	{
		label: "Open Out",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open Out channel = ${1:value1}, path$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a file for ouput only. The file is replaced by the new one.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_out" ].value
		},
	},
	{
		label: "Append",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Append channel = ${1:value1}, path$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a file for output, and add content at the end of it',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "append" ].value
		},
	},
	{
		label: "Assign",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Assign name$ = "${1:value1}", path$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign a name to a file or device',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "assign" ].value
		},
	},
	{
		label: "Field$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Field$ channel = ${1:value1}, length = ${2:value2}, field$ = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a record structure for a random access file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "field$" ].value
		},
	},
	{
		label: "Put",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Put channel = ${1:value1}, recordNumber = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Output a record to a random access file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "put" ].value
		},
	},
	{
		label: "Get",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get channel = ${1:value1}, recordNumber = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a record from a random access file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get" ].value
		},
	},
	{
		label: "LOF",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LOF channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the length of a "Out", "In" or "Random access" channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lof" ].value
		},
	},
	{
		label: "EOF",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = EOF( channel = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if the file pointer of a channel is located at the end of the file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "eof" ].value
		},
	},
	{
		label: "POF",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'POF channel = ${1:value1}, position = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the position of the file pointer in an open channel (type must be "Out" ort "Append")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pof" ].value
		},
	},
	{
		label: "POF",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = POF( channel = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of the file pointer in an open channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pof" ].value
		},
	},
	{
		label: "Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Close channel = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close one or all opened files, and in case of output, save the buffered data into it',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "close" ].value
		},
	},
	{
		label: "Parent",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Parent',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the current directory to the parent directory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "parent" ].value
		},
	},
	{
		label: "Rename",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rename path$ = "${1:value1}", newName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rename a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rename" ].value
		},
	},
	{
		label: "Kill",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Kill path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "kill" ].value
		},
	},
	{
		label: "Load Variables",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Variables path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read the content of variables from a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_variables" ].value
		},
	},
	{
		label: "Save Variables",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save Variables path$ = "${1:value1}", any = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'save the content of variables or arrays into a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_variables" ].value
		},
	},
	{
		label: "Drive First$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Drive First$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the name of the first available drive on the machine.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "drive_first$" ].value
		},
	},
	{
		label: "Drive Next$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Drive Next$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the name of the next available drive on the machine.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "drive_next$" ].value
		},
	},
	{
		label: "FSel$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = FSel$( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a file selector and return the name of the selected file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fsel$" ].value
		},
	},
	{
		label: "Dir First$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dir First$( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'List the indicated path internally and return the first file in the list',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dir_first$" ].value
		},
	},
	{
		label: "Dir Next$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dir Next$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the next file in the list generated by Dir First$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dir_next$" ].value
		},
	},
	{
		label: "Exist",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Exist( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if a file or directory exists on the disc',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "exist" ].value
		},
	},
	{
		label: "File Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = File Name$( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of a file, without extension',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "file_name$" ].value
		},
	},
	{
		label: "File Extension$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = File Extension$( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the extension of a path to a file, without the dot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "file_extension$" ].value
		},
	},
	{
		label: "File Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = File Length( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the length in bytes of a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "file_length" ].value
		},
	},
	{
		label: "Dir",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dir path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'List a directory in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dir" ].value
		},
	},
	{
		label: "Disc Info$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Disc Info$',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return information about a drive',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "disc_info$" ].value
		},
	},
	{
		label: "LDir",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LDir path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'TODO',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ldir" ].value
		},
	},
	{
		label: "Command Line$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Command Line$ command$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the parameters of the command that has been used to launch the application.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "command_line$" ].value
		},
	},
	{
		label: "Set Input",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Input char1 = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the end of line characters detected when you input from a random access file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_input" ].value
		},
	},
	{
		label: "Set Dir",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Dir',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the width of the Dir function results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_dir" ].value
		},
	},
	{
		label: "Dir$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dir$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved variable. Change current directory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dir$" ].value
		},
	},
	{
		label: "Firebase Init",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase Init apiKey$ = "${1:value1}", appId$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialise Firebase using your web app\'s Firebase configuration',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_init" ].value
		},
	},
	{
		label: "Firebase NewQuery$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase NewQuery$( collection$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a new Firestore database query',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_newquery$" ].value
		},
	},
	{
		label: "Firebase QueryDispose",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QueryDispose queryRef$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Removes a query and any retrieved collection documents from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querydispose" ].value
		},
	},
	{
		label: "Firebase QueryAddWhere",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QueryAddWhere queryRef$ = "${1:value1}", field$ = "${2:value2}", operator$ = "${3:value3}", value$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add a \'where\' clause to an existing query to filter the query results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_queryaddwhere" ].value
		},
	},
	{
		label: "Firebase QueryAddWhereValue",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QueryAddWhereValue queryRef$ = "${1:value1}", field$ = "${2:value2}", operator$ = "${3:value3}", value = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add a \'where\' clause to an existing query to filter the query results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_queryaddwherevalue" ].value
		},
	},
	{
		label: "Firebase QueryAddOrderBy",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QueryAddOrderBy queryRef$ = "${1:value1}", field$ = "${2:value2}", direction$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add an \'order by\' clause to an existing query to order the query results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_queryaddorderby" ].value
		},
	},
	{
		label: "Firebase QueryGetDocumentID$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase QueryGetDocumentID$( queryRef$ = "${2:value2}", row = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Firebase collection Document ID for a specific row index in the query results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querygetdocumentid$" ].value
		},
	},
	{
		label: "Firebase QueryGetString$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase QueryGetString$( queryRef$ = "${2:value2}", docId$ = "${3:value3}", field$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a field from a specific document within a querys results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querygetstring$" ].value
		},
	},
	{
		label: "Firebase QueryGetDate$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase QueryGetDate$( queryRef$ = "${2:value2}", docId$ = "${3:value3}", field$ = "${4:value4}", format$ = "${5:value5}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a field from a specific document within a querys results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querygetdate$" ].value
		},
	},
	{
		label: "Firebase QueryGetValue",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Firebase QueryGetValue( queryRef$ = "${2:value2}", docId$ = "${3:value3}", field$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a field from a specific document within a querys results',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querygetvalue" ].value
		},
	},
	{
		label: "Firebase QueryGetDocumentCount",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Firebase QueryGetDocumentCount( queryRef$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of documents that have been retrieved with a Firebase query',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querygetdocumentcount" ].value
		},
	},
	{
		label: "Firebase QueryGet",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QueryGet queryRef$ = "${1:value1}", limit = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retrieve the documents from a Firestore collection query',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_queryget" ].value
		},
	},
	{
		label: "Firebase QuerySubscribe",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase QuerySubscribe queryRef$ = "${1:value1}", limit = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Listen to the results of a query and automatically receive updates when changes are detected',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_querysubscribe" ].value
		},
	},
	{
		label: "Firebase NewDocument$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase NewDocument$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a new Firestore document',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_newdocument$" ].value
		},
	},
	{
		label: "Firebase DocumentDispose",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentDispose docRef$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a document reference from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentdispose" ].value
		},
	},
	{
		label: "Firebase GetDocumentDate$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase GetDocumentDate$( docRef$ = "${2:value2}", field$ = "${3:value3}", format$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_getdocumentdate$" ].value
		},
	},
	{
		label: "Firebase GetDocumentString$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase GetDocumentString$( docRef$ = "${2:value2}", field$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_getdocumentstring$" ].value
		},
	},
	{
		label: "Firebase GetDocumentValue",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Firebase GetDocumentValue( docRef$ = "${2:value2}", field$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_getdocumentvalue" ].value
		},
	},
	{
		label: "Firebase SetDocumentString",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SetDocumentString docRef$ = "${1:value1}", field$ = "${2:value2}", value$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_setdocumentstring" ].value
		},
	},
	{
		label: "Firebase SetDocumentDate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SetDocumentDate docRef$ = "${1:value1}", field$ = "${2:value2}", value$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_setdocumentdate" ].value
		},
	},
	{
		label: "Firebase SetDocumentValue",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SetDocumentValue docRef$ = "${1:value1}", field$ = "${2:value2}", value = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of a document field',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_setdocumentvalue" ].value
		},
	},
	{
		label: "Firebase SetDocumentServerTimestamp",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SetDocumentServerTimestamp docRef$ = "${1:value1}", field$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of a document field to a server-generated timestamp',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_setdocumentservertimestamp" ].value
		},
	},
	{
		label: "Firebase SetDocumentIncrement",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SetDocumentIncrement docRef$ = "${1:value1}", field$ = "${2:value2}", value = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'A special token that tells the server to increment the field\'s current value by a given value',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_setdocumentincrement" ].value
		},
	},
	{
		label: "Firebase DocumentUpdate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentUpdate collection$ = "${1:value1}", docId$ = "${2:value2}", docRef$ = "${3:value3}", onCompleted$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update an existing document in a Firestore database collection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentupdate" ].value
		},
	},
	{
		label: "Firebase DocumentSet",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentSet collection$ = "${1:value1}", docId$ = "${2:value2}", docRef$ = "${3:value3}", onCompleted$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create or overwrite a single document in a Firestore database collection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentset" ].value
		},
	},
	{
		label: "Firebase DocumentAdd",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentAdd collection$ = "${1:value1}", docRef$ = "${2:value2}", onCompleted$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a single document in a Firestore database collection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentadd" ].value
		},
	},
	{
		label: "Firebase DocumentDelete",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentDelete collection$ = "${1:value1}", docId$ = "${2:value2}", onCompleted$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a single document in a Firestore database collection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentdelete" ].value
		},
	},
	{
		label: "Firebase DocumentGet",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentGet collection$ = "${1:value1}", docId$ = "${2:value2}", onData$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retrieve the contents of a single document from a Firestore database collection',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentget" ].value
		},
	},
	{
		label: "Firebase DocumentSubscribe",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DocumentSubscribe collection$ = "${1:value1}", docId$ = "${2:value2}", onData$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retrieve the contents of a single document and automatically receive updates when changes are detected',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_documentsubscribe" ].value
		},
	},
	{
		label: "Firebase UploadFile",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase UploadFile fileRef$ = "${1:value1}", storagePath$ = "${2:value2}", onCompleted$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Upload a file to your Firebase Storage bucket',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_uploadfile" ].value
		},
	},
	{
		label: "Firebase FileDispose",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase FileDispose fileRef$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a file reference from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_filedispose" ].value
		},
	},
	{
		label: "Firebase ReadFileImageDataUrl",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase ReadFileImageDataUrl fileRef$ = "${1:value1}", onRead$ = "${2:value2}", maxSize = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns an image file as a Data URL string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_readfileimagedataurl" ].value
		},
	},
	{
		label: "Firebase DeleteFile",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase DeleteFile storagePath$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a file from your Firebase Storage bucket',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_deletefile" ].value
		},
	},
	{
		label: "Firebase GetFileMetaData",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase GetFileMetaData storagePath$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Gets the list of available meta data for a file stored in your Firebase Storage bucket',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_getfilemetadata" ].value
		},
	},
	{
		label: "FirebaseAuth SignIn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignIn email$ = "${1:value1}", password$ = "${2:value2}", onCompleted$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users with an email address and password',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signin" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithGoogle",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithGoogle onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their Google account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithgoogle" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithFaceBook",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithFaceBook onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their Facebook account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithfacebook" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithApple",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithApple onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their Apple account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithapple" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithTwitter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithTwitter onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their Twitter account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithtwitter" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithGitHub",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithGitHub onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their GitHub account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithgithub" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithMicrosoft",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithMicrosoft onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users using their Microsoft account',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithmicrosoft" ].value
		},
	},
	{
		label: "FirebaseAuth SignInAnonymously",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInAnonymously onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Authenticate users anonymously',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinanonymously" ].value
		},
	},
	{
		label: "FirebaseAuth SignInWithBuiltInUI",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignInWithBuiltInUI signInFlow$ = "${1:value1}", provider$ = "${2:value2}", class$ = "${3:value3}", termsOfServiceUrl$ = "${4:value4}", privacyPolicyUrl$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'A prebuilt user interface to add a complete signin system to your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signinwithbuiltinui" ].value
		},
	},
	{
		label: "FirebaseAuth SignOut",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SignOut',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sign the authenticated user out of your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_signout" ].value
		},
	},
	{
		label: "FirebaseAuth HideBuiltInUI",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth HideBuiltInUI',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the user interface that is shown with the FirebaseAuth SignInWithBuiltInUI instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_hidebuiltinui" ].value
		},
	},
	{
		label: "FirebaseAuth AuthState",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = FirebaseAuth AuthState()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the current authentication state of the Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_authstate" ].value
		},
	},
	{
		label: "FirebaseAuth UserID$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = FirebaseAuth UserID$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the unique identifier for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_userid$" ].value
		},
	},
	{
		label: "FirebaseAuth UserDisplayName$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = FirebaseAuth UserDisplayName$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the Display Name for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_userdisplayname$" ].value
		},
	},
	{
		label: "FirebaseAuth UserEmail$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = FirebaseAuth UserEmail$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the Email address for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_useremail$" ].value
		},
	},
	{
		label: "FirebaseAuth UserPhotoUrl$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = FirebaseAuth UserPhotoUrl$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the users photo Url for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_userphotourl$" ].value
		},
	},
	{
		label: "FirebaseAuth UserEmailVerified",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = FirebaseAuth UserEmailVerified()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check if the user currently signed into your Firebase web application has been verified',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_useremailverified" ].value
		},
	},
	{
		label: "FirebaseAuth CreateUser",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth CreateUser displayName$ = "${1:value1}", email$ = "${2:value2}", password$ = "${3:value3}", onCompleted$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add a new user account to your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_createuser" ].value
		},
	},
	{
		label: "FirebaseAuth UpdateDisplayName",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth UpdateDisplayName displayName$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update the display name for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_updatedisplayname" ].value
		},
	},
	{
		label: "FirebaseAuth UpdatePhotoUrl",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth UpdatePhotoUrl photoUrl$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update the photo Url for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_updatephotourl" ].value
		},
	},
	{
		label: "FirebaseAuth UpdateEmail",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth UpdateEmail email$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update the email addres for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_updateemail" ].value
		},
	},
	{
		label: "FirebaseAuth UpdatePassword",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth UpdatePassword password$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update the password for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_updatepassword" ].value
		},
	},
	{
		label: "FirebaseAuth SendPasswordResetEmail",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SendPasswordResetEmail email$ = "${1:value1}", onCompleted$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send an email with a link to reset a users password',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_sendpasswordresetemail" ].value
		},
	},
	{
		label: "FirebaseAuth SendEmailVerification",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth SendEmailVerification onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send an email with a link to verify the email address for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_sendemailverification" ].value
		},
	},
	{
		label: "FirebaseAuth DeleteUser",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'FirebaseAuth DeleteUser onCompleted$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete the account for the user currently signed into your Firebase web application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebaseauth_deleteuser" ].value
		},
	},
	{
		label: "Firebase IsBusy",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Firebase IsBusy()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check if Firebase is busy communicating with the server',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_isbusy" ].value
		},
	},
	{
		label: "Firebase FormatDate$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase FormatDate$( dateTime$ = "${2:value2}", format$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Format a date to a desired format',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_formatdate$" ].value
		},
	},
	{
		label: "Firebase DateNow$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Firebase DateNow$( format$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the current date and time of the local device',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_datenow$" ].value
		},
	},
	{
		label: "Firebase DateDiff",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Firebase DateDiff( startDate$ = "${2:value2}", endDate$ = "${3:value3}", measurement$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Compare the difference between 2 date time strings',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_datediff" ].value
		},
	},
	{
		label: "Firebase SelectFile",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Firebase SelectFile onSelected$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a file selector to allow a single file to be selected',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "firebase_selectfile" ].value
		},
	},
	{
		label: "Get Font Number",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Get Font Number()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of fonts available to the application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_font_number" ].value
		},
	},
	{
		label: "Set Font",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Font font = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Select font for use by Text command in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_font" ].value
		},
	},
	{
		label: "Font$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Font$ fontname = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return details of available fonts',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "font$" ].value
		},
	},
	{
		label: "Set Bitmap Font",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Bitmap Font ${1:index}, Image = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set a bitmapped font from an image of the images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_bitmap_font" ].value
		},
	},
	{
		label: "Bitmap Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bitmap Text ${1:index}, Text$ = "${2:value2}", FontStyle = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a text on the currentscreen with a bitmapped font store in the images bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bitmap_text" ].value
		},
	},
	{
		label: "Get Block",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Block ${1:index}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the graphics from a screen into a buffer.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_block" ].value
		},
	},
	{
		label: "Set Block Alpha",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Block Alpha ${1:index}, alpha = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the transparency alpha of a block, that will be applied the next time the block is drawn in one screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_block_alpha" ].value
		},
	},
	{
		label: "Put Block",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Put Block ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a previously captured block in the current screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "put_block" ].value
		},
	},
	{
		label: "Del Block",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Block',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys an existing Block and free memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_block" ].value
		},
	},
	{
		label: "Hrev Block",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hrev Block',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Flip a block horzontally',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hrev_block" ].value
		},
	},
	{
		label: "Vrev Block",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Vrev Block',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Flip a block vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vrev_block" ].value
		},
	},
	{
		label: "Cls",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Cls ink = ${1:value1}, x1 = ${2:value2}, y2 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear an area of the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cls" ].value
		},
	},
	{
		label: "Plot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Plot x = ${1:value1}, y = ${2:value2}, ink = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a pixel in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "plot" ].value
		},
	},
	{
		label: "Point",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Point( x = ${2:value2}, y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the palette index of a point within the current screen (warning',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "point" ].value
		},
	},
	{
		label: "Point RGB",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Point RGB( x = ${2:value2}, y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the true colour RGB value of a point within the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "point_rgb" ].value
		},
	},
	{
		label: "Point RGBA",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Point RGBA( x = ${2:value2}, y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the true colour RGBA value of a point within the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "point_rgba" ].value
		},
	},
	{
		label: "Line Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Width width = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the width of the lines used in line drawing operations',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_width" ].value
		},
	},
	{
		label: "Line Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Width',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Line Width setting for the current screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_width" ].value
		},
	},
	{
		label: "Line Cap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Cap cap$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the \'capping\' style of lines drawn using Draw, Draw To, Arc, Ellipse Arc and Polyline',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_cap" ].value
		},
	},
	{
		label: "Line Join",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Join join$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the \'join\' style of lines drawn using Draw, Draw To, Arc, Ellipse Arc, Polyline and all the lines that make up polygonal shapes.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_join" ].value
		},
	},
	{
		label: "Border First",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border First onOff = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw the border first on outlined shapes or text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_first" ].value
		},
	},
	{
		label: "Border After",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border After',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw the border after on outlined shapes or text (default method).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_after" ].value
		},
	},
	{
		label: "Draw",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw x = ${1:value1}, y = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a line with the current Ink from the last graphical position',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw" ].value
		},
	},
	{
		label: "Curve",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Curve',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a curved line from one pair of coordinates to another using control points.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "curve" ].value
		},
	},
	{
		label: "Ellipse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ellipse x = ${1:value1}, y = ${2:value2}, xRadius = ${3:value3}, yradius = ${4:value4}, rotation = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an ellipse with the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ellipse" ].value
		},
	},
	{
		label: "Filled Ellipse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Ellipse x = ${1:value1}, y = ${2:value2}, xRadius = ${3:value3}, yradius = ${4:value4}, rotation = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an filled ellipse with the current Ink and pattern definitions in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_ellipse" ].value
		},
	},
	{
		label: "Circle",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Circle x = ${1:value1}, y = ${2:value2}, radius = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a circle with the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "circle" ].value
		},
	},
	{
		label: "Shape",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shape x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, sides = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a regular polygon with a specified number of sides using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shape" ].value
		},
	},
	{
		label: "Filled Shape",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Shape x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, sides = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a filled regular polygon with a specified number of sides using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_shape" ].value
		},
	},
	{
		label: "Star",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Star x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, rotation = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a star shape with a specified number of points using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "star" ].value
		},
	},
	{
		label: "Filled Star",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Star x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, rotation = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a filled star shape with a specified number of points using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_star" ].value
		},
	},
	{
		label: "Arc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Arc x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, angle1 = ${4:value4}, angle2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an arc of a circle between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "arc" ].value
		},
	},
	{
		label: "Ellipse Arc x, y, radius1, radius2, angle1, angle2, rotation, fill, counterclockwise",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ellipse Arc x, y, radius1, radius2, angle1, angle2, rotation, fill, counterclockwise x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, angle1 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an arc of an ellipse between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ellipse_arc_x,_y,_radius1,_radius2,_angle1,_angle2,_rotation,_fill,_counterclockwise" ].value
		},
	},
	{
		label: "Segment x, y, radius1, radius2, radius3, angle1, angle2, rotation, fill, counterclockwise",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Segment x, y, radius1, radius2, radius3, angle1, angle2, rotation, fill, counterclockwise x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, radius3 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a segment of a cricle or ellipse between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "segment_x,_y,_radius1,_radius2,_radius3,_angle1,_angle2,_rotation,_fill,_counterclockwise" ].value
		},
	},
	{
		label: "Filled Segment",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Segment x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, radius3 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a segment of a cricle or ellipse between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_segment" ].value
		},
	},
	{
		label: "Filled Arc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Arc x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, angle1 = ${4:value4}, angle2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an arc of a circle between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_arc" ].value
		},
	},
	{
		label: "Filled Ellipse Arc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Ellipse Arc x = ${1:value1}, y = ${2:value2}, radius1 = ${3:value3}, radius2 = ${4:value4}, angle1 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a filled arc of an ellipse between 2 angles using the current Ink in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_ellipse_arc" ].value
		},
	},
	{
		label: "Disc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Disc x = ${1:value1}, y = ${2:value2}, radius = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a filled circle with the current Ink and Pattern definitions in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "disc" ].value
		},
	},
	{
		label: "Bar",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bar x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a filled rectangle with the current Ink, Pattern in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bar" ].value
		},
	},
	{
		label: "Filled Round Box x1, y1, width, height, radius",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Filled Round Box x1, y1, width, height, radius x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, width = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws a filled rectangle with rounded corners using the current Ink, fill Pattern and Border settings (if applicable) on the current screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "filled_round_box_x1,_y1,_width,_height,_radius" ].value
		},
	},
	{
		label: "Box",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Box x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws an empty rectangle with the current Ink, Line width and Line Pattern in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "box" ].value
		},
	},
	{
		label: "Round Box x1, y1, width, height, radius, fill",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Round Box x1, y1, width, height, radius, fill x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, width = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws a rectangle with rounded corners using the current Ink and Border settings (if applicable) on the current screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "round_box_x1,_y1,_width,_height,_radius,_fill" ].value
		},
	},
	{
		label: "Paint",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Paint x = ${1:value1}, y = ${2:value2}, color = ${3:value3}, border = ${4:value4}, mode = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Performs a flood-paint of a closed area in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paint" ].value
		},
	},
	{
		label: "Polyline",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Polyline x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, x3 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a closed empty shape using the current Ink and line parameters for the outline adn the current fill parameters for the inside. The last point is joined to the first point of the list',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "polyline" ].value
		},
	},
	{
		label: "Polygon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Polygon x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, x3 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a closed empty shape using the current Ink and line parameters for the outline and the current fill parameters for the inside. The last point is joined to the first point of the list',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "polygon" ].value
		},
	},
	{
		label: "Gr Locate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gr Locate x = ${1:value1}, y = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Position the graphical cursor in the current screen. Next instructions like "Draw To" (or any graphical instruction wheree the X and Y coordinates are ommited) will starrt from this position',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gr_locate" ].value
		},
	},
	{
		label: "Text Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Text Length( text$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Calculate the width in pixels of a string when draw inthe current screen with the current selected font and font size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_length" ].value
		},
	},
	{
		label: "Text Height",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Text Height()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the height of the current graphical font on the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_height" ].value
		},
	},
	{
		label: "Text Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Text Base()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position of the base line of a graphical text displayed on the current screen with the current font attributes name and size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_base" ].value
		},
	},
	{
		label: "Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Text x = ${1:value1}, y = ${2:value2}, text$ = "${3:value3}", tags$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a graphical text on the current screen using the current font and font size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text" ].value
		},
	},
	{
		label: "Centre Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Centre Text how = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Centers graphical text on the current screen using the current font and font size',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "centre_text" ].value
		},
	},
	{
		label: "Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Text',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Text (with NO parameters) performs the equivalent of CR LF for Text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text" ].value
		},
	},
	{
		label: "Text Shadow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Text Shadow offsetX = ${1:value1}, offsetY = ${2:value2}, blur = ${3:value3}, rgba = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a shadow beneath any graphical text characters.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_shadow" ].value
		},
	},
	{
		label: "Text Shadow Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Text Shadow Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the Text Shadow effect.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_shadow_off" ].value
		},
	},
	{
		label: "Draw Shadow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Shadow offsetX = ${1:value1}, offsetY = ${2:value2}, blur = ${3:value3}, rgba = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a shadow beneath any graphics drawn with Plot, Draw, Circle, Ellipse, Shape, Box, Star etc.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_shadow" ].value
		},
	},
	{
		label: "Draw Shadow Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Shadow Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the Draw Shadow effect.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_shadow_off" ].value
		},
	},
	{
		label: "Text Mode",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Text Mode Mode$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the Text drawing mode to draw either solid colour text, outline text or bordered text. Text Mode is "fill" by default.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_mode" ].value
		},
	},
	{
		label: "Format Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Format Text text$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, tags$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw a graphical text within a given area of the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "format_text" ].value
		},
	},
	{
		label: "Get Text Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Text Height text$ = "${1:value1}", width = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the computed height of the text box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_text_height" ].value
		},
	},
	{
		label: "Animate Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Animate Text',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Handle click on links in formatted text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "animate_text" ].value
		},
	},
	{
		label: "Animate Text On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Animate Text On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restart animated text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "animate_text_on" ].value
		},
	},
	{
		label: "Animate Text Pause",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Animate Text Pause',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses animated text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "animate_text_pause" ].value
		},
	},
	{
		label: "Animate Text Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Animate Text Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops animated text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "animate_text_off" ].value
		},
	},
	{
		label: "Set Paint",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Paint onOff = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the painting mode for filled drawing forms like "Polygon" and "Bar"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_paint" ].value
		},
	},
	{
		label: "Border On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate the drawing of borders around shapes.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_on" ].value
		},
	},
	{
		label: "Border Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'De-Activate the drawing of borders around shapes.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_off" ].value
		},
	},
	{
		label: "Set Pattern",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Pattern pattern = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the filled area painting pattern',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_pattern" ].value
		},
	},
	{
		label: "Border Pattern",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Pattern image = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Select an pattern number to use as a fill image in graphical drawings.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_pattern" ].value
		},
	},
	{
		label: "Fill Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fill Image image = ${1:value1}, scale = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Select an image from the Image Bank to use as a fill image in graphical drawings.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fill_image" ].value
		},
	},
	{
		label: "Border Fill Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Fill Image image = ${1:value1}, scale = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Select an image from the Image Bank to use as a border fill image in graphical drawings.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_fill_image" ].value
		},
	},
	{
		label: "Linear Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Linear Gradient x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, color$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a linear gradient that will be used as a fill pattern.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "linear_gradient" ].value
		},
	},
	{
		label: "Conic Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Conic Gradient x = ${1:value1}, y = ${2:value2}, color$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a conic gradient that will be used as a fill pattern.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "conic_gradient" ].value
		},
	},
	{
		label: "Radial Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Radial Gradient x1 = ${1:value1}, y1 = ${2:value2}, r1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a radial gradient between a start circle and an end circle that will be used as a fill pattern.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "radial_gradient" ].value
		},
	},
	{
		label: "Conic Border Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Conic Border Gradient x = ${1:value1}, y = ${2:value2}, color$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a conic gradient that will be used as a fill pattern in any borders drawn around shapes or text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "conic_border_gradient" ].value
		},
	},
	{
		label: "Linear Border Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Linear Border Gradient x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, color$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a linear gradient that will be used as a fill pattern in any borders drawn around shapes or text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "linear_border_gradient" ].value
		},
	},
	{
		label: "Radial Border Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Radial Border Gradient x1 = ${1:value1}, y1 = ${2:value2}, r1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a radial gradient between a start circle and an end circle that will be used as a fill pattern in any borders drawn around shapes or text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "radial_border_gradient" ].value
		},
	},
	{
		label: "Gradient Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gradient Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off gradient fill and removes the pattern.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gradient_off" ].value
		},
	},
	{
		label: "Border Gradient Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Gradient Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off border gradient fill.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_gradient_off" ].value
		},
	},
	{
		label: "Set Line",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Line pattern = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the patterm of the future lines drawn by the "Draw", "Draw To", "Box" etc. and any Borders around Shapes or Text instructions',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_line" ].value
		},
	},
	{
		label: "Line Dash",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Dash dash1 = ${1:value1}, space1 = ${2:value2}, dash2 = ${3:value3}, space2 = ${4:value4}, dash3 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the patterm of the future lines drawn by the "Draw", "Draw To", "Box" etc. and any Borders around Shapes or Text instructions',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_dash" ].value
		},
	},
	{
		label: "Line Dash Offset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Dash Offset offset = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Applies an offset to the start position of your dashed line.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_dash_offset" ].value
		},
	},
	{
		label: "Ink",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ink ${1:index}, pattern = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the index of the colour from the palette of the current screen to use in all future graphical operations',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ink" ].value
		},
	},
	{
		label: "Border Ink",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Ink ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the colour of plain borders used in graphical operations.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_ink" ].value
		},
	},
	{
		label: "Ink",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Ink()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the graphic ink color index.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ink" ].value
		},
	},
	{
		label: "Ink$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Ink$( color = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a string to use in Text and Format Text instructions to change the color of the ink',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ink$" ].value
		},
	},
	{
		label: "Set Font$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Set Font$( font$ = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a string to use in Text and Format Text instructions to change the name of the font. Warning, for this instruction to work all fonts must have been previously loaded with a "Set Font" instruction.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_font$" ].value
		},
	},
	{
		label: "Set Font Size$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Set Font Size$( height = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a string to use in Text and Format Text instructions to change the height of the font.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_font_size$" ].value
		},
	},
	{
		label: "Italic$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Italic$( onOff = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a string to use in Text and Format Text instructions to set the font to italic',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "italic$" ].value
		},
	},
	{
		label: "Bold$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Bold$( onOff = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a string to use in Text and Format Text instructions to set the font to bold',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bold$" ].value
		},
	},
	{
		label: "Gr Writing",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gr Writing style = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set all graphical operations drawing mode. Warning work in progress incomplete support TODO!',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gr_writing" ].value
		},
	},
	{
		label: "Clip",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clip x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clip all further graphical operations in the current screen to a rectangle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clip" ].value
		},
	},
	{
		label: "Clip",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clip',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Removes the clipping rectangle and allow drawing on the whole surface of the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clip" ].value
		},
	},
	{
		label: "Set Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Text style = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the style used by Print, by selecting 0 or more of eight different styles (deprecated - use Set Print)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_text" ].value
		},
	},
	{
		label: "Set Print",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Print style = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the style of a font used by Print instructions by selecting one of eight different styles',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_print" ].value
		},
	},
	{
		label: "Text Styles",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Text Styles()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index reference of the text style you last selected using "Set Text"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "text_styles" ].value
		},
	},
	{
		label: "Paste Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Paste Image x = ${1:value1}, y = ${2:value2}, image = ${3:value3}, scaleX# = ${4:value4}, scaleY# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draws an image from the image bank in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paste_image" ].value
		},
	},
	{
		label: "Draw Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Filter filterName$ = "${1:value1}", value1# = ${2:value2}, value2# = ${3:value3}, value3# = ${4:value4}, value4# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to any drawing operations, including text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_filter" ].value
		},
	},
	{
		label: "Draw Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Draw Filter Del filterName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters applied to drawing operations.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_filter_del" ].value
		},
	},
	{
		label: "Draw Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Draw Filter$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_filter$" ].value
		},
	},
	{
		label: "Draw Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Draw Filter()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of the given filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "draw_filter" ].value
		},
	},
	{
		label: "Border Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Filter filterName$ = "${1:value1}", value1# = ${2:value2}, value2# = ${3:value3}, value3# = ${4:value4}, value4# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to any borders applied to either drawn shapes or text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_filter" ].value
		},
	},
	{
		label: "Border Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border Filter Del filterName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters applied to drawing operations.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_filter_del" ].value
		},
	},
	{
		label: "Border Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Border Filter$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_filter$" ].value
		},
	},
	{
		label: "Border Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Border Filter()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of the given filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border_filter" ].value
		},
	},
	{
		label: "Paste Icon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Paste Icon x = ${1:value1}, y = ${2:value2}, iconIndex = ${3:value3}, scaleX# = ${4:value4}, scaleY# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Draw an icon from the Icons bank into a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paste_icon" ].value
		},
	},
	{
		label: "Make Icon Mask",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Make Icon Mask iconIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a transparency mask out of the full black color of an image in Icons bank (RGB = $000000)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "make_icon_mask" ].value
		},
	},
	{
		label: "No Icon Mask",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'No Icon Mask iconIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Removes the transparency mask of an icon in the Icons bank, turning the icon fully opaque',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "no_icon_mask" ].value
		},
	},
	{
		label: "Ins Icon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Icon position = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts an empty icon in Icons bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_icon" ].value
		},
	},
	{
		label: "Del Icon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Icon',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an icon Icons bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_icon" ].value
		},
	},
	{
		label: "Get Icon",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Icon, iconIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the current screen and add the image to the Icons bank.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_icon" ].value
		},
	},
	{
		label: "Get Icon Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Icon Palette mask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy the colour palette from the Icons bank to the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_icon_palette" ].value
		},
	},
	{
		label: "Set Interval",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Set Interval( delay = ${2:value2}, procName$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a procedure all the N milliseconds, and return its ID',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_interval" ].value
		},
	},
	{
		label: "Clear Interval",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clear Interval id = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a process setted with the Set Interval function. The AOZ procedure associated will not called any more.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clear_interval" ].value
		},
	},
	{
		label: "Key Code Table",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Key Code Table',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key_code_table" ].value
		},
	},
	{
		label: "Inkey$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Inkey$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Checks to see if a key has been pressed, and reports back its value in a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "inkey$" ].value
		},
	},
	{
		label: "Input$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Input$( numChars = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Anticipate a number of characters to input into a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "input$" ].value
		},
	},
	{
		label: "Input",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Input variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'The INPUT command is used to enter information into one or more variables, separated by commas',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "input" ].value
		},
	},
	{
		label: "Line Input",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Line Input variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Input a list of variables separated by [Return]',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "line_input" ].value
		},
	},
	{
		label: "Wait Key",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wait Key',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application until the user presses a key',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wait_key" ].value
		},
	},
	{
		label: "Key Speed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Key Speed timeLag = ${1:value1}, delaySpeed = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change key repeat speed. Not implented in HTML applications, maybe later in executable applications',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key_speed" ].value
		},
	},
	{
		label: "Key State",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Key State( keyCode = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test for a specific key press',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key_state" ].value
		},
	},
	{
		label: "Key Shift",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Key Shift()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the state of the modifier keys',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key_shift" ].value
		},
	},
	{
		label: "Key Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Key Name$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Javascript name of the last pressed key',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key_name$" ].value
		},
	},
	{
		label: "ScanCode",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ScanCode()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the scancode of a key entered with INKEY$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scancode" ].value
		},
	},
	{
		label: "ScanShift",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ScanShift()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return shift status of key entered with INKEY$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scanshift" ].value
		},
	},
	{
		label: "Put Key",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Put Key text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a string of characters directly into the keyboard buffer, enabling you to simulate user typing (in INPUT functions for example)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "put_key" ].value
		},
	},
	{
		label: "Clear Key",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clear Key',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Re-set the keyboard buffer. Might not have an effect on all platforms.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clear_key" ].value
		},
	},
	{
		label: "Key$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Key$ macro$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved variable. Define a keyboard macro',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "key$" ].value
		},
	},
	{
		label: "Scan$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Scan$( scanCode = ${2:value2}, mask = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a scan-code for use with Key$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scan$" ].value
		},
	},
	{
		label: "Hide On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hide On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the mouse pointer (Deprecatred, use "Hide")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hide_on" ].value
		},
	},
	{
		label: "Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Hide',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the mouse pointer',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hide" ].value
		},
	},
	{
		label: "Show On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Show On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show the mouse pointer (Deprecated, use "Show")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "show_on" ].value
		},
	},
	{
		label: "Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Show',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show the mouse pointer',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "show" ].value
		},
	},
	{
		label: "Change Mouse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Change Mouse shape = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the shape of the pointer arrow',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "change_mouse" ].value
		},
	},
	{
		label: "Mouse Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Mouse Image URL$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the mouse pointer by an image',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_image" ].value
		},
	},
	{
		label: "X Mouse",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Mouse()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved Variable. Returns / Set the horizontal coordinate of the mouse. Note',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_mouse" ].value
		},
	},
	{
		label: "Y Mouse",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Mouse()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved Variable. Returns / Set the vertical coordinate of the mouse. Note',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_mouse" ].value
		},
	},
	{
		label: "Mouse Key",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouse Key()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the status of the mouse buttons',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_key" ].value
		},
	},
	{
		label: "Mouse Click",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouse Click()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check for click of mouse button.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_click" ].value
		},
	},
	{
		label: "Limit Mouse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Limit Mouse x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the shape of the pointer arrow',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "limit_mouse" ].value
		},
	},
	{
		label: "Limit Mouse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Limit Mouse',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Without parameters, restore the displacement of the mouse to the whole screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "limit_mouse" ].value
		},
	},
	{
		label: "Mouse Wheel",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouse Wheel()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the movement of the mouse wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_wheel" ].value
		},
	},
	{
		label: "Wait Click",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wait Click',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application until the user presses a mouse button or touches the screen (touch screen)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wait_click" ].value
		},
	},
	{
		label: "Wait Input",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wait Input',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application until the user presses a mouse button, touches the screen (touch screen) or press a key',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wait_input" ].value
		},
	},
	{
		label: "Fix",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fix number = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the number of decimal of floating point numbers to ASCII conversion (used in both Print and =Str$())',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fix" ].value
		},
	},
	{
		label: "Radian",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Radian',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the default angle representation in AOZ to Radians.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "radian" ].value
		},
	},
	{
		label: "Degree",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Degree',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the default angle representation in AOZ to degrees.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "degree" ].value
		},
	},
	{
		label: "Is Degree",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Is Degree',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns true If Degree is on.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "is_degree" ].value
		},
	},
	{
		label: "Is Radian",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Is Radian',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns true If Radian is on.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "is_radian" ].value
		},
	},
	{
		label: "Rad",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rad',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that converts an angle in radians to degrees.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rad" ].value
		},
	},
	{
		label: "Rnd (*floor* to *ceiling*)",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Rnd (*floor* to *ceiling*)()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a random number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rnd_(*floor*_to_*ceiling*)" ].value
		},
	},
	{
		label: "Randomize",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Randomize seed = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch the random number generator to Mersenne Twist and generate a new seed.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "randomize" ].value
		},
	},
	{
		label: "Sgn",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sgn( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the sign of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sgn" ].value
		},
	},
	{
		label: "Abs",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Abs( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the absolute value of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "abs" ].value
		},
	},
	{
		label: "Int",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Int( floatNumber = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the integer portion of the number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "int" ].value
		},
	},
	{
		label: "PI#",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = PI#()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of PI',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pi#" ].value
		},
	},
	{
		label: "Min",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Min( value1 = ${2:value2}, value2 = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the minimal value of two numbers',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "min" ].value
		},
	},
	{
		label: "Max",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Max( value1 = ${2:value2}, value2 = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the maximal value of two numbers',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "max" ].value
		},
	},
	{
		label: "Sin",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Sin( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Sine of an angle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sin" ].value
		},
	},
	{
		label: "Cos",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Cos( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Cosine of an angle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cos" ].value
		},
	},
	{
		label: "Tan",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Tan( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Tangent of an angle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tan" ].value
		},
	},
	{
		label: "ASin",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ASin( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Arc Sine of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "asin" ].value
		},
	},
	{
		label: "ACos",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ACos( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Arc Cosine of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "acos" ].value
		},
	},
	{
		label: "ATan",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ATan( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Arc Tangent of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "atan" ].value
		},
	},
	{
		label: "HSin",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = HSin( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Hyperbolic Sine of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hsin" ].value
		},
	},
	{
		label: "HCos",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = HCos( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Hyperbolic Cosine of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hcos" ].value
		},
	},
	{
		label: "HTan",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = HTan( angle = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Hyperbolic Tangent of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "htan" ].value
		},
	},
	{
		label: "Sqr",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Sqr( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the square root of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sqr" ].value
		},
	},
	{
		label: "Log",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Log( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the base 10 logarythm of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "log" ].value
		},
	},
	{
		label: "Ln",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Ln( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the Neperian Logarythm of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ln" ].value
		},
	},
	{
		label: "Exp",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Exp( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the exponantial of a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "exp" ].value
		},
	},
	{
		label: "Get Angle",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Get Angle( x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the angle of the line between two points measured clockwise from horizontal-right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_angle" ].value
		},
	},
	{
		label: "Get Distance",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Get Distance( x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Measures the distance between two points in pixels',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_distance" ].value
		},
	},
	{
		label: "Odd ( number )",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Odd ( number )()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if variable or expression is evaluated as an Odd number.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "odd_(_number_)" ].value
		},
	},
	{
		label: "Even ( number )",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Even ( number )()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if variable or expression  is evaluated as an Even number.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "even_(_number_)" ].value
		},
	},
	{
		label: "Mod",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Mod',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Operator. Calculates the modulo of two numbers',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mod" ].value
		},
	},
	{
		label: "Not",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Not',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Operator or function. Logical NOT, equivalent to ! in other languages',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "not" ].value
		},
	},
	{
		label: "Poke",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Poke address = ${1:value1}, value = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change a one-byte word at a memory address',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "poke" ].value
		},
	},
	{
		label: "Doke",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Doke address = ${1:value1}, value = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change a two-byte word at a memory address. Value is set in little-endian in PC mode and big-endian in Amiga mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "doke" ].value
		},
	},
	{
		label: "Loke",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Loke address = ${1:value1}, value = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change a four-byte word at a memory address. Value is set in little-endian in PC mode and big-endian in Amiga mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "loke" ].value
		},
	},
	{
		label: "Poke$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Poke$ address = ${1:value1}, text$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write the ascii values of a string in memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "poke$" ].value
		},
	},
	{
		label: "Peek$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Peek$( address = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a string from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "peek$" ].value
		},
	},
	{
		label: "Peek",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Peek( address = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'read a byte from an address',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "peek" ].value
		},
	},
	{
		label: "Deek",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Deek( address = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a two-bytes value from an address.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "deek" ].value
		},
	},
	{
		label: "Leek",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Leek( address = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a four-bytes value from an address. Value is read in little-endian in PC mode and big-endian in Amiga mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "leek" ].value
		},
	},
	{
		label: "Fill",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fill start = ${1:value1}, finish = ${2:value2}, pattern = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'fill memory block with the contents of a variable.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fill" ].value
		},
	},
	{
		label: "Copy",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Copy start = ${1:value1}, finish = ${2:value2}, destination = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy a memory block',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "copy" ].value
		},
	},
	{
		label: "Hunt",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Hunt( start = ${2:value2}, finish = ${3:value3}, text$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Find a string of characters in memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hunt" ].value
		},
	},
	{
		label: "BSet",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BSet variable = ${1:value1}, position = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set a bit of a variable to 1',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bset" ].value
		},
	},
	{
		label: "BClr",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BClr variable = ${1:value1}, position = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set a bit of a variable to 0',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bclr" ].value
		},
	},
	{
		label: "BChg",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BChg variable = ${1:value1}, postion = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle a bit of a variable',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bchg" ].value
		},
	},
	{
		label: "BTst",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BTst( position = ${2:value2}, value = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Test a bit in an interger number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "btst" ].value
		},
	},
	{
		label: "Ror.b",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ror.b number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the right on the first 8 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ror.b" ].value
		},
	},
	{
		label: "Ror.w",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ror.w number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the right on the first 16 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ror.w" ].value
		},
	},
	{
		label: "Ror.l",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ror.l number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the right on 32 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ror.l" ].value
		},
	},
	{
		label: "Rol.b",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rol.b number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the left on the first 8 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rol.b" ].value
		},
	},
	{
		label: "Rol.w",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rol.w number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the left on the first 16 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rol.w" ].value
		},
	},
	{
		label: "Rol.l",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rol.l number = ${1:value1}, variable = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the content of an integer variable to the left on 32 bits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rol.l" ].value
		},
	},
	{
		label: "On Menu Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu Del',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete labels and procedures used by ON MENU.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_del" ].value
		},
	},
	{
		label: "On Menu On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle automatic menu selection.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_on" ].value
		},
	},
	{
		label: "On Menu Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle automatic menu selection. Will be implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_off" ].value
		},
	},
	{
		label: "Menu To Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu To Bank bankIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save menu definitions into a memory bank. Will be implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_to_bank" ].value
		},
	},
	{
		label: "Bank To Menu",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bank To Menu bankIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restore a menu definition saved in a menu bank.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bank_to_menu" ].value
		},
	},
	{
		label: "Menu On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate a menu.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_on" ].value
		},
	},
	{
		label: "Menu OFF",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu OFF',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deactivate a menu. Will be implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_off" ].value
		},
	},
	{
		label: "Menu Mouse On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Mouse On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display the menu at position of mouse cursor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_mouse_on" ].value
		},
	},
	{
		label: "Menu Mouse Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Mouse Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop displayiong the menu at position of mouse cursor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_mouse_off" ].value
		},
	},
	{
		label: "Menu Key",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Key item = ${1:value1}, key = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign a key to a menu item.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_key" ].value
		},
	},
	{
		label: "Menu Bar",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Bar level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display menu items as a vertical bar.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_bar" ].value
		},
	},
	{
		label: "Menu Line",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Line level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display menu items as a horizontal bar.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_line" ].value
		},
	},
	{
		label: "Menu Inactive",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Inactive level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Deactivate a menu item',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_inactive" ].value
		},
	},
	{
		label: "Menu Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Menu Del level = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete one or more menu items.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu_del" ].value
		},
	},
	{
		label: "Menu$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Menu$( level = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'variable',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "menu$" ].value
		},
	},
	{
		label: "Choice",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Choice( level = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Read a menu item',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "choice" ].value
		},
	},
	{
		label: "On Menu Proc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu Proc proc = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Automatic menu selection.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_proc" ].value
		},
	},
	{
		label: "On Menu Gosub",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu Gosub label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Automatic menu',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_gosub" ].value
		},
	},
	{
		label: "On Menu Goto",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Menu Goto label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Automatic menu selection.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_menu_goto" ].value
		},
	},
	{
		label: "Player Initialise",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Initialise engine$ = "${1:value1}", key$ = "${2:value2}", onError$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialise the player engine and login.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_initialise" ].value
		},
	},
	{
		label: "Player Forget",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Forget',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Removes the stored player from the browser application storage and reinitialises the player engine with a new guest account.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_forget" ].value
		},
	},
	{
		label: "Player SendAccountRecoveryEmail",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player SendAccountRecoveryEmail email$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sends an email with instructions for recovering a player account. (If supported by the player engine)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_sendaccountrecoveryemail" ].value
		},
	},
	{
		label: "Player Register",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Register email$ = "${1:value1}", password$ = "${2:value2}", playerName$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Links a Guest player account to an email and password.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_register" ].value
		},
	},
	{
		label: "Player Login",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Login email$ = "${1:value1}", password$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Login to the player engine with an account that was registered using the "Player Register" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_login" ].value
		},
	},
	{
		label: "Player Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Update playerName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Update the player related account data such as the player name.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_update" ].value
		},
	},
	{
		label: "Player Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Player Name$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the player name for the currently logged in player account.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_name$" ].value
		},
	},
	{
		label: "Player IsGuest",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Player IsGuest()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines if the current player account is a guest account or has been registered/linked to an email and password.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_isguest" ].value
		},
	},
	{
		label: "Player Id$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Player Id$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the unique identifier for the current player account.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_id$" ].value
		},
	},
	{
		label: "Player ErrorCode",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Player ErrorCode()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the error code for the last Player instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_errorcode" ].value
		},
	},
	{
		label: "Player ErrorMessage$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Player ErrorMessage$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the error message for the last Player instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_errormessage$" ].value
		},
	},
	{
		label: "Leaderboard Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Leaderboard Load leaderboardName$ = "${1:value1}", maxItems = "${2:value2}", startPosition = "${3:value3}", onLoaded$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Loads and caches a leaderboards data',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "leaderboard_load" ].value
		},
	},
	{
		label: "Leaderboard$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Leaderboard$( itemIndex = ${2:value2}, itemName$ = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Provides access to the data items in a cached leaderboard.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "leaderboard$" ].value
		},
	},
	{
		label: "Leaderboard Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Leaderboard Update leaderboardName$ = "${1:value1}", value = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Updates or adds a players leaderboard entry with the given value.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "leaderboard_update" ].value
		},
	},
	{
		label: "Player Data$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Player Data$( itemName$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Gets the value of a data item saved using the "Player Data" instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_data$" ].value
		},
	},
	{
		label: "Player Data",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Player Data itemName$ = "${1:value1}", itemValue$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save an item of data against the current player account.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_data" ].value
		},
	},
	{
		label: "Player Engine$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Player Engine$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Gets the name of the engine that has been initialised',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "player_engine$" ].value
		},
	},
	{
		label: "Set Rainbow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Rainbow ${1:index}, ink = ${2:value2}, height = ${3:value3}, red$ = "${4:value4}", green$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new Rainbow effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_rainbow" ].value
		},
	},
	{
		label: "Rainbow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow ${1:index}, offset = ${2:value2}, position = ${3:value3}, height = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the display of a rainbow already create with "Set Rainbow"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow" ].value
		},
	},
	{
		label: "Linear Rainbow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Linear Rainbow ${1:index}, x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the display of a rainbow already created with "Set Rainbow" to Linear',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "linear_rainbow" ].value
		},
	},
	{
		label: "Linear Rainbow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Linear Rainbow ${1:index}, x = ${2:value2}, y = ${3:value3}, offset = ${4:value4}, angle# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the display of a rainbow already created with "Set Rainbow" as a Radial Rainbow',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "linear_rainbow" ].value
		},
	},
	{
		label: "Conic Rainbow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Conic Rainbow ${1:index}, x = ${2:value2}, y = ${3:value3}, offset = ${4:value4}, angle# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the display of a rainbow already created with "Set Rainbow" as a Conic Rainbow',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "conic_rainbow" ].value
		},
	},
	{
		label: "Rainbow To Screen",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow To Screen ${1:index}, screen = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Direct the display of a rainbow to a specific screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_to_screen" ].value
		},
	},
	{
		label: "Rainbow To Front",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow To Front ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Direct the display of a rainbow over all graphics elements',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_to_front" ].value
		},
	},
	{
		label: "Rainbow To Back",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow To Back ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Direct the display of a rainbow under all graphics elements',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_to_back" ].value
		},
	},
	{
		label: "Rainbow To Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow To Image ${1:index}, image = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Direct the display of a rainbow to an image of the image bank, allowing the rainbow to be displayed as a Bob',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_to_image" ].value
		},
	},
	{
		label: "Rainbow Gradient",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow Gradient ${1:index}, offset = ${2:value2}, height = ${3:value3}, noAlpha = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a string containing color stops and compatible with the Screen gradietn instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_gradient" ].value
		},
	},
	{
		label: "Rainbow Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rainbow Del ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys a Rainbow',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rainbow_del" ].value
		},
	},
	{
		label: "Screen Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Open ${1:index}, width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a new screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_open" ].value
		},
	},
	{
		label: "Screen Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys the current screen or a given screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_close" ].value
		},
	},
	{
		label: "Screen Clone",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Clone ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create an exact and synchronized copy of the current screen that can be displayed at another position and Z-order. Both screen share the same internal pixel buffers. Graphical operations are fobidden in the cloned screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_clone" ].value
		},
	},
	{
		label: "Screen Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Hide',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a screen disappear from display. The screen will remain active and drawing operation are still possible after this instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_hide" ].value
		},
	},
	{
		label: "Screen Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Show',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a hidden screen reappear on display',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_show" ].value
		},
	},
	{
		label: "Screen Display",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Display ${1:index}, x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the display position and width of a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_display" ].value
		},
	},
	{
		label: "Screen Center",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Center ${1:index}, centerX = ${2:value2}, centerY = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enforces the centering of a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_center" ].value
		},
	},
	{
		label: "Screen Offset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Offset ${1:index}, xOffset = ${2:value2}, yOffset = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the offet in the internal screen buffer of the top-left diplayed pixel, allowing scrollings',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_offset" ].value
		},
	},
	{
		label: "Screen To Front",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen To Front',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the display order of the screen, and passes the screen in front of all other screens',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_to_front" ].value
		},
	},
	{
		label: "Screen To Back",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen To Back',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the display order of the screen, and passes the screen behind all other screens',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_to_back" ].value
		},
	},
	{
		label: "Screen Hot Spot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Hot Spot ${1:index}, xSpot = ${2:value2}, ySpot = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the hot-spot of a screen to a given coordinate. The hot-spot is the position within the screen where the Screen Offset will have effect and around which rotation will be done',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_hot_spot" ].value
		},
	},
	{
		label: "Screen Hot Spot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Hot Spot, flags = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the hot spot of a screen to a given coordinate. The hot-spot is the position within the screen where the Screen Offset will have effect and around which rotation will be done',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_hot_spot" ].value
		},
	},
	{
		label: "Screen Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Rotate, angle = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate a screen around it\'s hot-spot on display. Warning, on software renderers this instruction will slow down the application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_rotate" ].value
		},
	},
	{
		label: "Screen Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Rotate angle = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotate the current screen around it\'s hot-spot on display.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_rotate" ].value
		},
	},
	{
		label: "Screen Skew",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Skew ${1:index}, xSkew# = ${2:value2}, ySkew# = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Applies a horizontal and vertical distortion to a screen during the display process. Does not affect the content of the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_skew" ].value
		},
	},
	{
		label: "Screen Scale",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Scale ${1:index}, xScale = ${2:value2}, yScale = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resize a screen during the display process. Does not affect the content of the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_scale" ].value
		},
	},
	{
		label: "Screen Alpha",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Alpha ${1:index}, alpha# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the global transparency of a Screen, also called "Alpha". All graphics, text and Bobs that are on the screen will be affected. Sprites are unaffected by Screen Alpha.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_alpha" ].value
		},
	},
	{
		label: "Screen Blend",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Blend ${1:index}, blend = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the Global Composite Operation of a Screen. This controls the way the screens pixels interact with underlying pixels as the screen is drawn. The instruction is in the experimental phase of development and functionality may change in the future.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_blend" ].value
		},
	},
	{
		label: "Screen Copy",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Copy sourceIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy an area from one screen to another or itself, resizing the area',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_copy" ].value
		},
	},
	{
		label: "Screen Project",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Project sourceIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy an area from one screen to another or itself, with optional resizing and/or re-shaping or warping the area',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_project" ].value
		},
	},
	{
		label: "X Screen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Screen(, x = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a hardware horizontal coordinate into a screen coordinate. Hardware coordinates are different from screen coordinate only in retro-machine display emulation (Amiga, Atari etc.)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_screen" ].value
		},
	},
	{
		label: "Y Screen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Screen(, y = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a hardware vertical coordinate into a screen coordinate. Hardware coordinates are different from screen coordinate only in retro-machine display emulation (Amiga, Atari etc.)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_screen" ].value
		},
	},
	{
		label: "Def Scroll",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Def Scroll ${1:index}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new scrolling zone in the current screen. This instruction has no visible effect until a "Scroll" instruction is used',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "def_scroll" ].value
		},
	},
	{
		label: "Scroll",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Scroll ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Performs the action of scrolling for a pre-defined scrolling area.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scroll" ].value
		},
	},
	{
		label: "Appear",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Appear sourceScreen = ${1:value1}, destinationScreen = ${2:value2}, pixels = ${3:value3}, range = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Progressively draw one screen into another using a fading effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "appear" ].value
		},
	},
	{
		label: "Zoom",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Zoom sourceScreen = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy and scale a rectangle from one screen to another.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zoom" ].value
		},
	},
	{
		label: "XGr",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'XGr',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current horizontal coordinate of the graphical cursor in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "xgr" ].value
		},
	},
	{
		label: "YGr",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'YGr',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current vertical coordinate of the graphical cursor in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ygr" ].value
		},
	},
	{
		label: "ScIn",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ScIn(, x = ${2:value2}, y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check if the given coordinates are located above a given screen on display. Hardware coordinate are only different from screen coordinate for retro-machine emulation (Amiga, Atari etc.)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scin" ].value
		},
	},
	{
		label: "Mouse Screen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouse Screen()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the screen under the mouse',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_screen" ].value
		},
	},
	{
		label: "Screen Colour",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Colour()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of colours in the palette of a given screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_colour" ].value
		},
	},
	{
		label: "Screen Color",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Color()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of colours in the palette of a given screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_color" ].value
		},
	},
	{
		label: "Screen Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Width()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width in pixels of a given screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_width" ].value
		},
	},
	{
		label: "Screen Height",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Height()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the height in pixels of a given screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_height" ].value
		},
	},
	{
		label: "Screen",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the given screen index as the current screen, all graphical operation being directed to this screen after this instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen" ].value
		},
	},
	{
		label: "Screen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the current screen, -1 if no screen is opened when the function is called',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen" ].value
		},
	},
	{
		label: "Remap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Remap color1 = ${1:value1}, color2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Transforms the values of all the pixels in a rectangle matching a specific RGB value to another RGBA value.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "remap" ].value
		},
	},
	{
		label: "Set Transparent",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Transparent colors = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set one or more colours of the current screen as transparent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_transparent" ].value
		},
	},
	{
		label: "Stop Transparent",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Stop Transparent colors = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set one colour of the current screen as opaque. Will invoke a complete remapping of the screen that migght take a long time.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "stop_transparent" ].value
		},
	},
	{
		label: "Set Alpha",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Alpha ink = ${1:value1}, alpha# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the alpha of any palette colour in the current screen. This effect of this instruction will only be visible when the next graphical instruction using this colour are executed.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_alpha" ].value
		},
	},
	{
		label: "Screen Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Filter ${1:index}, filterName$ = "${2:value2}", value1# = ${3:value3}, value2# = ${4:value4}, value3# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to a screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_filter" ].value
		},
	},
	{
		label: "Screen Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen Filter Del ${1:index}, filterName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters applied to a screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_filter_del" ].value
		},
	},
	{
		label: "Screen Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Screen Filter$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_filter$" ].value
		},
	},
	{
		label: "Screen Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Screen Filter( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of the given filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_filter" ].value
		},
	},
	{
		label: "Voice",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'A sound voice part of the game',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice" ].value
		},
	},
	{
		label: "Boom",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Boom',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'BOOM command plays a realistic explosive sound effect.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "boom" ].value
		},
	},
	{
		label: "Shoot",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shoot',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Generate percussive sound effect.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shoot" ].value
		},
	},
	{
		label: "Bell",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bell',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Generate the sound of a bell.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bell" ].value
		},
	},
	{
		label: "Sam Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Play sample = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a sound sample from the sample bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_play" ].value
		},
	},
	{
		label: "Sam Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop one or more samples playing',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_stop" ].value
		},
	},
	{
		label: "Volume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Volume level = ${1:value1}, voice = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the volume of a voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "volume" ].value
		},
	},
	{
		label: "Voice",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice bitmask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate a voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice" ].value
		},
	},
	{
		label: "Voice On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice On voice = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Unmute one or all voices',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_on" ].value
		},
	},
	{
		label: "Voice Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice Off voice = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Mute one or all voices',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_off" ].value
		},
	},
	{
		label: "Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Play voice = ${1:value1}, delay = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "play" ].value
		},
	},
	{
		label: "Play Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Play Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop playing a voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "play_off" ].value
		},
	},
	{
		label: "Set Wave",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Wave ${1:index}, shape$ = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a wave form',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_wave" ].value
		},
	},
	{
		label: "Wave",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wave ${1:index}, bitmask = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign a wave to sound channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wave" ].value
		},
	},
	{
		label: "Noise To",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Noise To bitmask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign noise wave to sound channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "noise_to" ].value
		},
	},
	{
		label: "Sample",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sample ${1:index}, bitmask = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign noise wave to sound channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sample" ].value
		},
	},
	{
		label: "Del Wave",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Wave ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a wave',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_wave" ].value
		},
	},
	{
		label: "Set Envel",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Envel number = ${1:value1}, phase = ${2:value2}, duration = ${3:value3}, volume = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a volume envelope',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_envel" ].value
		},
	},
	{
		label: "VuMeter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = VuMeter( voice = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the volume level of a voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vumeter" ].value
		},
	},
	{
		label: "Frequency",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Frequency( voice = ${2:value2}, number = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Analyse the sound and return the current level of a given frequency',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "frequency" ].value
		},
	},
	{
		label: "Sam Bank",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Bank bankNumber = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the current Samples bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_bank" ].value
		},
	},
	{
		label: "Sam Raw",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Raw voices = ${1:value1}, address = ${2:value2}, length = ${3:value3}, frequency = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a raw sample from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_raw" ].value
		},
	},
	{
		label: "Sam Loop On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Loop On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Start repetition of a sample',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_loop_on" ].value
		},
	},
	{
		label: "Sam Loop Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sam Loop Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Start repetition of a sample',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sam_loop_off" ].value
		},
	},
	{
		label: "Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite ${1:index}, image = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the image of a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite" ].value
		},
	},
	{
		label: "Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite ${1:index}, image = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create or change a sprite, and return it\'s object',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite" ].value
		},
	},
	{
		label: "Sprite Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Show',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a sprite visible on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_show" ].value
		},
	},
	{
		label: "Sprite Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Hide',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Make a sprite invisible',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_hide" ].value
		},
	},
	{
		label: "Sprite Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys all or one sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_off" ].value
		},
	},
	{
		label: "Sprite Priority",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Priority onOff = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns on or off automatic sorting of the Z-order of the sprites',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_priority" ].value
		},
	},
	{
		label: "Sprite Update Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Update Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the automatic sprite coordinate update system. After it, all "Sprite" instruction will no longer have a visible effect until an "Sprite Update" instruction is used',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_update_off" ].value
		},
	},
	{
		label: "Sprite Update On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Update On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns on the automatic sprite coordinate update system. After it, the effect of all "Sprite" instructions will be visible on display',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_update_on" ].value
		},
	},
	{
		label: "Sprite Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Update',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enforce a refresh of all the sprites on screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_update" ].value
		},
	},
	{
		label: "Sprite Scale",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Scale ${1:index}, scaleX# = ${2:value2}, scaleY# = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the scale (zoom) of a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_scale" ].value
		},
	},
	{
		label: "Sprite Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Rotate ${1:index}, angle# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotates a sprite around it\'s hot-spot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_rotate" ].value
		},
	},
	{
		label: "Sprite Skew",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Skew ${1:index}, skewX# = ${2:value2}, skewY# = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Distorts a sprite horizontally or vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_skew" ].value
		},
	},
	{
		label: "Sprite Alpha",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Alpha ${1:index}, alpha# = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the global transparency of a sprite, also called "Alpha"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_alpha" ].value
		},
	},
	{
		label: "Sprite Shadow",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Shadow ${1:index}, offsetX = ${2:value2}, offsetY = ${3:value3}, blur = ${4:value4}, rgba = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a shadow beneath the sprite.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_shadow" ].value
		},
	},
	{
		label: "Sprite Shadow Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Shadow Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off one or all sprite shadows by setting the offsets to zero.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_shadow_off" ].value
		},
	},
	{
		label: "X Sprite",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Sprite( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the horizontal coordinate of the sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_sprite" ].value
		},
	},
	{
		label: "Y Sprite",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Sprite( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns vertical coordinate of the sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_sprite" ].value
		},
	},
	{
		label: "I Sprite",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = I Sprite( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the index of the current image displayed by a Sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "i_sprite" ].value
		},
	},
	{
		label: "Is Sprite",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Is Sprite()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Checks if a sprite is defined without generating an error',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "is_sprite" ].value
		},
	},
	{
		label: "Get Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Sprite, imageIndex = ${1:value1}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Captures a portion of the current screen, create a transparency mask out of true black (RGB #000000) and add the image to the Images bank. (Deprecated, use "Get Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_sprite" ].value
		},
	},
	{
		label: "Get Sprite Palette",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Get Sprite Palette mask = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy the colour palette from the Images bank to the current screen. (Deprecated, use "Get Images Palette")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "get_sprite_palette" ].value
		},
	},
	{
		label: "Ins Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Ins Sprite position = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts an empty image in the Images bank (deprecated, use "Ins Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ins_sprite" ].value
		},
	},
	{
		label: "Del Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Sprite imageIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete an image in the Images bank (deprecated, use "Del Image")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_sprite" ].value
		},
	},
	{
		label: "Del Sprite",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Del Sprite start = ${1:value1}, end = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a range of images from the image bank (deprecated, use "Del Image To")',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "del_sprite" ].value
		},
	},
	{
		label: "Sprite Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move ${1:index}, movementX$ = "${2:value2}", movementY$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the movement of a sprite horizontally and vertically. The sprite starts to move immediately. For finer control please use the "Channel" and "Move" instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move" ].value
		},
	},
	{
		label: "Sprite Move X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move X ${1:index}, movement$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the horizontal movement of a sprite. The sprite starts to move immediately. For finer control please use the "Channel" and "MoveX" instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move_x" ].value
		},
	},
	{
		label: "Sprite Move Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move Y ${1:index}, movement$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Defines the horizontal movement of a sprite. The sprite starts to move immediately. For finer control please use the "Channel" and "Move Y" instructions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move_y" ].value
		},
	},
	{
		label: "Sprite Move Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the movement of one or all the sprites.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move_off" ].value
		},
	},
	{
		label: "Sprite Move On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move On ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Start the movement of one or all the sprites.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move_on" ].value
		},
	},
	{
		label: "Sprite Move Freeze",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Move Freeze',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause the movement of one or all the sprites.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_move_freeze" ].value
		},
	},
	{
		label: "Sprite Moveon",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sprite Moveon( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if the movement of one sprite is completed or not.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_moveon" ].value
		},
	},
	{
		label: "Sprite Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Filter ${1:index}, filterName$ = "${2:value2}", value1# = ${3:value3}, value2# = ${4:value4}, value3# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to a sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_filter" ].value
		},
	},
	{
		label: "Sprite Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sprite Filter Del ${1:index}, filterName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters applied to a sprite.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_filter_del" ].value
		},
	},
	{
		label: "Sprite Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Sprite Filter$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_filter$" ].value
		},
	},
	{
		label: "Sprite Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Sprite Filter( ${2:index}, paramNumber = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of a filter for s sprite',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sprite_filter" ].value
		},
	},
	{
		label: "Write Local",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Write Local Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Writes a value in the local storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "write_local" ].value
		},
	},
	{
		label: "Write Session",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Write Session Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Writes a value in the session storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "write_session" ].value
		},
	},
	{
		label: "Read Local",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Read Local( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reads and returns a value as a number from the local storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "read_local" ].value
		},
	},
	{
		label: "Read Local$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Read Local$( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reads and returns a value as a string from the local storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "read_local$" ].value
		},
	},
	{
		label: "Read Session",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Read Session( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reads and returns a value as a number from the session storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "read_session" ].value
		},
	},
	{
		label: "Read Session$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Read Session$( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reads and returns a value as a string from the session storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "read_session$" ].value
		},
	},
	{
		label: "Local Exists",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Local Exists( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if a value exists in the local storage, or False',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "local_exists" ].value
		},
	},
	{
		label: "Session Exists",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Session Exists( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if a value exists in the session storage, or False',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "session_exists" ].value
		},
	},
	{
		label: "Delete Local",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Delete Local Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a value from the local storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "delete_local" ].value
		},
	},
	{
		label: "Delete Session",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Delete Session Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a value from the session storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "delete_session" ].value
		},
	},
	{
		label: "Clear Local",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clear Local',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear all values from the local storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clear_local" ].value
		},
	},
	{
		label: "Clear Session",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clear Session',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear all values from the session storage',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clear_session" ].value
		},
	},
	{
		label: "Asc",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Asc( text$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Converts the first character of a string into it\'s ASCII code',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "asc" ].value
		},
	},
	{
		label: "Left$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Left$( string$ = "${2:value2}", number = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return or set the leftmost characters of a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "left$" ].value
		},
	},
	{
		label: "Right$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Right$( string$ = "${2:value2}", number = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return or set the rightmost characters of a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "right$" ].value
		},
	},
	{
		label: "Replace$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Replace$( string$ = "${2:value2}", subString$ = "${3:value3}", replacement$ = "${4:value4}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Replace a number or all occurrences of a sub-string in a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "replace$" ].value
		},
	},
	{
		label: "Mid$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Mid$( string$ = "${2:value2}", offset = ${3:value3}, number = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return or set a portion of a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mid$" ].value
		},
	},
	{
		label: "Flip$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Flip$( string$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Invert a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "flip$" ].value
		},
	},
	{
		label: "Chr$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Chr$( code = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the character with a given ASCII code',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "chr$" ].value
		},
	},
	{
		label: "Space$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Space$( length = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a string contain the demanded amount of spaces',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "space$" ].value
		},
	},
	{
		label: "String$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = String$( string$ = "${2:value2}", number = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a new string from the first character of an existing string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "string$" ].value
		},
	},
	{
		label: "Upper$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Upper$( string$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a string of text to upper-case',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "upper$" ].value
		},
	},
	{
		label: "Lower$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Lower$( STRING$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a string of text to upper-case',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lower$" ].value
		},
	},
	{
		label: "Str$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Str$( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a number into a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "str$" ].value
		},
	},
	{
		label: "Trim$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Trim$( value = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Used to remove blanks at the beginning and end of a string. The considered blanks are the space characters (space, tab, non-breaking space, etc.) as well as the end-of-line characters (LF, CR, etc.).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "trim$" ].value
		},
	},
	{
		label: "Val",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Val( string$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a string into a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "val" ].value
		},
	},
	{
		label: "Bin$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Bin$( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a decimal value into a string of binary digits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bin$" ].value
		},
	},
	{
		label: "Hex$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Hex$( number = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a decimal value into a string of hexadecimal digits',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hex$" ].value
		},
	},
	{
		label: "Len",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Len( string = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the length in characters of a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "len" ].value
		},
	},
	{
		label: "Instr",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Instr( host$ = "${2:value2}", guest$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Search for occurrences of one sub-string within another string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "instr" ].value
		},
	},
	{
		label: "Trim$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Trim$( string$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove any space at the beginning or at the end of a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "trim$" ].value
		},
	},
	{
		label: "String Base Index",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'String Base Index ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the base index of string functions like Mid$, Left$ or Right$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "string_base_index" ].value
		},
	},
	{
		label: "Multi On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Multi On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restart the multitasking inside of Aoz',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "multi_on" ].value
		},
	},
	{
		label: "Multi Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Multi Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop multitasking in Aoz',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "multi_off" ].value
		},
	},
	{
		label: "ErrTrap",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ErrTrap()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of the last Trapped error, and reset the number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "errtrap" ].value
		},
	},
	{
		label: "Err$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Err$( errorNumber = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the text of an error message from it\'s number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "err$" ].value
		},
	},
	{
		label: "ErrN",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = ErrN()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of the last error that occured',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "errn" ].value
		},
	},
	{
		label: "Resume Next",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resume Next',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resume the execution of current program after an error trapping routine to the next instruction after the error occurred.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resume_next" ].value
		},
	},
	{
		label: "Resume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resume',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resume the execution of current program after an error trapping routine to the instruction that caused the error.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resume" ].value
		},
	},
	{
		label: "Every On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Every On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle regular EVERY calls on',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "every_on" ].value
		},
	},
	{
		label: "Every Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Every Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle regular EVERY calls off',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "every_off" ].value
		},
	},
	{
		label: "Every",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Every time = ${1:value1}, procedure = ${2:value2}, label = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call subroutine or procedure at regular intervals',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "every" ].value
		},
	},
	{
		label: "For",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'For ${1:variable} = ${2:start} To ${3:end}\n\t${4:code}\nNext ${1:variable}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat loop a specific number of times',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "for" ].value
		},
	},
	{
		label: "Next",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Next',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates the end of a For / Next loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "next" ].value
		},
	},
	{
		label: "Step",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Step step = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Control increment of index in a FOR / NEXT loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "step" ].value
		},
	},
	{
		label: "Repeat",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Repeat\n\t${1:code}\n\tRefresh\nUntil ${2:condition}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat loop until a condition is satisfied',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "repeat" ].value
		},
	},
	{
		label: "Until",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Until',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates the end of a REPEAT / UNTIL loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "until" ].value
		},
	},
	{
		label: "While",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'While ${1:condition}\n\t${2:code}\n\tRefresh\nWend',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat loop while condition is true',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "while" ].value
		},
	},
	{
		label: "Wend",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wend',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'marks the end of a WHILE / WEND loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wend" ].value
		},
	},
	{
		label: "Do",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Do\n\t${4:code}\n\tRefresh\nLoop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Keep repeating a list of statements',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "do" ].value
		},
	},
	{
		label: "Loop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Loop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Mark the end of a DO / LOOP loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "loop" ].value
		},
	},
	{
		label: "Exit",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Exit',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Break out of a loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "exit" ].value
		},
	},
	{
		label: "Exit If",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Exit If condition = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Break out of a loop',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "exit_if" ].value
		},
	},
	{
		label: "Gosub",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Gosub label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Jump to a sub-routine',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "gosub" ].value
		},
	},
	{
		label: "Return",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Return',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return from a sub-routine called by GOSUB',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "return" ].value
		},
	},
	{
		label: "Pop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pop label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove RETURN information',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pop" ].value
		},
	},
	{
		label: "Goto",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Goto label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Jump to a specified place in the program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "goto" ].value
		},
	},
	{
		label: "If",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'If condition = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Choose between alternative actions depending on the result of an expression',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "if" ].value
		},
	},
	{
		label: "Else",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Else condition = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Qualify a condition',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "else" ].value
		},
	},
	{
		label: "Else If",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Else If condition = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Qualify another condition',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "else_if" ].value
		},
	},
	{
		label: "On Error Goto",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Error Goto label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Trap an error in your program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_error_goto" ].value
		},
	},
	{
		label: "On Break Proc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On Break Proc label = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Jump to a procedure when break in program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on_break_proc" ].value
		},
	},
	{
		label: "On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'On expression = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Jump on recognising an expression',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "on" ].value
		},
	},
	{
		label: "Procedure",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Procedure ${1:Name}[ ${2:argument1}, ${3:argument2}, ${4:argument3},... ]\n\t${5:code}\nEnd Procedure',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a procedure',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "procedure" ].value
		},
	},
	{
		label: "End proc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'End proc',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'End a procedure',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "end_proc" ].value
		},
	},
	{
		label: "Pop proc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pop proc',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Leave a procedure immediately',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pop_proc" ].value
		},
	},
	{
		label: "Proc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Proc',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Flag a procedure',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "proc" ].value
		},
	},
	{
		label: "Def Fn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Def Fn',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'create a user-defined function',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "def_fn" ].value
		},
	},
	{
		label: "Fn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Fn',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a user-defined function',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "fn" ].value
		},
	},
	{
		label: "Run",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Run',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Execute another AOZ program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "run" ].value
		},
	},
	{
		label: "Rem",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Rem',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Insert a reminder message into a program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rem" ].value
		},
	},
	{
		label: "Edit",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Edit',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Leave current program and return to Edit Screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "edit" ].value
		},
	},
	{
		label: "Direct",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Direct',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Leave current program and return to Direct Mode',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "direct" ].value
		},
	},
	{
		label: "Break On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Break On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'toggle the program break keys on',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "break_on" ].value
		},
	},
	{
		label: "Break Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Break Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'toggle the program break keys off',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "break_off" ].value
		},
	},
	{
		label: "Include",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Include "${1:value1}.aozinc"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts the source of another AOZ file into the current file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "include" ].value
		},
	},
	{
		label: "End",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'End',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'End the current program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "end" ].value
		},
	},
	{
		label: "Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Interrupt the current program',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "stop" ].value
		},
	},
	{
		label: "Include",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Include path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Inserts the source of another AOZ file into the current file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "include" ].value
		},
	},
	{
		label: "Td Init",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Init',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialise le moteur 3D',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_init" ].value
		},
	},
	{
		label: "Screen 3D Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Screen 3D Open ${1:index}, SceneID = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Ouvre un écran pour afficher et manipuler des objets 3D. Il est possible d\'ouvrir',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "screen_3d_open" ].value
		},
	},
	{
		label: "Td Start",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Start',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Active le moteur 3D',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_start" ].value
		},
	},
	{
		label: "Td Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Désactive le moteur 3D.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_stop" ].value
		},
	},
	{
		label: "Td Screen Update",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Screen Update ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Met à jour un écran 3D',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_screen_update" ].value
		},
	},
	{
		label: "Td Set Background",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Set Background Image$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Applique une image de fond à l\'écran 3D en cours',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_set_background" ].value
		},
	},
	{
		label: "Td Copy",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Copy SourceName$ = "${1:value1}", CopyName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Duplique un objet 3D et ajoute la copie à la scene en cours sous un autre nom',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_copy" ].value
		},
	},
	{
		label: "Td Group Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Group Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'un objet de type "Groupe" et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_group_add" ].value
		},
	},
	{
		label: "Td Visible",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Visible ObjectName$ = "${1:value1}", Visible = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rend un objet 3D Visible ou Invisible',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_visible" ].value
		},
	},
	{
		label: "Td Cube Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Cube Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'un cube 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_cube_add" ].value
		},
	},
	{
		label: "Td Box Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Box Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'une boite 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_box_add" ].value
		},
	},
	{
		label: "Td Sphere Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Sphere Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'une sphere 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_sphere_add" ].value
		},
	},
	{
		label: "Td Cone Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Cone Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'un cône 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_cone_add" ].value
		},
	},
	{
		label: "Td Plane Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Plane Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'un plan 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_plane_add" ].value
		},
	},
	{
		label: "Td Cylinder Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Cylinder Add ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Création d\'un cylindre 3D et l\'ajoute à l\'objet Parent.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_cylinder_add" ].value
		},
	},
	{
		label: "Td Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Move ObjectName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Positionne un objet 3D sur les 3 axes X,Y et Z.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_move" ].value
		},
	},
	{
		label: "Td Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Rotate ObjectName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotation d\'un objet 3D sur les 3 axes X,Y et Z.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_rotate" ].value
		},
	},
	{
		label: "Td Scale",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Scale ObjectName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Redimensionne un objet 3D sur les 3 axes X,Y et Z.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_scale" ].value
		},
	},
	{
		label: "Td Backward",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Backward ObjectName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Déplace un objet 3D sur l\'axe Z vers l\'angle opposé à l\'axe Y (marche arrière).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_backward" ].value
		},
	},
	{
		label: "Td Foreward",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Foreward ObjectName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Déplace un objet 3D sur l\'axe Z vers l\'angle de l\'axe Y (marche avant).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_foreward" ].value
		},
	},
	{
		label: "Td Delete",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Td Delete ObjectName$ = "${1:value1}", ParentName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Supprime un objet 3D de la scene ou d\'un groupe d\'objet.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "td_delete" ].value
		},
	},
	{
		label: "Wind Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wind Open ${1:index}, x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a new text window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wind_open" ].value
		},
	},
	{
		label: "Wind Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wind Close',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close the current text window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wind_close" ].value
		},
	},
	{
		label: "Wind Save",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wind Save',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save the character content of the the currenttext window so that it can be restored later',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wind_save" ].value
		},
	},
	{
		label: "Wind Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wind Move dx = ${1:value1}, dy = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the current text window horizontally and vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wind_move" ].value
		},
	},
	{
		label: "Wind Size",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wind Size newWidth = ${1:value1}, newHeight = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resize the current text window horizontally and vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wind_size" ].value
		},
	},
	{
		label: "Window",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Window ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Activate the given window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "window" ].value
		},
	},
	{
		label: "Windon",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Windon()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the current window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "windon" ].value
		},
	},
	{
		label: "Locate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Locate x = ${1:value1}, y = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the position of the text cursor of the current window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "locate" ].value
		},
	},
	{
		label: "Clw",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Clw',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear the current text window with the Paper color, and restores the cursor at position 0, 0',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "clw" ].value
		},
	},
	{
		label: "Home",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Home',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the text cursor at position 0, 0. The next text output with a "Print" instructions will be locate on the top-left of the windows',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "home" ].value
		},
	},
	{
		label: "Curs Pen",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Curs Pen color = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the text cursor at position 0, 0. The next text output with a "Print" instructions will be locate on the top-left of the windows',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "curs_pen" ].value
		},
	},
	{
		label: "Pen$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Pen$( color = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to use in a "Print" statement and change the colour of the pen used to draw the following texts',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pen$" ].value
		},
	},
	{
		label: "Paper$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Paper$( color = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to use in a "Print" statement and change the colour of the paper used to draw the following texts',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paper$" ].value
		},
	},
	{
		label: "Print",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Print ITEMS = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print items in the current window of the current screen. A semi-column at the end of will prevent a new-line',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "print" ].value
		},
	},
	{
		label: "Print Using",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Print Using item = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print formatted text and numbers in the current window of the current screen. A semi-column at the end of will prevent a new-line',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "print_using" ].value
		},
	},
	{
		label: "At",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = At( X = ${2:value2}, Y = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to use in a "Print" statement that changes the location of the cursor for the next characters',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "at" ].value
		},
	},
	{
		label: "Pen",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pen colorIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the colour of the pen used to draw characters during a "Print" statement',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pen" ].value
		},
	},
	{
		label: "Paper",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Paper colorIndex = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the colour of the paper used to draw the background space of the characters during a "Print" statement',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paper" ].value
		},
	},
	{
		label: "Pen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Pen()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function to return the current text Pen color index.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pen" ].value
		},
	},
	{
		label: "Paper",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Paper()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function to return the current text Paper color index.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "paper" ].value
		},
	},
	{
		label: "Centre",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Centre TEXT$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display and centre a string in the middle of the current text window at the current vertical position of the text cursor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "centre" ].value
		},
	},
	{
		label: "Border",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Border borderNumber = ${1:value1}, paper = ${2:value2}, pen = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change teh text window character border to one of the pre-defined ones',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border" ].value
		},
	},
	{
		label: "Writing",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Writing mode1 = ${1:value1}, mode2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the logical operation done during the display of characters by the "Print" statement',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "writing" ].value
		},
	},
	{
		label: "Title Top",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Title Top title$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a title in the top of the current text window with a border. Will have no effect if the window has no border',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "title_top" ].value
		},
	},
	{
		label: "Title Bottom",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Title Bottom title$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a title in the bottom of the current text window with a border. Will have no effect if the window has no border',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "title_bottom" ].value
		},
	},
	{
		label: "Curs Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Curs Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the text cursor and stop all associated colour animation. Use this instruction before graphical output to speed up the display',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "curs_off" ].value
		},
	},
	{
		label: "Curs On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Curs On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show the text cursor and restarts all associated colour animation',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "curs_on" ].value
		},
	},
	{
		label: "Inverse On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Inverse On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Inverse setting for printing text in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "inverse_on" ].value
		},
	},
	{
		label: "Inverse Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Inverse Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch off Inverse setting for printing text in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "inverse_off" ].value
		},
	},
	{
		label: "Bold On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bold On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Bold setting for printing text in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bold_on" ].value
		},
	},
	{
		label: "Bold Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bold Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch off Bold setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bold_off" ].value
		},
	},
	{
		label: "Italic On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Italic On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Italic setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "italic_on" ].value
		},
	},
	{
		label: "Italic Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Italic Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch off Italic setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "italic_off" ].value
		},
	},
	{
		label: "Under On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Under On width = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Underline setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "under_on" ].value
		},
	},
	{
		label: "Under Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Under Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch off Underline setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "under_off" ].value
		},
	},
	{
		label: "Outline On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Outline On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Outline setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "outline_on" ].value
		},
	},
	{
		label: "Outline Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Outline Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch off Outline setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "outline_off" ].value
		},
	},
	{
		label: "Shade On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shade On intensity = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch on Shade setting for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shade_on" ].value
		},
	},
	{
		label: "Shade Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shade Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Switch Off shading for Printed text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shade_off" ].value
		},
	},
	{
		label: "Shadow On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shadow On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn ON the shadow effect for printing in the current text window.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shadow_on" ].value
		},
	},
	{
		label: "Shadow Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shadow Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn OFF the shadow effect for printed text.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shadow_off" ].value
		},
	},
	{
		label: "Scroll On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Scroll On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn ON the automatic scrolling of the content of the current text window when the cursor pass the bottom line',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scroll_on" ].value
		},
	},
	{
		label: "Scroll Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Scroll Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn OFF the automatic scrolling of teh content of the current text window when the cursor pass the bottom line, enforcing all further printing to be done in the top of the window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "scroll_off" ].value
		},
	},
	{
		label: "CUp$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CUp$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to be used during a "Print" statement, moving the cursor one line up',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cup$" ].value
		},
	},
	{
		label: "CDown$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CDown$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to be used during a "Print" statement, moving the cursor one line down',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cdown$" ].value
		},
	},
	{
		label: "CLeft$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CLeft$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to be used during a "Print" statement, moving the cursor one character left',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cleft$" ].value
		},
	},
	{
		label: "CRight$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CRight$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to be used during a "Print" statement, moving the cursor one character right',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cright$" ].value
		},
	},
	{
		label: "CUp",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CUp',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the cursor of the current text window one line up.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cup" ].value
		},
	},
	{
		label: "CDown",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CDown',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the cursor of the current text window one line down.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cdown" ].value
		},
	},
	{
		label: "CLeft",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CLeft',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the cursor of the current text window one character left.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cleft" ].value
		},
	},
	{
		label: "CRight",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CRight',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the cursor of the current text window one character right.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cright" ].value
		},
	},
	{
		label: "Memorize X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Memorize X',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Store the horizontal position of the cursor in internal memory so that you can retreive it later',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "memorize_x" ].value
		},
	},
	{
		label: "Memorize Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Memorize Y',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Store the vertical position of the cursor in internal memory so that you can retreive it later',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "memorize_y" ].value
		},
	},
	{
		label: "Remember X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Remember X',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Recall the horizontal position of the cursor from internal memory and move the cursor to the position',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "remember_x" ].value
		},
	},
	{
		label: "Remember Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Remember Y',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Recall the vertical position of the cursor from internal memory and move the cursor to the position',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "remember_y" ].value
		},
	},
	{
		label: "CMove$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CMove$( dx = ${2:value2}, dy = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to be used during a "Print" statement, moving the cursor by the given character displacements',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cmove$" ].value
		},
	},
	{
		label: "CMove",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CMove dx = ${1:value1}, dy = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move the text cursor by a horizontal and/or vertical displacement',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cmove" ].value
		},
	},
	{
		label: "CLine",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CLine length = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clear the line (paint with the Paper colour) located at the current vertical position of the cursor.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "cline" ].value
		},
	},
	{
		label: "HScroll",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'HScroll type = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scroll the content of the current window or line by one character horizontally',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hscroll" ].value
		},
	},
	{
		label: "VScroll",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'VScroll type = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scroll the content of the current window or line by one character vertically',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vscroll" ].value
		},
	},
	{
		label: "Tab$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Tab$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a string containing the Tab character (ASCII',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tab$" ].value
		},
	},
	{
		label: "Set Tab",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Tab width = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the width in characters used when printing the TAB character. Default is 4',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_tab" ].value
		},
	},
	{
		label: "Repeat$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Repeat$( text$ = "${2:value2}", number = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Repeat a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "repeat$" ].value
		},
	},
	{
		label: "Set Curs",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Curs l1 = ${1:value1}, l2 = ${2:value2}, l3 = ${3:value3}, l4 = ${4:value4}, l5 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the shape of the text cursor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_curs" ].value
		},
	},
	{
		label: "X Curs",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Curs()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the horizontal position of the text cursor, in text coordinates',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_curs" ].value
		},
	},
	{
		label: "Y Curs",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Curs()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the vertical position of the text cursor, in text coordinates',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_curs" ].value
		},
	},
	{
		label: "X Graphic",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Graphic()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the graphical horizontal position of a coordinate in the text window coordinate space',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_graphic" ].value
		},
	},
	{
		label: "Y Graphic",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Graphic()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the graphical vertical position of a coordinate in the text window coordinate space',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_graphic" ].value
		},
	},
	{
		label: "Border$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Border$( text$ = "${2:value2}", border = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string understood by the "Print" statement, enforcing the drawing of a border around the given text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "border$" ].value
		},
	},
	{
		label: "X Text",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = X Text( x = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a horizontal coordinate from the graphical coordinate space of the screen hosting the text window in a text coordinate within the window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "x_text" ].value
		},
	},
	{
		label: "Y Text",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Y Text( y = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Convert a vertical coordinate from the graphical coordinate space of the screen hosting the text window in a text coordinate within the window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "y_text" ].value
		},
	},
	{
		label: "Win Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Win Width',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the width of the current window in the current screen (number of columns)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "win_width" ].value
		},
	},
	{
		label: "Win Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Win Height',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the height of the current window in the current screen (number of lines)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "win_height" ].value
		},
	},
	{
		label: "Timer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Timer',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserved variable. Return / Set the number of millisecond since the application was launched. Work in 1/50th of seconds in Amiga emulation',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "timer" ].value
		},
	},
	{
		label: "Refresh",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Refresh',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application until the next screen refresh',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "refresh" ].value
		},
	},
	{
		label: "Wait Vbl",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wait Vbl',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application until the next screen refresh, alias of Sync Display',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wait_vbl" ].value
		},
	},
	{
		label: "Wait",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Wait time = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pauses the application during a certain period of time',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "wait" ].value
		},
	},
	{
		label: "UI Confirm",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Confirm title$ = "${1:value1}", content$ = "${2:value2}", confirmButton$ = "${3:value3}", closeButton$ = "${4:value4}", onConfirm$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a Confirm user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_confirm" ].value
		},
	},
	{
		label: "UI Prompt$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Prompt$ title$ = "${1:value1}", value$ = "${2:value2}", confirmButton$ = "${3:value3}", closeButton$ = "${4:value4}", onConfirm$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a Prompt user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_prompt$" ].value
		},
	},
	{
		label: "UI Prompt",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Prompt title$ = "${1:value1}", value = "${2:value2}", confirmButton$ = "${3:value3}", closeButton$ = "${4:value4}", onConfirm$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a Prompt user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_prompt" ].value
		},
	},
	{
		label: "UI Progress",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Progress id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, value = ${4:value4}, width = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a Progress user interface component,',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_progress" ].value
		},
	},
	{
		label: "UI Radio",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Radio id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, items$ = "${4:value4}", fontSize = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a Radio user interface component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_radio" ].value
		},
	},
	{
		label: "UI TextBlock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI TextBlock id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a TextBlock user interface component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_textblock" ].value
		},
	},
	{
		label: "UI IFrame",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI IFrame id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Adds an iframe HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_iframe" ].value
		},
	},
	{
		label: "UI ColorPicker",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI ColorPicker id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a ColorPicker user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_colorpicker" ].value
		},
	},
	{
		label: "UI Slider",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Slider id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, min = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a Slider user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_slider" ].value
		},
	},
	{
		label: "UI Select",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Select id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, items$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a Select user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_select" ].value
		},
	},
	{
		label: "UI CheckBox",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI CheckBox id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a CheckBox user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_checkbox" ].value
		},
	},
	{
		label: "UI TextBox",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI TextBox id$ = "${1:value1}", value$ = "${2:value2}", placeHolder$ = "${3:value3}", class$ = "${4:value4}", x = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a TextBox user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_textbox" ].value
		},
	},
	{
		label: "UI Button",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Button id$ = "${1:value1}", content$ = "${2:value2}", class$ = "${3:value3}", x = ${4:value4}, y = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a Button user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_button" ].value
		},
	},
	{
		label: "UI TextArea",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI TextArea id$ = "${1:value1}", value$ = "${2:value2}", placeHolder$ = "${3:value3}", class$ = "${4:value4}", x = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a Button user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_textarea" ].value
		},
	},
	{
		label: "UI Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Image id$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays an Image user interface component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_image" ].value
		},
	},
	{
		label: "UI Popup",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Popup id$ = "${1:value1}", content$ = "${2:value2}", placement$ = "${3:value3}", delay = ${4:value4}, class$ = "${5:value5}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a UI Popup component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_popup" ].value
		},
	},
	{
		label: "UI Delete",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Delete id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a UI Component from the display.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_delete" ].value
		},
	},
	{
		label: "UI Cls",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Cls group$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete UI Components from the display.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_cls" ].value
		},
	},
	{
		label: "UI Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Show id$ = "${1:value1}", group$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show UI elements that have been hidden using the UI Hide instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_show" ].value
		},
	},
	{
		label: "UI Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Hide id$ = "${1:value1}", group$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide UI elements from the display',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_hide" ].value
		},
	},
	{
		label: "UI Enable",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Enable',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enable all the user actions on a UI element.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_enable" ].value
		},
	},
	{
		label: "UI Disable",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Disable',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Disable all the user actions on a UI element.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_disable" ].value
		},
	},
	{
		label: "UI Value",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Value id$ = "${1:value1}", value$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Updates ths value of a UI Component.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_value" ].value
		},
	},
	{
		label: "UI HideTooltip",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI HideTooltip id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hides a button tooltip if it is visible',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_hidetooltip" ].value
		},
	},
	{
		label: "UI Value$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = UI Value$( id$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_value$" ].value
		},
	},
	{
		label: "UI Property$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Property$ id$ = "${1:value1}", propertyName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a UI component property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_property$" ].value
		},
	},
	{
		label: "UI Property",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Property id$ = "${1:value1}", propertyName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of a UI component property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_property" ].value
		},
	},
	{
		label: "UI X",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI X id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'x\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_x" ].value
		},
	},
	{
		label: "UI Y",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Y id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'y\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_y" ].value
		},
	},
	{
		label: "UI Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Width id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'width\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_width" ].value
		},
	},
	{
		label: "UI Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Height id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'height\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_height" ].value
		},
	},
	{
		label: "UI FontSize",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI FontSize id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'fontSize\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_fontsize" ].value
		},
	},
	{
		label: "UI FontName$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI FontName$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'fontName$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_fontname$" ].value
		},
	},
	{
		label: "UI Group$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Group$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'group$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_group$" ].value
		},
	},
	{
		label: "UI Padding",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Padding id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'padding\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_padding" ].value
		},
	},
	{
		label: "UI Content$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Content$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'content$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_content$" ].value
		},
	},
	{
		label: "UI Min",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Min id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'min\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_min" ].value
		},
	},
	{
		label: "UI Max",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Max id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'max\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_max" ].value
		},
	},
	{
		label: "UI Step",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Step id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'step\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_step" ].value
		},
	},
	{
		label: "UI Rows",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Rows id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'rows\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_rows" ].value
		},
	},
	{
		label: "UI Placeholder$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Placeholder$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'placeHolder\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_placeholder$" ].value
		},
	},
	{
		label: "UI Tooltip$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Tooltip$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'tooltip$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_tooltip$" ].value
		},
	},
	{
		label: "UI TooltipPlacement$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI TooltipPlacement$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'TooltipPlacement$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_tooltipplacement$" ].value
		},
	},
	{
		label: "UI IconClass$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI IconClass$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'iconClass$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_iconclass$" ].value
		},
	},
	{
		label: "UI Class$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Class$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'iconClass$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_class$" ].value
		},
	},
	{
		label: "UI Type$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Type$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'type$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_type$" ].value
		},
	},
	{
		label: "UI OnChange$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI OnChange$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'onChange$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_onchange$" ].value
		},
	},
	{
		label: "UI OnClick$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI OnClick$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'onClick$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_onclick$" ].value
		},
	},
	{
		label: "UI Items$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Items$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'items$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_items$" ].value
		},
	},
	{
		label: "UI Src$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Src$ id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current \'src$\' value of a UI component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_src$" ].value
		},
	},
	{
		label: "UI Focus",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI Focus id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets focus to a component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_focus" ].value
		},
	},
	{
		label: "UI SelectText",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'UI SelectText id$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Selects all the text in a TextBox component',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ui_selecttext" ].value
		},
	},
	{
		label: "Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Add variable = ${1:value1}, expression = ${2:value2}, base = ${3:value3}, top = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "add" ].value
		},
	},
	{
		label: "Array",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Array( array = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "array" ].value
		},
	},
	{
		label: "Data",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Data',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "data" ].value
		},
	},
	{
		label: "Dec",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dec',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dec" ].value
		},
	},
	{
		label: "Dim",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dim dimension1 = ${1:value1}, dimension2 = ${2:value2}, dimensionX = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dim" ].value
		},
	},
	{
		label: "False",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = False()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "false" ].value
		},
	},
	{
		label: "Global",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Global variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "global" ].value
		},
	},
	{
		label: "Inc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Inc variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "inc" ].value
		},
	},
	{
		label: "Is Defined",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Is Defined()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "is_defined" ].value
		},
	},
	{
		label: "Match",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Match( array = ${2:value2}, value = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "match" ].value
		},
	},
	{
		label: "Read",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Read variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "read" ].value
		},
	},
	{
		label: "Restore",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Restore',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "restore" ].value
		},
	},
	{
		label: "Shared",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Shared variable = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "shared" ].value
		},
	},
	{
		label: "Sort",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sort array = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sort" ].value
		},
	},
	{
		label: "Swap",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Swap variable1 = ${1:value1}, variable2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "swap" ].value
		},
	},
	{
		label: "True",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = True()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "true" ].value
		},
	},
	{
		label: "Varptr",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Varptr( variable = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "varptr" ].value
		},
	},
	{
		label: "Reserve Zone",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reserve Zone',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reserve memory to store graphical detection zones in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reserve_zone" ].value
		},
	},
	{
		label: "Reset Zone",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reset Zone',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Erase the definition of a zone, making it non-detectable',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reset_zone" ].value
		},
	},
	{
		label: "Zone$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Zone$ text$ = "${1:value1}", ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return a magical string to use in the Print instruction when printing text, allowing you to define a zone around the text included as a parameter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zone$" ].value
		},
	},
	{
		label: "Set Zone",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Zone ${1:index}, x1 = ${2:value2}, y1 = ${3:value3}, x2 = ${4:value4}, y2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define a new zone in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_zone" ].value
		},
	},
	{
		label: "Zone",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Zone( ${2:index}, x = ${3:value3}, y = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check if a given coordinate lays inside of a pre-defined zone',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zone" ].value
		},
	},
	{
		label: "Mouse Zone",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouse Zone()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of a predefined zone (with "Set Zone") under the mouse',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouse_zone" ].value
		},
	},
	{
		label: "Zone On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Zone On ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enable a zone in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zone_on" ].value
		},
	},
	{
		label: "Zone Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Zone Off ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Disable a zone in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zone_off" ].value
		},
	},
	{
		label: "Zones Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Zones Length()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of zones defined for the current screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zones_length" ].value
		},
	},
	{
		label: "Zone Enabled",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Zone Enabled( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return True if the zone is enabled, or False',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "zone_enabled" ].value
		},
	},
	{
		label: "Bot Connect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bot Connect',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Establishes the connection with a Bot robot connected to your machine.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_connect" ].value
		},
	},
	{
		label: "Bot Disconnect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Bot Disconnect',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a running connection to Bot.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_disconnect" ].value
		},
	},
	{
		label: "BOT Audio Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Audio Play content$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a preset audio file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_audio_play" ].value
		},
	},
	{
		label: "BOT Sound Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Sound Play type$ = "${1:value1}", note = ${2:value2}, note$ = "${3:value3}", beat = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Plays the specified note of the specified instrument for the specified beats',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_sound_play" ].value
		},
	},
	{
		label: "BOT Record",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Record',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts to record sounds',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_record" ].value
		},
	},
	{
		label: "BOT Record Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Record Play',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play the recorded sounds',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_record_play" ].value
		},
	},
	{
		label: "BOT Tempo",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Tempo percentage = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the playing speed of speaker',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_tempo" ].value
		},
	},
	{
		label: "BOT Tempo",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Tempo()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current tempo of the speaker',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_tempo" ].value
		},
	},
	{
		label: "BOT Volume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Volume percentage = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the playing volume of speaker',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_volume" ].value
		},
	},
	{
		label: "BOT Volume",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Volume()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current volume of the speaker',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_volume" ].value
		},
	},
	{
		label: "BOT Led On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Led On r = ${1:value1}, g = ${2:value2}, b = ${3:value3}, number = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the colors of the Bot LEDs',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_on" ].value
		},
	},
	{
		label: "BOT Led Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Led Play content$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays the specified LED animation on the Bot LED strip',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_play" ].value
		},
	},
	{
		label: "BOT Led Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Led Move step = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Makes the colors of the LEDs roll from left to right by the specified number of positions',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_move" ].value
		},
	},
	{
		label: "BOT Led Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Led Show colors$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the color of the five LEDs of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_show" ].value
		},
	},
	{
		label: "BOT Led Bright",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Led Bright bright = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the brightness of all five LEDs of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_bright" ].value
		},
	},
	{
		label: "BOT Led Bright",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Led Bright()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current brightness of the Bot LEDs',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_led_bright" ].value
		},
	},
	{
		label: "BOT Strip On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Strip On r = ${1:value1}, g = ${2:value2}, b = ${3:value3}, number = ${4:value4}, port = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Lights up the LEDs on the LED strip or ring connected to the specified port in the specified color(s).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_on" ].value
		},
	},
	{
		label: "BOT Strip Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Strip Off number = ${1:value1}, port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns off the specified LED(s) on the LED strip or ring connected to the specified port.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_off" ].value
		},
	},
	{
		label: "BOT Strip Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Strip Show colors$ = "${1:value1}", port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the color(s) of all LEDs on the LED strip or ring connected to the specified port.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_show" ].value
		},
	},
	{
		label: "BOT Strip Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Strip Move step = ${1:value1}, step = ${2:value2}, port = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Makes the colors of the LEDs on the LED strip connected to the specified port roll from left to right by the specified number of positions.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_move" ].value
		},
	},
	{
		label: "BOT Strip Bright",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Strip Bright brightness = ${1:value1}, port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the brightness of the LED strip or ring connected to the specified port.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_bright" ].value
		},
	},
	{
		label: "BOT Strip Bright",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Strip Bright( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the brightness of the LED strip or ring connected to the specified port.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_strip_bright" ].value
		},
	},
	{
		label: "BOT Move",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Move speed = ${1:value1}, duration = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Moves the connected Bot forward or backward at the given speed.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_move" ].value
		},
	},
	{
		label: "BOT Turn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Turn direction$ = "${1:value1}", speed = ${2:value2}, duration = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn the connected Bot to the left or the right.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_turn" ].value
		},
	},
	{
		label: "BOT Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Rotate angle = ${1:value1}, speed = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Rotates the connected Bot to a specified angle',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_rotate" ].value
		},
	},
	{
		label: "BOT Rotation",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Rotation( axis$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the angle Bot rotates around the specified axis, with the clockwise direction as the positive direction.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_rotation" ].value
		},
	},
	{
		label: "BOT Speed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Speed speedLeft = ${1:value1}, speedRight = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the speed of the two motors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_speed" ].value
		},
	},
	{
		label: "BOT Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the rotation of the two motors',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_stop" ].value
		},
	},
	{
		label: "BOT Wheel Power",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Wheel Power speed = ${1:value1}, motor = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the power of wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_power" ].value
		},
	},
	{
		label: "BOT Wheel Power",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Wheel Power( motor = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains, in real time, the power of the encoder motor connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_power" ].value
		},
	},
	{
		label: "BOT Wheel Speed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Wheel Speed speed = ${1:value1}, motor = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the speed of wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_speed" ].value
		},
	},
	{
		label: "BOT Wheel Speed",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Wheel Speed( motor = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the speed of a wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_speed" ].value
		},
	},
	{
		label: "BOT Wheel Turn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Wheel Turn angle = ${1:value1}, speed = ${2:value2}, motor = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the angle the wheel connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_turn" ].value
		},
	},
	{
		label: "BOT Wheel Angle",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Wheel Angle( motor = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the angle of the wheel connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_angle" ].value
		},
	},
	{
		label: "BOT Wheel Reset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Wheel Reset motor = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resets the angle of the wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_reset" ].value
		},
	},
	{
		label: "BOT Wheel Lock",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Wheel Lock motor = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enables the self-locking function of a wheel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wheel_lock" ].value
		},
	},
	{
		label: "BOT Motor Speed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Motor Speed speed = ${1:value1}, port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the rotating speed of a motor connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_motor_speed" ].value
		},
	},
	{
		label: "BOT Motor Speed",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = BOT Motor Speed( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the speed of the motor connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_motor_speed" ].value
		},
	},
	{
		label: "BOT Motor Power",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Motor Power power1 = ${1:value1}, power2 = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the power output of the motors connected to ports M1 and M2',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_motor_power" ].value
		},
	},
	{
		label: "BOT Motor Power",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Motor Power( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the power output of the motor connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_motor_power" ].value
		},
	},
	{
		label: "BOT Motor Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Motor Stop port = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the power output of the motor connected to the specified port to zero',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_motor_stop" ].value
		},
	},
	{
		label: "BOT Servo Angle",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Servo Angle angle = "${1:value1}", port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the angle of the servo connected to a given port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_servo_angle" ].value
		},
	},
	{
		label: "BOT Servo Angle",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Servo Angle( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the angle of the servo connected to a given port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_servo_angle" ].value
		},
	},
	{
		label: "BOT Servo Release",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Servo Release port = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Releases the angle of the servo connected to the specified port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_servo_release" ].value
		},
	},
	{
		label: "BOT Servo Drive",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Servo Drive angle1 = ${1:value1}, angle2 = ${2:value2}, angle3 = ${3:value3}, angle4 = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the angles of the servos connected to ports 1 to 4 at the same time',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_servo_drive" ].value
		},
	},
	{
		label: "BOT Print",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Print text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print a message on the screen of the Bot with automatic line breaks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_print" ].value
		},
	},
	{
		label: "BOT PrintLn",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT PrintLn text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print a message on the screen of the Bot with forced line breaks',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_println" ].value
		},
	},
	{
		label: "BOT Label Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Label Show text$ = "${1:value1}", size = ${2:value2}, x = ${3:value3}, y = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Displays a text in the specified position of Bot\'s screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_label_show" ].value
		},
	},
	{
		label: "BOT Display Rotate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Display Rotate angle = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets Bot\'s screen to the specified displaying direction (angles)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_display_rotate" ].value
		},
	},
	{
		label: "BOT Display Clear",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Display Clear',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Clears the display of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_display_clear" ].value
		},
	},
	{
		label: "BOT Display Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Display Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turns the display of the Bot off',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_display_off" ].value
		},
	},
	{
		label: "BOT Controller",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Controller( name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether the specified Bot button is pressed or the joystick is moved to the specified direction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_controller" ].value
		},
	},
	{
		label: "BOT Controller",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Controller( name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the number of times the specified Bot button is pressed or the joystick is moved to the specified direction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_controller" ].value
		},
	},
	{
		label: "BOT Controller Reset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Controller Reset name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resets the number of times the specified Bot button is pressed or the joystick is moved to the specified direction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_controller_reset" ].value
		},
	},
	{
		label: "BOT Brightness",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Brightness()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the ambient light brightness detected by the light sensor on Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_brightness" ].value
		},
	},
	{
		label: "BOT Loudness",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Loudness( mode$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the ambient sound loudness detected by the microphone on the Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_loudness" ].value
		},
	},
	{
		label: "BOT Tilt",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Tilt( direction$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether Bot is tilted forward',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_tilt" ].value
		},
	},
	{
		label: "BOT Face",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Face( direction$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether Bot is facing up or down',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_face" ].value
		},
	},
	{
		label: "BOT Stand",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Stand()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether Bot is placed perpendicular to the ground, with the LED strip on the bottom.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_stand" ].value
		},
	},
	{
		label: "BOT Handstand",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Handstand()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether Bot is placed perpendicular to the ground, with the LED strip on the top.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_handstand" ].value
		},
	},
	{
		label: "BOT Shake",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Shake()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determines whether Bot is shaken and returns the shaking strenght',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_shake" ].value
		},
	},
	{
		label: "BOT Wave",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Wave( direction$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Determine whether Bot is waved and returns the waving strength.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wave" ].value
		},
	},
	{
		label: "BOT Wave Speed",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Wave Speed()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the speed of the last wave.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_wave_speed" ].value
		},
	},
	{
		label: "BOT Pitch",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Pitch()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current pitch of the connected Bot.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_pitch" ].value
		},
	},
	{
		label: "BOT Roll",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Roll()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current roll angle of the connected Bot.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_roll" ].value
		},
	},
	{
		label: "BOT Yaw",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Yaw()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current yaw angle of the connected Bot.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_yaw" ].value
		},
	},
	{
		label: "BOT Reset Yaw",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Reset Yaw',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resets the yaw angle.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_reset_yaw" ].value
		},
	},
	{
		label: "BOT Accelerometer",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = BOT Accelerometer( axis$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the output (in m/s²) of the accelerometer on the specified axis.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_accelerometer" ].value
		},
	},
	{
		label: "BOT Gyro",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Gyro( axis$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the angular speed around the specified axis.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_gyro" ].value
		},
	},
	{
		label: "BOT Pin Analog",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Pin Analog level = ${1:value1}, port = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the digital input for the specified pin(s).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_pin_analog" ].value
		},
	},
	{
		label: "BOT Pin Analog",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = BOT Pin Analog( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the voltage at the specified pin',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_pin_analog" ].value
		},
	},
	{
		label: "BOT Pin",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = BOT Pin( port = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the digital input at the specified pin.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_pin" ].value
		},
	},
	{
		label: "BOT Pin Pwm",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT Pin Pwm duty = ${1:value1}, frequency = ${2:value2}, port = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the specified pin(s) to output PWM signals with the specified frequency and duty cycle.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_pin_pwm" ].value
		},
	},
	{
		label: "BOT Mac Address$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = BOT Mac Address$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the media access control address (MAC address) of the Wi-Fi',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_mac_address$" ].value
		},
	},
	{
		label: "BOT BotName$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = BOT BotName$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the name of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_botname$" ].value
		},
	},
	{
		label: "BOT BotName$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'BOT BotName$',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Changes the name of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_botname$" ].value
		},
	},
	{
		label: "BOT Language$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = BOT Language$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Obtains the current language of the connected Bot',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "bot_language$" ].value
		},
	},
	{
		label: "OS Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = OS Name$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the OS currently in use (abbreviated)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "os_name$" ].value
		},
	},
	{
		label: "Manifest$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Manifest$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the name of the selected manifest platform.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "manifest$" ].value
		},
	},
	{
		label: "Browser Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Name$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the simple name of the browser in which an AOZ application is running.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_name$" ].value
		},
	},
	{
		label: "Browser Language$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Language$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the language used by the client browser.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_language$" ].value
		},
	},
	{
		label: "Browser Version$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Version$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the version of the client browser.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_version$" ].value
		},
	},
	{
		label: "Browser Agent$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Agent$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the full userAgent of the client browser.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_agent$" ].value
		},
	},
	{
		label: "Browser Engine$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Engine$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the code engine on which the browser is running.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_engine$" ].value
		},
	},
	{
		label: "Browser Ancestor$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Ancestor$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the browser\'s ancestral name.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_ancestor$" ].value
		},
	},
	{
		label: "Browser CodeName$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser CodeName$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the code name for the browser.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_codename$" ].value
		},
	},
	{
		label: "Browser Online$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Online$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns a boolean, true if application is online.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_online$" ].value
		},
	},
	{
		label: "Browser Platform$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Browser Platform$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the OS platform where the browser is running.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "browser_platform$" ].value
		},
	},
	{
		label: "Geo Latitude",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Geo Latitude()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the geographic Latitude of the device.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "geo_latitude" ].value
		},
	},
	{
		label: "Geo Longitude",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Geo Longitude()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Function that returns the geographic Latitude of the device.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "geo_longitude" ].value
		},
	},
	{
		label: "Open URL",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open URL URL$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a URL in window',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_url" ].value
		},
	},
	{
		label: "Companion Connect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Connect onMessage$ = "${1:value1}", onError$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Establishes the connection with a local server, if it is running.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_connect" ].value
		},
	},
	{
		label: "Companion Disconnect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Disconnect',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a running connection to the companion server.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_disconnect" ].value
		},
	},
	{
		label: "Companion Open API",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Open API ${1:index}, name$ = "${2:value2}", authorization$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open an API implemented in the Companion Server',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_open_api" ].value
		},
	},
	{
		label: "Companion Close API",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Close API ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close an API that was previously open',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_close_api" ].value
		},
	},
	{
		label: "Companion Call API",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Call API ${1:index}, command$ = "${2:value2}", param = ${3:value3}, extra = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send a command to the channel of the Companion server\'s API. This function does not wait for the end of the execution of the command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_call_api" ].value
		},
	},
	{
		label: "Companion Execute Command$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Companion Execute Command$ ${1:index}, command$ = "${2:value2}", param = ${3:value3}, extra = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send a command to the channel of the Companion server\'s API, wait for the response and return the response as it was sent by the server.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "companion_execute_command$" ].value
		},
	},
	{
		label: "CSV Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Open ${1:index}, width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a CSV channel, enabling you to load, create and later save CSV files',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_open" ].value
		},
	},
	{
		label: "CSV Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Load ${1:index}, path$ = "${2:value2}", tags$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a CSV file iinto the channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_load" ].value
		},
	},
	{
		label: "CSV Save",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Save ${1:index}, path$ = "${2:value2}", tags$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save the content of the channel into a CSV file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_save" ].value
		},
	},
	{
		label: "CSV Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys a channel and all data associated',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_close" ].value
		},
	},
	{
		label: "CSV Column$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CSV Column$( inded = ${2:value2}, column = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the title of a column as a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_column$" ].value
		},
	},
	{
		label: "CSV Get$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CSV Get$( ${2:index}, column = ${3:value3}, line = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the content of a cell as a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_get$" ].value
		},
	},
	{
		label: "CSV Get",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = CSV Get( ${2:index}, column = ${3:value3}, line = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the content of a cell as a number, or 0 if the content cannot be converted to a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_get" ].value
		},
	},
	{
		label: "CSV Get Ascii$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CSV Get Ascii$( ${2:index}, column = ${3:value3}, line = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Extracts and return the text content of a cell as string. Returns an empty string if there is no ttext. Use it for cells indicating a currency, like the ones generated by Excel with 1000$ or such',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_get_ascii$" ].value
		},
	},
	{
		label: "CSV String$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = CSV String$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the whole content of a channel as an exportable CSV formatted UTF-8 string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_string$" ].value
		},
	},
	{
		label: "CSV Column",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Column ${1:index}, column = ${2:value2}, title$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the title of a column',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_column" ].value
		},
	},
	{
		label: "CSV Set$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Set$ ${1:index}, column = ${2:value2}, line = ${3:value3}, text$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the content of a cell from a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_set$" ].value
		},
	},
	{
		label: "CSV Set",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Set ${1:index}, column = ${2:value2}, line = ${3:value3}, number = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the content of a cell from a number',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_set" ].value
		},
	},
	{
		label: "CSV Erase",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'CSV Erase ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset a CSV channel, all data will be erased',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_erase" ].value
		},
	},
	{
		label: "CSV Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = CSV Width( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of columns of a channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_width" ].value
		},
	},
	{
		label: "CSV Height",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = CSV Height( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of lines of a channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "csv_height" ].value
		},
	},
	{
		label: "Set DB Service",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set DB Service',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the Database Service configuration',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_db_service" ].value
		},
	},
	{
		label: "DB Query",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Query, Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Asynchrone request execution from the database',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_query" ].value
		},
	},
	{
		label: "DB Query Sync",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Query Sync Id$ = "${1:value1}", Name$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Synchrone request execution from the database. The program will be paused as long as the request is not completed( success or error )',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_query_sync" ].value
		},
	},
	{
		label: "DB Count Rows",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = DB Count Rows()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of rows got from the last request called.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_count_rows" ].value
		},
	},
	{
		label: "DB Count Columns",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = DB Count Columns()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of columns got from the last request called',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_count_columns" ].value
		},
	},
	{
		label: "DB Column Name$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = DB Column Name$(, numCol = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of the column <numCol>.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_column_name$" ].value
		},
	},
	{
		label: "DB Column Type$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = DB Column Type$(, numCol = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the type of the column <numCol>.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_column_type$" ].value
		},
	},
	{
		label: "DB Column Value$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = DB Column Value$(, Colname$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of the column ColName$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_column_value$" ].value
		},
	},
	{
		label: "DB Column Value",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = DB Column Value(, Colname$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of the column ColName$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_column_value" ].value
		},
	},
	{
		label: "DB Column Value#",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = DB Column Value#()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of the column ColName$',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_column_value#" ].value
		},
	},
	{
		label: "DB Next Row",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Next Row',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Moves the seeker to the next row.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_next_row" ].value
		},
	},
	{
		label: "DB Goto Row",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'DB Goto Row, row = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Moves the seeker to the number of row.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "db_goto_row" ].value
		},
	},
	{
		label: "Debug On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Debug On visible = ${1:value1}, console = ${2:value2}, watch = ${3:value3}, source = ${4:value4}, bobs = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialize the debugging system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "debug_on" ].value
		},
	},
	{
		label: "Debug Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Debug Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the debugging system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "debug_off" ].value
		},
	},
	{
		label: "Log",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Log',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print a text to the debugger console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "log" ].value
		},
	},
	{
		label: "Watch",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Watch any = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add a variable or an expression to watch in the debugger',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "watch" ].value
		},
	},
	{
		label: "Break",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Break',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the application and steps into the code',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "break" ].value
		},
	},
	{
		label: "Break If",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Break If any = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the application and steps into the code if the result of an expression is true (or different from zero)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "break_if" ].value
		},
	},
	{
		label: "AOZ Hide",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'AOZ Hide',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Hide the AOZ display canvas',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "aoz_hide" ].value
		},
	},
	{
		label: "AOZ Show",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'AOZ Show',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show the AOZ display canvas',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "aoz_show" ].value
		},
	},
	{
		label: "Dom Element",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Element elementType$ = "${1:value1}", elementId$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_element" ].value
		},
	},
	{
		label: "Dom Attribute",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Attribute elementId$ = "${1:value1}", attribute$ = "${2:value2}", value$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set an attribute of a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_attribute" ].value
		},
	},
	{
		label: "Dom Attribute",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dom Attribute( elementId$ = "${2:value2}", attribute$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the value of a attribute of a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_attribute" ].value
		},
	},
	{
		label: "Dom Add",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Add childId$ = "${1:value1}", parentId$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add an HTML element create with "Dom Create" command into an other HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_add" ].value
		},
	},
	{
		label: "Dom Content",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Content elementId$ = "${1:value1}", html$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the content of a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_content" ].value
		},
	},
	{
		label: "Dom Content",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dom Content( elementId$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the code HTML contains in a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_content" ].value
		},
	},
	{
		label: "Dom Value",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Value elementId$ = "${1:value1}", value$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'If the HTML Element is a element of form (input, list, radio, option...), you can to set the value of this element (text, selected...)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_value" ].value
		},
	},
	{
		label: "Dom Value",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dom Value( elementId$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'If the HTML Element is a element of form (input, list, radio, option...), return this value (text, selected...)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_value" ].value
		},
	},
	{
		label: "Dom Event",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Event elementId$ = "${1:value1}", eventName$ = "${2:value2}", procName$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Link an Event Listener on a HTML Element with a AOZ Procedure',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_event" ].value
		},
	},
	{
		label: "Load CSS",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load CSS url$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a file CSS to add styles rules',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_css" ].value
		},
	},
	{
		label: "Load CSS",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load CSS url$ = "${1:value1}", media$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a file CSS to add styles rules for a specific media ("screen", "print"...)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_css" ].value
		},
	},
	{
		label: "Add CSS Rule",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Add CSS Rule cssRule$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Add a CSS Rule directly in AOZ code',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "add_css_rule" ].value
		},
	},
	{
		label: "Load JS",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load JS url$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a Javascript file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_js" ].value
		},
	},
	{
		label: "Dom Visible",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Visible elementId$ = "${1:value1}", visible = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show/Hide a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_visible" ].value
		},
	},
	{
		label: "Dom Position",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Position elementId$ = "${1:value1}", x = ${2:value2}, y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the Position of a HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_position" ].value
		},
	},
	{
		label: "Dom Position",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Position elementId$ = "${1:value1}", X = ${2:value2}, Y = ${3:value3}, unitMeasure$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the Position of a HTML element with a specific measeure unit (\'px\', \'pt\', \'em\', \'%\'...)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_position" ].value
		},
	},
	{
		label: "Dom Size",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Size elementId$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resize a HTML element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_size" ].value
		},
	},
	{
		label: "Dom Size",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Size elementId$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}, unitMeasure$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resize a HTML element with a specific measeure unit (\'px\', \'pt\', \'em\', \'%\'...)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_size" ].value
		},
	},
	{
		label: "Dom Enabled",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Enabled elementId$ = "${1:value1}", enabled = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show/Hide a HTML Element',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_enabled" ].value
		},
	},
	{
		label: "Dom Button",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Button elementId$ = "${1:value1}", label$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a HTML Button',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_button" ].value
		},
	},
	{
		label: "Dom Button",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Button elementId$ = "${1:value1}", label$ = "${2:value2}", className$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a HTML Button with CSS Rules',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_button" ].value
		},
	},
	{
		label: "Dom Layer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Layer elementId$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a HTML Layer (div)',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_layer" ].value
		},
	},
	{
		label: "Dom Button",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dom Button elementId$ = "${1:value1}", className$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create a HTML Button with CSS Rules',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dom_button" ].value
		},
	},
	{
		label: "JS Execute",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JS Execute javascript$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Executes the Javascript code defined in the parameter string..',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "js_execute" ].value
		},
	},
	{
		label: "IDE End Accessory",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE End Accessory',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Terminates the current application if it is running as an accessory, and restore the previous running one',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_end_accessory" ].value
		},
	},
	{
		label: "IDE Connect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Connect TAGS = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Connects to the IDE',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_connect" ].value
		},
	},
	{
		label: "IDE Disconnect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Disconnect TAGS = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the connection with the IDE',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_disconnect" ].value
		},
	},
	{
		label: "IDE Is Connected",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = IDE Is Connected()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Indicates if the application is connected to the IDE',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_is_connected" ].value
		},
	},
	{
		label: "IDE Send Command",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Send Command command$ = "${1:value1}", param1$ = "${2:value2}", param2$ = "${3:value3}", param3$ = "${4:value4}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send a command to the IDE',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_send_command" ].value
		},
	},
	{
		label: "IDE Commands",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = IDE Commands()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts the exploration of the last messages, and switch to the next one if more the next times it is called. Old messages are discarted.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_commands" ].value
		},
	},
	{
		label: "IDE Command$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = IDE Command$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the command of the current message',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_command$" ].value
		},
	},
	{
		label: "IDE Responses",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = IDE Responses()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts the exploration of the last responses to one, or all messages, in order of reception, and switch to the next one if more the next times it is called. Old reponses are discarted.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_responses" ].value
		},
	},
	{
		label: "IDE Response$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = IDE Response$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the current reponse',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_response$" ].value
		},
	},
	{
		label: "IDE Property$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = IDE Property$( path$ = "${2:value2}", default$ = "${3:value3}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns one of the string properties of the last message',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_property$" ].value
		},
	},
	{
		label: "IDE Property",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = IDE Property( path$ = "${2:value2}", default = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the value of one of the properties of the last message',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_property" ].value
		},
	},
	{
		label: "IDE Property Type$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = IDE Property Type$( path$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the type one of the property of the last message as a string',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_property_type$" ].value
		},
	},
	{
		label: "PSel$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = PSel$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Used to handle multiple applications in the AMOS IDE. Will not be implemented.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "psel$" ].value
		},
	},
	{
		label: "IDE Copy Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Copy Text text$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Copy a string into the clipboard',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_copy_text" ].value
		},
	},
	{
		label: "IDE Insert Text",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Insert Text text$ = "${1:value1}", tags$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Insert a string at the position of the cursor',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_insert_text" ].value
		},
	},
	{
		label: "IDE Resize Panel",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Resize Panel panel$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Resize an IDE panel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_resize_panel" ].value
		},
	},
	{
		label: "IDE Move Panel",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Move Panel panel$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Move an IDE panel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_move_panel" ].value
		},
	},
	{
		label: "IDE Destroy Panel",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'IDE Destroy Panel panel$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close an IDE panel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "ide_destroy_panel" ].value
		},
	},
	{
		label: "JSON Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Open ${1:index}, tags = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a JSON channel, enabling you to load or create JSON files',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_open" ].value
		},
	},
	{
		label: "JSON Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys a JSON channel and all data associated',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_close" ].value
		},
	},
	{
		label: "JSON Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Load ${1:index}, path$ = "${2:value2}", tags$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a json file into the channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_load" ].value
		},
	},
	{
		label: "JSON Save",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Save ${1:index}, path$ = "${2:value2}", tags$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Save the resulting stringifies JSON string as a file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_save" ].value
		},
	},
	{
		label: "JSON New",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON New ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset the JSON channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_new" ].value
		},
	},
	{
		label: "JSON Parse",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Parse ${1:index}, string$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'parse a string into JSON',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_parse" ].value
		},
	},
	{
		label: "JSON Stringify$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Stringify$ ${1:index}, string$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'parse a string into JSON',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_stringify$" ].value
		},
	},
	{
		label: "JSON Property",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JSON Property( ${2:index}, path$ = "${3:value3}", defaultValue = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a numeric value from the JSON file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_property" ].value
		},
	},
	{
		label: "JSON Property$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = JSON Property$( ${2:index}, path$ = "${3:value3}", defaultValue$ = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a string value from the JSON file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_property$" ].value
		},
	},
	{
		label: "JSON Set Property$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Set Property$ ${1:index}, path$ = "${2:value2}", value$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set a string value in the JSON file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_set_property$" ].value
		},
	},
	{
		label: "JSON Set Property",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'JSON Set Property ${1:index}, path$ = "${2:value2}", value = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set a numerical value in the JSON file',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "json_set_property" ].value
		},
	},
	{
		label: "Load Map",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Map path$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a map file. The file must be one of these formats',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_map" ].value
		},
	},
	{
		label: "Load Tileset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load Tileset path$ = "${1:value1}", firstImage = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load all the tiles from a map file, and create the associated images in the image bank to use as tiles.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_tileset" ].value
		},
	},
	{
		label: "Create Tileset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Create Tileset name$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}, firstImage = ${4:value4}, numberOfImages = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create an empty tileset, each tile being associated to it\'s number plus the first image in the image bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "create_tileset" ].value
		},
	},
	{
		label: "View Map$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = View Map$( viewName$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the name of map associated to a map view.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_map$" ].value
		},
	},
	{
		label: "Set View Map",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set View Map viewName$ = "${1:value1}", mapName$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Link a map to a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_view_map" ].value
		},
	},
	{
		label: "View Layer",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = View Layer( mapName$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the current layer displayed by a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_layer" ].value
		},
	},
	{
		label: "Set View Layer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set View Layer viewName$ = "${1:value1}", layerIndex = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the index of the current layer of the map in a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_view_layer" ].value
		},
	},
	{
		label: "Map Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Map Width viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of tiles of the map referred to by a map view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "map_width" ].value
		},
	},
	{
		label: "Map Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Map Height viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of tiles in height of a map view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "map_height" ].value
		},
	},
	{
		label: "View Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'View Width viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width of a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_width" ].value
		},
	},
	{
		label: "View Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'View Height viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the height of a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_height" ].value
		},
	},
	{
		label: "Tile Width",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Tile Width viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the width of a tile',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_width" ].value
		},
	},
	{
		label: "Tile Height",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Tile Height viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the height of a tile',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_height" ].value
		},
	},
	{
		label: "Replace Tiles",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Replace Tiles viewName$ = "${1:value1}", tileSearch = ${2:value2}, tileReplace = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Replace all the tiles in the view with another tile',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "replace_tiles" ].value
		},
	},
	{
		label: "Reset Maps",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Reset Maps',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset the maps set and clean all the maps data',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "reset_maps" ].value
		},
	},
	{
		label: "Push View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Push View viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Store the actual state of a map view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "push_view" ].value
		},
	},
	{
		label: "Pop View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Pop View viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Restore the state of a map view stored by "View Push" command',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "pop_view" ].value
		},
	},
	{
		label: "Open View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open View viewName$ = "${1:value1}", mapIndex$ = "${2:value2}", tileSetIndex$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a view to display a map on the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_view" ].value
		},
	},
	{
		label: "Close View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Close View viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a map Open Viewed with the "Open View" commands.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "close_view" ].value
		},
	},
	{
		label: "Layer Count",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Layer Count viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of layers in the view\'s map',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "layer_count" ].value
		},
	},
	{
		label: "Offset View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Offset View viewName$ = "${1:value1}", X = ${2:value2}, Y = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Scrolls the map into the view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "offset_view" ].value
		},
	},
	{
		label: "Display View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Display View viewName$ = "${1:value1}", X = ${2:value2}, Y = ${3:value3}, width = ${4:value4}, height = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the position and the size of the view on the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "display_view" ].value
		},
	},
	{
		label: "View Offset X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = View Offset X( viewName$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_offset_x" ].value
		},
	},
	{
		label: "View Offset Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = View Offset Y( viewName$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: '',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "view_offset_y" ].value
		},
	},
	{
		label: "Redraw View",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Redraw View viewName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Redraw the map view on the screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "redraw_view" ].value
		},
	},
	{
		label: "Resize Tiles",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Resize Tiles viewName$ = "${1:value1}", width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the width and height of the tiles in pixel for a specific view, does not affect the original tiles',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "resize_tiles" ].value
		},
	},
	{
		label: "Tile Count",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile Count( viewName$ = "${2:value2}", tileValue = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of a specific tile in a map view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_count" ].value
		},
	},
	{
		label: "Tile First",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile First( viewName$ = "${2:value2}", tileValue = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the first tile on the map view with this value',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_first" ].value
		},
	},
	{
		label: "Tile Next",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile Next()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the next number of tile on the map view after a call to the "Tile First" command.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_next" ].value
		},
	},
	{
		label: "Tile X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile X( viewName$ = "${2:value2}", tileIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the horizontal position in pixel of a tile in the map view.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_x" ].value
		},
	},
	{
		label: "Tile Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile Y( viewName$ = "${2:value2}", tileIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the vertical position in pixel of a tile in the map view.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_y" ].value
		},
	},
	{
		label: "Tile Test",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Tile Test( viewName$ = "${2:value2}", x = ${3:value3}, y = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of tile at the position x and y on a map view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_test" ].value
		},
	},
	{
		label: "Set Tile Image",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Tile Image viewName$ = "${1:value1}", tileIndex = ${2:value2}, imageIndex = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the image of a specific tile of a Map View',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_tile_image" ].value
		},
	},
	{
		label: "Tile Image",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile Image( viewName$ = "${2:value2}", tileIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the index of the image used to display a tile in the view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_image" ].value
		},
	},
	{
		label: "Set Tile Value",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Tile Value viewName$ = "${1:value1}", tileIndex = ${2:value2}, imageIndex = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the value of a specifc tile in a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_tile_value" ].value
		},
	},
	{
		label: "Tile Value",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Tile Value( viewName$ = "${2:value2}", tileIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of a specific tile in the map',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_value" ].value
		},
	},
	{
		label: "Set Tile String",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Tile String viewName$ = "${1:value1}", tileIndex = ${2:value2}, string$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the internal string of a specifc tile in a view',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_tile_string" ].value
		},
	},
	{
		label: "Tile String$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Tile String$( viewName$ = "${2:value2}", tileIndex = ${3:value3})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the string associated with a specific tile in the map',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tile_string$" ].value
		},
	},
	{
		label: "Music",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Music number = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a piece of MIDI music. Playing AMOS musics is not yet implemented.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "music" ].value
		},
	},
	{
		label: "Music Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Music Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop a MIDI music.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "music_stop" ].value
		},
	},
	{
		label: "Music Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Music Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn off all music',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "music_off" ].value
		},
	},
	{
		label: "MVolume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'MVolume',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the volume of a piece of music. May not be implemented in favor of tracker musics.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mvolume" ].value
		},
	},
	{
		label: "Tempo",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Tempo tempo = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change the speed of a piece of music.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "tempo" ].value
		},
	},
	{
		label: "Touch Screen",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Touch Screen()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if the device screen is touch sensitive, otherwise False.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_screen" ].value
		},
	},
	{
		label: "Touch Count",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Touch Count()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of touches on the device\'s touch screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_count" ].value
		},
	},
	{
		label: "Touch X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Touch X( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the horizontal position of a touch on the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_x" ].value
		},
	},
	{
		label: "Touch Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Touch Y( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the vertical position of a touch on the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_y" ].value
		},
	},
	{
		label: "Touch State",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Touch State( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the state of a touch on the screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_state" ].value
		},
	},
	{
		label: "Touch On Change",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Touch On Change procName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign an AOZ procedure to touch-screen events.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "touch_on_change" ].value
		},
	},
	{
		label: "Orientable",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Orientable()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if the device screen is orientable, otherwise False.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "orientable" ].value
		},
	},
	{
		label: "Orientation X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Orientation X()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the X angle of the orientation screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "orientation_x" ].value
		},
	},
	{
		label: "Orientation Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Orientation Y()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Y angle of the orientation screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "orientation_y" ].value
		},
	},
	{
		label: "Orientation Z",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Orientation Z()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the Z angle of the orientation screen.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "orientation_z" ].value
		},
	},
	{
		label: "Accelerometer",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Accelerometer()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if the device has an accelerometer, otherwise False.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "accelerometer" ].value
		},
	},
	{
		label: "Acceleration X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Acceleration X()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the acceleration value on the X-axis of the device.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "acceleration_x" ].value
		},
	},
	{
		label: "Acceleration Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Acceleration Y()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the acceleration value on the Y-axis of the device.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "acceleration_y" ].value
		},
	},
	{
		label: "Acceleration Z",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Acceleration Z()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the acceleration value on the Z-axis of the device.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "acceleration_z" ].value
		},
	},
	{
		label: "Orientation",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Orientation()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the orientation of the device. 0=Landscape, 1=Portrait',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "orientation" ].value
		},
	},
	{
		label: "Lock Orientation",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Lock Orientation orientation$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Locks the orientation of the device',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lock_orientation" ].value
		},
	},
	{
		label: "Call Service",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Call Service, url$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a web service by RTC protocole ans send it parameters.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "call_service" ].value
		},
	},
	{
		label: "Load HTML",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Load HTML url$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a HTML file and add it to application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "load_html" ].value
		},
	},
	{
		label: "QRCode Create",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'QRCode Create text$ = "${1:value1}", imageIndex = ${2:value2}, width = ${3:value3}, height = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Create an QRCode image from a text and assign it to an image number.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_create" ].value
		},
	},
	{
		label: "QRCode Scan",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'QRCode Scan cameraId$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Setting up the QR Code reader.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_scan" ].value
		},
	},
	{
		label: "QRCode Scan On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'QRCode Scan On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Starts the QR Code reader.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_scan_on" ].value
		},
	},
	{
		label: "QRCode Scan Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'QRCode Scan Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stops the QR Code reader.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_scan_off" ].value
		},
	},
	{
		label: "QRCode Scan$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = QRCode Scan$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retrieves the text of the QR Code retrieved by the reader.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_scan$" ].value
		},
	},
	{
		label: "QRCode Get Camera",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'QRCode Get Camera imageIndex = ${1:value1}, width = ${2:value2}, height = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Store the image of the camera into the images banks, at the image number IMAGE.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "qrcode_get_camera" ].value
		},
	},
	{
		label: "Renderer Filter",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Renderer Filter filterName$ = "${1:value1}", value1# = ${2:value2}, value2# = ${3:value3}, value3# = ${4:value4}, value4# = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set or add a graphical filter to the whole application',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "renderer_filter" ].value
		},
	},
	{
		label: "Renderer Filter Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Renderer Filter Del filterName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Remove a specific or all filters.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "renderer_filter_del" ].value
		},
	},
	{
		label: "Renderer Filter$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Renderer Filter$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the CSS string used by Javascript in the context.filter property.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "renderer_filter$" ].value
		},
	},
	{
		label: "Renderer Filter",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Renderer Filter( paramNumber = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the current value of the given filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "renderer_filter" ].value
		},
	},
	{
		label: "Open File Dialog",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open File Dialog',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show The AOZ Open Files Dialog Box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_file_dialog" ].value
		},
	},
	{
		label: "Save File Dialog",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Save File Dialog title$ = "${1:value1}", filter$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show The AOZ Save Files Dialog Box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "save_file_dialog" ].value
		},
	},
	{
		label: "",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: ' title$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Show The AOZ Directories Dialog Box',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "" ].value
		},
	},
	{
		label: "REXX Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'REXX Open portName$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open an REXX communication port. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx_open" ].value
		},
	},
	{
		label: "REXX Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'REXX Close',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close the REXX communication port. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx_close" ].value
		},
	},
	{
		label: "REXX Wait",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'REXX Wait',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Wait for a message from an AREXX program. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx_wait" ].value
		},
	},
	{
		label: "REXX Answer",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'REXX Answer value = ${1:value1}, return$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'answer a message from an AREXX program. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx_answer" ].value
		},
	},
	{
		label: "REXX$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = REXX$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a message from an REXX program. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx$" ].value
		},
	},
	{
		label: "REXX",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = REXX()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get a message from an REXX program. Maybe implemented later.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "rexx" ].value
		},
	},
	{
		label: "Robot Connect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Robot Connect onMessage$ = "${1:value1}", onError$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Establishes the connection with a local server, if it is running.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "robot_connect" ].value
		},
	},
	{
		label: "Robot Disconnect",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Robot Disconnect',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a running connection to the companion server.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "robot_disconnect" ].value
		},
	},
	{
		label: "LMS Debug On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Debug On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn On the SCORM debugger.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_debug_on" ].value
		},
	},
	{
		label: "LMS Debug Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Debug Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Turn Off the SCORM debugger.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_debug_off" ].value
		},
	},
	{
		label: "LMS Initialize",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Initialize',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Find and start the SCORM API and initialize the tracking.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_initialize" ].value
		},
	},
	{
		label: "LMS Value$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = LMS Value$( keyword$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of a SCORM variable from the API.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_value$" ].value
		},
	},
	{
		label: "LMS Value",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Value keyword$ = "${1:value1}", value$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of a SCORM variable.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_value" ].value
		},
	},
	{
		label: "LMS Error$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = LMS Error$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the code of the last error of SCORM from the API.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_error$" ].value
		},
	},
	{
		label: "LMS Error Text$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = LMS Error Text$( code$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the text of an error from this code.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_error_text$" ].value
		},
	},
	{
		label: "LMS Diagnostic$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = LMS Diagnostic$( code$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the text of the diagnostic for an error.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_diagnostic$" ].value
		},
	},
	{
		label: "LMS Commit",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Commit',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Commit all the SCORM variables on the Learning System.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_commit" ].value
		},
	},
	{
		label: "LMS Finish",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'LMS Finish',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop all communication with the SCORM API and the Learning System.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lms_finish" ].value
		},
	},
	{
		label: "Sfx To",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx To voice = ${1:value1}, ${2:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Assign Sfx to sound voice channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_to" ].value
		},
	},
	{
		label: "Sfx Del",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Del ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Delete a previously created sound effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_del" ].value
		},
	},
	{
		label: "Sfx Mutate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Mutate ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Applies slight random changes to the parameters of a sound',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_mutate" ].value
		},
	},
	{
		label: "Sfx Json$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Json$ ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the JSON string containing the parameters of the given sound',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_json$" ].value
		},
	},
	{
		label: "Sfx Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Play string = ${1:value1}, ${2:index}, string = ${3:value3}, string = ${4:value4}, float = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Plays a given sound effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_play" ].value
		},
	},
	{
		label: "Sfx Create",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Create ${1:index}, ${2:index}, string = ${3:value3}, string = ${4:value4}, string = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Creates a sound effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_create" ].value
		},
	},
	{
		label: "Sfx Set",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Sfx Set ${1:index}, string = ${2:value2}, any = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the value of one of the properties of a sound effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_set" ].value
		},
	},
	{
		label: "Sfx Get",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Sfx Get( ${2:index}, string = ${3:value3}, any = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the value of one of the properties of a sound effect',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "sfx_get" ].value
		},
	},
	{
		label: "HSlider",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'HSlider x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, max = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialize the debugging system',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "hslider" ].value
		},
	},
	{
		label: "VSlider",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'VSlider x1 = ${1:value1}, y1 = ${2:value2}, x2 = ${3:value3}, y2 = ${4:value4}, max = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Display a vertical slider',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "vslider" ].value
		},
	},
	{
		label: "Set Slider",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Slider paper1 = ${1:value1}, outline1 = ${2:value2}, pattern1 = ${3:value3}, paper2 = ${4:value4}, outline2 = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Define the colors and patterns of the next created slider',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_slider" ].value
		},
	},
	{
		label: "Editor Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Editor Open ${1:index}, string = ${2:value2}, integer = ${3:value3}, integer = ${4:value4}, integer = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a new source editor in the current Text Window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_open" ].value
		},
	},
	{
		label: "Editor Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Editor Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close an open editor and removes it from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_close" ].value
		},
	},
	{
		label: "Editor NLines",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Editor NLines ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of line of the current text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_nlines" ].value
		},
	},
	{
		label: "Editor Findline",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Editor Findline ${1:index}, string = ${2:value2}, integer = ${3:value3}, boolean = ${4:value4}, boolean = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Search the text for a given string and return the number of the line containing the text',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_findline" ].value
		},
	},
	{
		label: "Editor Getword$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Editor Getword$( ${2:index}, integer = ${3:value3}, integer = ${4:value4}, string = ${5:value5})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a given word on a given line',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_getword$" ].value
		},
	},
	{
		label: "Editor Getline$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Editor Getline$( ${2:index}, integer = ${3:value3}, string = ${4:value4})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a given line',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "editor_getline$" ].value
		},
	},
	{
		label: "Console Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Open ${1:index}, string = ${2:value2}, integer = ${3:value3}, integer = ${4:value4}, integer = ${5:value5}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a new console window in the current Text Window in the current screen',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_open" ].value
		},
	},
	{
		label: "Console Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close an open console and removes it from memory',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_close" ].value
		},
	},
	{
		label: "Console Print",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Print ${1:index}, string = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print information in the console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_print" ].value
		},
	},
	{
		label: "Console Nextline",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Nextline ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Print a new prompt line in the console',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_nextline" ].value
		},
	},
	{
		label: "Console Command$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Command$ ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the text entered by the user when he presses <ENTER>',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_command$" ].value
		},
	},
	{
		label: "Speech Synthesis Allowed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Synthesis Allowed',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns true if speech output is allowed on client.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_synthesis_allowed" ].value
		},
	},
	{
		label: "Speech Recognition Allowed",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Recognition Allowed',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns True if speech recognition is permitted.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_allowed" ].value
		},
	},
	{
		label: "Speech Recognition Start",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Recognition Start',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enable the Speech recognition System',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_start" ].value
		},
	},
	{
		label: "Speech Recognition Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Recognition Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Disable the Speech recognition System',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_stop" ].value
		},
	},
	{
		label: "Speech Recognition Reset",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Recognition Reset',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Reset the Speech recognition System',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_reset" ].value
		},
	},
	{
		label: "Speech Recognition Value$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Speech Recognition Value$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retrieves the word or phrase spoken by the user as a string.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_value$" ].value
		},
	},
	{
		label: "Speech Recognition Add Word",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Recognition Add Word word$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Adds a word to the words array',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_recognition_add_word" ].value
		},
	},
	{
		label: "Speech Language",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Speech Language lang$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the language for the speech module',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "speech_language" ].value
		},
	},
	{
		label: "Voice Count",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice Count',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the number of different voices available.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_count" ].value
		},
	},
	{
		label: "Voice$",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice$ voice = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the name of the specified voice.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice$" ].value
		},
	},
	{
		label: "Set Voice",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Voice voice = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'sets the voice to the specified index.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_voice" ].value
		},
	},
	{
		label: "Voice Pitch",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice Pitch pitch# = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the pitch of the voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_pitch" ].value
		},
	},
	{
		label: "Voice Rate",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice Rate rate# = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the speed for speech synthesis',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_rate" ].value
		},
	},
	{
		label: "Voice Volume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Voice Volume volume# = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Sets the volume of the voice',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "voice_volume" ].value
		},
	},
	{
		label: "Say",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Say sentence$ = "${1:value1}", mode = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Speak a string using the build in voice synthetizer',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "say" ].value
		},
	},
	{
		label: "Set Talk",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Set Talk sex = ${1:value1}, mode = ${2:value2}, pitch# = ${3:value3}, rate = ${4:value4}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the style of the speech',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "set_talk" ].value
		},
	},
	{
		label: "Talk Misc",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Talk Misc volume# = ${1:value1}, frequency = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set volume of speech',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "talk_misc" ].value
		},
	},
	{
		label: "Talk Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Talk Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop Speech',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "talk_stop" ].value
		},
	},
	{
		label: "Mouth Read",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouth Read()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Not implemented in AOZ',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouth_read" ].value
		},
	},
	{
		label: "Mouth Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouth Width()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Not implemented in AOZ',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouth_width" ].value
		},
	},
	{
		label: "Mouth Width",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Mouth Width()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Not implemented in AOZ',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "mouth_width" ].value
		},
	},
	{
		label: "Dev Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dev Open',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a device. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_open" ].value
		},
	},
	{
		label: "Dev Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dev Close',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a device. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_close" ].value
		},
	},
	{
		label: "Dev Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Dev Base()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get base address of an IO structure. May be implemented in AOZ later',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_base" ].value
		},
	},
	{
		label: "Dev Do",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dev Do',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a command using DoI0. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_do" ].value
		},
	},
	{
		label: "Dev Send",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dev Send',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a command using SendIO. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_send" ].value
		},
	},
	{
		label: "Dev Abort",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Dev Abort',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Abort an IO operation. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_abort" ].value
		},
	},
	{
		label: "Dev Check",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Dev Check()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Check status of a device with a CheckIO. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_check" ].value
		},
	},
	{
		label: "Dev First$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dev First$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the first device from the current device list. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_first$" ].value
		},
	},
	{
		label: "Dev Next$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Dev Next$()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the next device from the current device list. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "dev_next$" ].value
		},
	},
	{
		label: "Lib Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Lib Open',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a library. May be implemented in AOZ (for example for Windows DLLs), has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lib_open" ].value
		},
	},
	{
		label: "Lib Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Lib Close',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Close a library. May be implemented in AOZ (for example for Windows DLLs), has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lib_close" ].value
		},
	},
	{
		label: "Lib Call",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Lib Call',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Call a function from the libraryEnd Function. May be implemented in AOZ (for example for Windows DLLs), has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lib_call" ].value
		},
	},
	{
		label: "Lib Base",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Lib Base()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Get the base address of the library. May be implemented in AOZ, has no effect in current version',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "lib_base" ].value
		},
	},
	{
		label: "Exec",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Exec command$ = "${1:value1}", output$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Execute a system command. This instruction will be implemented in executable and node.js transpiled applications',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "exec" ].value
		},
	},
	{
		label: "Open Port",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Open Port channel = ${1:value1}, port$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a communication port on the machine',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "open_port" ].value
		},
	},
	{
		label: "Port",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Port( channel = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the content of an opened harware port',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "port" ].value
		},
	},
	{
		label: "Console Log",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Console Log message = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Write a text in the Javascript Console ( console.log equivalent )',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "console_log" ].value
		},
	},
	{
		label: "Track Loop On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Loop On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Enable the loop playing',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_loop_on" ].value
		},
	},
	{
		label: "Track Loop Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Loop Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Disable the loop playing',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_loop_off" ].value
		},
	},
	{
		label: "Track Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Load path$ = "${1:value1}", bank = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a module music file into a bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_load" ].value
		},
	},
	{
		label: "Track Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Play bank = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a music module loaded with Track Load instruction or Load Asset instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_play" ].value
		},
	},
	{
		label: "Track Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop the current playing of the music module after a call of Track Play instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_stop" ].value
		},
	},
	{
		label: "Track Pause",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Pause',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Pause the current playing of the music module after a call of Track Play instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_pause" ].value
		},
	},
	{
		label: "Track Resume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Resume',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Continue the current playing of the music module after a call of Track Stop instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_resume" ].value
		},
	},
	{
		label: "Track Title",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Title()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the title of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_title" ].value
		},
	},
	{
		label: "Track Signature",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Signature()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the name of the format of the current music module (Protracker or FastBassoonTracker).',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_signature" ].value
		},
	},
	{
		label: "Track Length",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Length()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the length of the current music module in number of patterns.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_length" ].value
		},
	},
	{
		label: "Track Channels",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Channels()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of channels of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_channels" ].value
		},
	},
	{
		label: "Track Position",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Position()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the number of the pattern in playing of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_position" ].value
		},
	},
	{
		label: "Track Row",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = Track Row()',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Return the position into the pattern in playing of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_row" ].value
		},
	},
	{
		label: "Track Volume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Volume volume = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the global volume of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_volume" ].value
		},
	},
	{
		label: "Track Pattern",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Pattern patterm = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Jump to a pattern of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_pattern" ].value
		},
	},
	{
		label: "Track Sam Init",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Sam Init bank = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialize the samples from a bank number. Note',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_sam_init" ].value
		},
	},
	{
		label: "Track Sam Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Sam Play sample = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a sample stored into the current module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_sam_play" ].value
		},
	},
	{
		label: "Track Sam Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Sam Play sample = ${1:value1}, rate = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a sample stored into the current module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_sam_play" ].value
		},
	},
	{
		label: "Track Sam Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Sam Play sample = ${1:value1}, rate = ${2:value2}, volume = ${3:value3}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a sample stored into the current module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_sam_play" ].value
		},
	},
	{
		label: "Med Load",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Med Load path$ = "${1:value1}", bank = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Load a module music file into a bank',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "med_load" ].value
		},
	},
	{
		label: "Med Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Med Play bank = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a music module loaded with Med Load instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "med_play" ].value
		},
	},
	{
		label: "Med Play",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Med Play bank = ${1:value1}, music = ${2:value2}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Play a music module loaded with Med Load instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "med_play" ].value
		},
	},
	{
		label: "Med Stop",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Med Stop',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Stop the current playing of the music module after a call of Med Play instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "med_stop" ].value
		},
	},
	{
		label: "Med Cont",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Med Cont',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Continue the current playing of the music module after a call of Med Stop instruction',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "med_cont" ].value
		},
	},
	{
		label: "Midi On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Midi On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'This instruction is not implemented. It\'s just for the compatibility with AMOS',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "midi_on" ].value
		},
	},
	{
		label: "Track Volume",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Track Volume volume = ${1:value1}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Set the global volume of the current music module.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "track_volume" ].value
		},
	},
	{
		label: "Led On",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Led On',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle high pass filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "led_on" ].value
		},
	},
	{
		label: "Led Off",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Led Off',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Toggle high pass filter',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "led_off" ].value
		},
	},
	{
		label: "Unity Init",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unity Init',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Initialise le moteur Unity',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_init" ].value
		},
	},
	{
		label: "Unity GameObject",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unity GameObject Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Manipule un objet de la scène Unity en cours',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_gameobject" ].value
		},
	},
	{
		label: "Unity Set Camera",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unity Set Camera Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Défini la caméra à utiliser pourt le point de vue',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_set_camera" ].value
		},
	},
	{
		label: "Unity GameObject Infos",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'Unity GameObject Infos Name$ = "${1:value1}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Charge les informations à propos d\'un objet de la scène courante.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_gameobject_infos" ].value
		},
	},
	{
		label: "Unity GameObject X",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Unity GameObject X( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retourne la position de l\'objet sur l\'axe X.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_gameobject_x" ].value
		},
	},
	{
		label: "Unity GameObject Y",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Unity GameObject Y( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retourne la position de l\'objet sur l\'axe Y.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_gameobject_y" ].value
		},
	},
	{
		label: "Unity GameObject Z",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}# = Unity GameObject Z( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Retourne la position de l\'objet sur l\'axe Z.',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_gameobject_z" ].value
		},
	},
	{
		label: "Unity Set Scene",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = Unity Set Scene( Name$ = "${2:value2}")',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Change la scène courante par la scène "Name$"',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "unity_set_scene" ].value
		},
	},
	{
		label: "WebSocket Open",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'WebSocket Open ${1:index}, url$ = "${2:value2}", protocol$ = "${3:value3}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Open a Web Socket channel, enabling you to send and receive messages and files',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_open" ].value
		},
	},
	{
		label: "WebSocket Close",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'WebSocket Close ${1:index}',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Destroys a WebSocket channel and all data associated',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_close" ].value
		},
	},
	{
		label: "WebSocket Send Message",
		kind: monaco.languages.CompletionItemKind.Keyword,
		insertText: 'WebSocket Send Message ${1:index}, message$ = "${2:value2}"',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Send a message through a WebSocket channel',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_send_message" ].value
		},
	},
	{
		label: "WebSocket Is Message",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = WebSocket Is Message( idnex = ${2:value2})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns TRUE if a new message is available, FALSE if not',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_is_message" ].value
		},
	},
	{
		label: "WebSocket Message$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = WebSocket Message$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns a newly arrived string message',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_message$" ].value
		},
	},
	{
		label: "WebSocket Is Error",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = WebSocket Is Error( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns TRUE if a an error has occurred, FALSE if OK',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_is_error" ].value
		},
	},
	{
		label: "WebSocket Is Connected",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable} = WebSocket Is Connected( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns TRUE if the WebSocket has succesfully connected to the server',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_is_connected" ].value
		},
	},
	{
		label: "WebSocket Error$",
		kind: monaco.languages.CompletionItemKind.Function,
		insertText: '${1:variable}$ = WebSocket Error$( ${2:index})',
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		detail: 'Returns the last error message',
		documentation: {
			supportHtml: true,
			value: aoz_documentation[ "websocket_error$" ].value
		},
	},

			];
			return { suggestions: suggestions };
		}
	});

	monaco.languages.registerHoverProvider( 'aoz', {
		provideHover: function( model, position ) {
			var word = (model.getWordAtPosition( position ) && model.getWordAtPosition( position ).word )?model.getWordAtPosition( position ).word:undefined;
			if( word == undefined || word == '' )
			{
				word = ' ';
			}
			return {
				range: new monaco.Range( 1, 1, model.getLineCount(), model.getLineMaxColumn( model.getLineCount())),
				contents: [
					{
						value: (aoz_documentation[ word.trim().strReplace( ' ', '_' ).toLowerCase() ] && aoz_documentation[ word.trim().strReplace( ' ', '_' ).toLowerCase() ].value) ? aoz_documentation[ word.trim().strReplace( ' ', '_' ).toLowerCase() ].value : "",
						supportHtml: true,
						isTrusted: true,
						supportThemeIcons: true
					}
				]
			}
		}
	} );
}

String.prototype.strReplace = function( strSearch, strReplace )
{
	var newStr = '';
	for( var n = 0; n < this.length; n++ )
	{
		var part = this.substr( n, strSearch.length );
		if( part == strSearch )
		{
			newStr = newStr + strReplace;
			n = n + ( strSearch.length - 1 );
		}
		else
		{
			newStr = newStr + part.substr( 0, 1 );
		}
	}

	return newStr;
}
